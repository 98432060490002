import {
  PostDataOperationCommentReplyProps,
  PostDataOperationCommentReplyResponse,
} from '@frontend/sorghum/interface';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { DASHBOARD_DATA_OPERATION_COMMENT_REPLY } from '../apis';

async function postDataOperationCommentReply(
  props: PostDataOperationCommentReplyProps,
): Promise<PostDataOperationCommentReplyResponse> {
  return axios
    .post(DASHBOARD_DATA_OPERATION_COMMENT_REPLY, props)
    .then((res) => res.data.data)
    .catch((err) => ({
      code: 400,
      msg: err,
    }));
}

export function usePostDataOperationCommentReply(
  props: PostDataOperationCommentReplyProps,
) {
  return useQuery({
    queryKey: ['post-data-operation-commentReply', props],
    queryFn: () => postDataOperationCommentReply(props),
  });
}

export default usePostDataOperationCommentReply;
