import { EditorCtx } from '@frontend/editor/external-providers';
import { Box, BoxProps, styled } from '@mui/material';
import { useCallback, useContext } from 'react';
import { useStoreApi } from 'reactflow';

interface EditorBlockContainerProps extends BoxProps {
  nodeID: string;
}

const ContainerStyled = styled(Box)<{ $disabled: boolean }>(
  ({ theme, $disabled }) => ({
    ...($disabled && {
      '& > div': {
        pointerEvents: 'none',
      },
    }),
  }),
);

export const EditorBlockContainer = ({
  nodeID,
  children,
  ...props
}: EditorBlockContainerProps) => {
  const { tourMode, setOnHoverNode } = useContext(EditorCtx);
  const store = useStoreApi();
  const nodes = store.getState().nodeInternals;

  // 設定 hover 目標，讓拖曳線段時可以得知連線目標，整個 block 都可以連線
  const handleNodeMouseEnter = useCallback(() => {
    const node = nodes.get(nodeID);
    setOnHoverNode(node);
  }, [nodeID, nodes, setOnHoverNode]);

  const handleNodeMouseLeave = useCallback(
    (e: React.MouseEvent) => {
      // 如果移動到 float-panel 上仍然算是 hover 狀態
      if (
        e.relatedTarget &&
        (e.relatedTarget as Element).id === 'node-float-panel'
      )
        return;
      setOnHoverNode(undefined);
    },
    [setOnHoverNode],
  );

  return (
    <ContainerStyled
      {...props}
      id={nodeID}
      $disabled={tourMode}
      onMouseEnter={handleNodeMouseEnter}
      onMouseLeave={handleNodeMouseLeave}
    >
      {children}
    </ContainerStyled>
  );
};

export default EditorBlockContainer;
