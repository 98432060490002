import { globalTheme } from '@frontend/components/external-providers';
import { MaterialStyledProps } from '@frontend/components/interface';
import { Textarea, Tooltip, UploadImage } from '@frontend/components/ui';
import { Alert } from '@frontend/components/utils';
import {
  useCanvasAdd,
  useCanvasCollect,
  useCanvasGet,
  useCanvasRemove,
  useCanvasUpdate,
  useGetProjectID,
  useModal,
  usePostEditorFlowUpload,
} from '@frontend/editor/data-access';
import { EditorCtx, UICtx } from '@frontend/editor/external-providers';
import {
  GalleryImageType,
  GalleryType,
  PublishErrorsEnum,
} from '@frontend/editor/interface';
import { IMAGE_RATIO, IMAGE_WIDTH } from '@frontend/editor/utils';
import { useGetEditorLabelItems } from '@frontend/sorghum/data-access';
import { GetEditorLabelItemLabelType } from '@frontend/sorghum/interface';
import AddIcon from '@mui/icons-material/Add';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { get } from 'lodash';
import { memo, useCallback, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  SortableContainer,
  SortableContainerProps,
  SortableElement,
  SortableElementProps,
} from 'react-sortable-hoc';
import { useViewport } from 'reactflow';
import EditorFloatPanel from '../editor-float-panel/editor-float-panel';
import SortableTextButtonGroup from '../editor-sortable-text-button-group/editor-sortable-text-button-group';

interface SortableItemStyledProps extends MaterialStyledProps {
  $isFirst: boolean;
}

interface EditorGalleryProps {
  id: string;
  draggable: boolean;
  parentID: string;
  index: number;
  setIsHoverMenu: (isHover: boolean) => void;
}

const ContainerStyled = styled(Box)<{ $isHover: boolean; $zoom: number }>(
  ({ theme, $isHover, $zoom }) => ({
    background: theme.palette['grey'][100],
    borderRadius: '8px',
    border: `2px solid ${
      $isHover ? theme.palette['info']['light'] : 'transparent'
    }`,
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(1.5),
    position: 'relative',
    gap: '12px',
    fontSize: `${28 / $zoom}px`,
  }),
);

const TitleWrapperStyled = styled(Box)(({ theme }) => ({
  background: theme.palette['grey'][100],
  display: 'inline-flex',
  justifyContent: 'space-between',

  '& > svg': {
    color: theme.palette['grey'][600],
    fontSize: '16px',
  },
}));

export const SortableListWrapperStyled = styled(Box)(({ theme }) => ({
  display: 'inline-flex',
  overflow: 'visible',
  width: '100%',
  gap: '12px',
}));

export const SortableItemStyled = styled(Box)<SortableItemStyledProps>(
  ({ theme, $isFirst }) => ({
    position: 'relative',
    padding: '8px',
    width: '259px',
    height: 'fit-content',
    background: theme.palette['grey'][200],
    boxShadow: $isFirst ? 'none' : theme.shadows[12],
    borderRadius: '8px',
  }),
);

export const ImageWrapperStyled = styled(Box)(({ theme }) => ({
  gap: '12px',
  display: 'flex',
  flexDirection: 'column',
}));

export const TextareaWrapper = styled(Box)(({ theme }) => ({
  background: theme.palette['grey']['white'],
}));

export const FakeImageWrapperStyled = styled(Box)(({ theme }) => ({
  height: '100%',
  width: '100%',
  position: 'absolute',
  top: 0,
  paddingLeft: '12px',
  left: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: theme.palette['bluegrey'][300],

  '::after': {
    height: '100%',
    width: '100%',
    position: 'absolute',
    background: theme.palette['background']['black'][5],
    content: '""',
    borderRadius: '8px',
    border: `1px dashed ${theme.palette['bluegrey'][300]}`,
  },
  '&:hover': {
    opacity: 0.5,
  },
}));

interface SortableItemProps {
  id: string;
  itemIndex: number;
  parentID: string;
  isAllowedToAdd: boolean;
  isLatestItem: boolean;
  deletable: boolean;
  draggable: boolean;
  isSorting: boolean;
  isHover: boolean;
  setIsHover: (val: boolean) => void;
}

const SortableItem: React.ComponentClass<
  SortableElementProps & SortableItemProps
> = SortableElement(
  ({
    id,
    itemIndex,
    parentID,
    deletable,
    draggable,
    isHover,
    isSorting,
    isAllowedToAdd,
    isLatestItem,
    setIsHover,
  }: SortableItemProps) => {
    const state = useContext(EditorCtx);
    const uiState = useContext(UICtx);

    const { getTargetElement } = useCanvasGet();
    // editor image 的資料

    const element = useMemo(
      () => getTargetElement(id) as GalleryImageType,
      [getTargetElement, id],
    );

    const parentElement = useMemo(
      () => getTargetElement(parentID) as GalleryType,
      [getTargetElement, parentID],
    );

    const [t] = useTranslation();
    const { openDeleteGalleryImageModal } = useModal();
    const { removeCell } = useCanvasRemove();
    const { addCellButton, addGalleryImage } = useCanvasAdd();
    const { canvasSortElement, canvasUpdateData } = useCanvasUpdate();
    const { getErrorStatus } = useCanvasCollect();
    const { data: projectID } = useGetProjectID();
    const { mutate: uploadImage } = usePostEditorFlowUpload(
      projectID as string,
    );
    const { data: labelItems } = useGetEditorLabelItems(projectID as string);
    const labelList = get(labelItems, 'data', []);

    const handleSortButton = useCallback(
      (oldIndex: number, newIndex: number) => {
        canvasSortElement(id, oldIndex, newIndex);
      },
      [canvasSortElement, id],
    );

    const handleMouseEnterInputField = useCallback(
      (objectId: string) => {
        uiState.onHoverElement(objectId, 3);
      },
      [uiState],
    );

    const handleMouseLeaveInputField = useCallback(() => {
      uiState.onHoverElement('', 0);
    }, [uiState]);

    const handleDeleteClick = useCallback(() => {
      if (
        element.url ||
        element.title ||
        element.subtitle ||
        element.children.length > 0
      ) {
        openDeleteGalleryImageModal(() => removeCell(id));
      } else {
        removeCell(id);
      }
    }, [
      removeCell,
      element.children.length,
      element.subtitle,
      element.title,
      element.url,
      id,
      openDeleteGalleryImageModal,
    ]);

    const handleTitleChange = useCallback(
      (text: string) => {
        canvasUpdateData(id, 'title', text);
      },
      [canvasUpdateData, id],
    );

    const handleSubtitleChange = useCallback(
      (text: string) => {
        canvasUpdateData(id, 'subtitle', text);
      },
      [canvasUpdateData, id],
    );

    const handleImageChange = useCallback(
      (val: Blob) => {
        uploadImage(
          { file: val, fileType: '6' },
          {
            onSuccess: (data) => {
              if (data.code === 20000) {
                canvasUpdateData(id, 'fileID', data.data.fileId);
                canvasUpdateData(id, 'url', data.data.fileUrl);
              } else {
                Alert.error(t('alert.imageSize'));
              }
            },
          },
        );
      },
      [canvasUpdateData, id, t, uploadImage],
    );

    const handleAddGalleryImage = useCallback(() => {
      addGalleryImage(element.nodeID as string, parentID, itemIndex + 1);
    }, [addGalleryImage, element.nodeID, itemIndex, parentID]);

    const handleHoverFloatPanel = useCallback(
      (target: string) => {
        if (target === 'add') {
          setIsHover(true);
        } else {
          setIsHover(false);
        }
      },
      [setIsHover],
    );

    // 要多包一層 div 才可以避免 flow 本身 zoom in 時造成的大小影響
    // 因為 react-sortable-hoc 會操作 transform 所以不能直接操作同一層元素
    return (
      <Box>
        <SortableItemStyled
          key={id}
          $isFirst={itemIndex === 0}
          onMouseEnter={() => handleMouseEnterInputField(id)}
          onMouseLeave={() => handleMouseLeaveInputField()}
        >
          {/* gallery 卡片上方的工具列 */}
          {uiState.hoveredElement.id === id &&
            uiState.hoveredElement.layer === 3 && (
              <EditorFloatPanel
                isCenter
                allowAdd={isAllowedToAdd}
                {...(isAllowedToAdd && {
                  handleAdd: handleAddGalleryImage,
                })}
                direction="row"
                draggable={draggable}
                handleHover={handleHoverFloatPanel}
                {...(deletable && { handleDelete: handleDeleteClick })}
                backgroundColor={
                  globalTheme ? globalTheme.palette?.grey?.white : ''
                }
              />
            )}

          <ImageWrapperStyled onMouseEnter={() => setIsHover(true)}>
            {/* hover add 符號時要顯示的假圖片，只會顯示在最後一張圖片右方 */}
            {isHover &&
              !isSorting &&
              isLatestItem &&
              parentElement.children.length < 10 && (
                <FakeImageWrapperStyled
                  id="add_gallery_card_right"
                  onClick={handleAddGalleryImage}
                >
                  <AddIcon />
                </FakeImageWrapperStyled>
              )}
            <UploadImage
              uploadImageID="add_gallery_image"
              defaultValue={element.url}
              imageType={parentElement.ratio === 1 ? 1 : 2}
              title={t('canvas.editorGallery.addImage')}
              info={t('canvas.editorGallery.imageFormat')}
              // 扣除 padding
              width={IMAGE_WIDTH - 24}
              ratio={IMAGE_RATIO}
              onChange={handleImageChange}
              publishError={
                !!getErrorStatus(id, PublishErrorsEnum.GALLERY_DATA_ERROR)
              }
            />
            <TextareaWrapper>
              <Textarea
                defaultValue={element.title}
                placeholder={t('canvas.editorGallery.addTitle')}
                limit={80}
                error={
                  !!getErrorStatus(id, PublishErrorsEnum.GALLERY_TITLE_EMPTY)
                }
                publishError={
                  !!getErrorStatus(id, PublishErrorsEnum.GALLERY_TITLE_EMPTY)
                }
                onChange={handleTitleChange}
                allowEnter={false}
                labelItem={labelList.filter(
                  (item: GetEditorLabelItemLabelType) =>
                    item.type === 1 || item.type === 2 || item.type === 3,
                )}
              />
            </TextareaWrapper>
            <TextareaWrapper>
              <Textarea
                defaultValue={element.subtitle}
                placeholder={t('canvas.editorGallery.addSubtitle')}
                limit={80}
                error={
                  !!getErrorStatus(id, PublishErrorsEnum.GALLERY_DATA_ERROR)
                }
                publishError={
                  !!getErrorStatus(id, PublishErrorsEnum.GALLERY_DATA_ERROR)
                }
                onChange={handleSubtitleChange}
                allowEnter={false}
                labelItem={labelList.filter(
                  (item: GetEditorLabelItemLabelType) =>
                    item.type === 1 || item.type === 2 || item.type === 3,
                )}
              />
            </TextareaWrapper>
            <SortableTextButtonGroup
              parentID={id}
              onSortEnd={handleSortButton}
              onAddClick={() => addCellButton(id)}
              addButtonID="gallery_add_button"
            />
          </ImageWrapperStyled>
        </SortableItemStyled>
      </Box>
    );
  },
);

interface SortableListProps {
  items: string[];
  parentID: string;
  isSorting: boolean;
}

const SortableList: React.ComponentClass<
  SortableContainerProps & SortableListProps
> = SortableContainer(({ items, parentID, isSorting }: SortableListProps) => {
  const [isHover, setIsHover] = useState(false);

  return (
    <SortableListWrapperStyled
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      {items.map((childID, index: number) => {
        return (
          <SortableItem
            parentID={parentID}
            key={childID}
            index={index}
            itemIndex={index}
            id={childID}
            deletable={items.length > 1}
            isAllowedToAdd={items.length < 10}
            isHover={isHover}
            isSorting={isSorting}
            setIsHover={setIsHover}
            draggable={items.length > 1}
            isLatestItem={index === items.length - 1}
          />
        );
      })}
    </SortableListWrapperStyled>
  );
});

export const EditorGallery = ({
  id, // EditorGallery 的 ID
  index,
  parentID, // Block 的 ID
  draggable,
  setIsHoverMenu,
}: EditorGalleryProps) => {
  const uiState = useContext(UICtx);
  const { zoom } = useViewport();
  const { canvasUpdateData } = useCanvasUpdate();

  const { getTargetElement } = useCanvasGet();

  const element = useMemo(
    () => getTargetElement(id) as GalleryType,
    [getTargetElement, id],
  );

  const [t] = useTranslation();
  const [isHover, setIsHover] = useState<boolean>(false);
  const [isSorting, setIsSorting] = useState(false);
  const { openDeleteGalleryModal } = useModal();
  const { removeElement } = useCanvasRemove();
  const { canvasSortElement } = useCanvasUpdate();

  const onRemoveButtonClick = useCallback(() => {
    // 如果有設定任何資料則跳出提示
    let needConfirm = false;
    element.children.forEach((childID: string) => {
      const childElement = getTargetElement(childID) as GalleryImageType;
      if (childElement.fileID || childElement.title || childElement.subtitle) {
        needConfirm = true;
      }
    });

    if (needConfirm) {
      openDeleteGalleryModal(() => removeElement(id));
    } else {
      removeElement(id);
    }
  }, [
    removeElement,
    element.children,
    getTargetElement,
    id,
    openDeleteGalleryModal,
  ]);
  const handleMouseEnter = useCallback(() => {
    uiState.onHoverElement(id, 2);
    setIsHover(true);
  }, [id, uiState]);

  const handleSwitchRatio = useCallback(() => {
    const ratio = element.ratio === 1 ? 2 : 1;
    canvasUpdateData(id, 'ratio', ratio);
  }, [canvasUpdateData, element.ratio, id]);

  const handleMouseLeave = useCallback(() => {
    setIsHover(false);
  }, []);

  const handleMouseEnterMenu = useCallback(() => {
    setIsHoverMenu(true);
  }, [setIsHoverMenu]);

  const handleMouseLeaveMenu = useCallback(() => {
    setIsHoverMenu(false);
  }, [setIsHoverMenu]);

  const onSortEnd = useCallback(
    ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => {
      canvasSortElement(id, oldIndex, newIndex);
      setIsSorting(false);
    },
    [canvasSortElement, id],
  );

  return (
    <ContainerStyled
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      $isHover={isHover}
      $zoom={zoom}
    >
      {/* Element 左側的刪除按鈕*/}
      {isHover && uiState.hoveredElement.layer < 3 && (
        <EditorFloatPanel
          onMouseEnter={handleMouseEnterMenu}
          onMouseLeave={handleMouseLeaveMenu}
          type="gallery"
          allowAdd
          parentID={parentID}
          index={index}
          draggable={draggable}
          direction="column"
          handleSwitchRatio={handleSwitchRatio}
          handleDelete={onRemoveButtonClick}
          backgroundColor={
            globalTheme.palette?.grey ? globalTheme.palette.grey[100] : ''
          }
        />
      )}
      <TitleWrapperStyled>
        <Typography variant="subtitle2" color="grey.900">
          {t('canvas.editorGallery.title')}
        </Typography>
        <Tooltip
          placement="right"
          title={t('canvas.editorGallery.titleTooltip')}
        >
          <ErrorOutlineIcon />
        </Tooltip>
      </TitleWrapperStyled>
      <SortableList
        items={element.children}
        axis="x"
        lockAxis="x"
        onSortStart={() => setIsSorting(true)}
        onSortEnd={onSortEnd}
        isSorting={isSorting}
        parentID={id}
        useDragHandle
        helperClass="node_horizontal_item_dragged_style"
      />
    </ContainerStyled>
  );
};

export default memo(EditorGallery);
