import {
  GetBillingCouponCheckResponse,
  ResponseWithData,
} from '@frontend/sorghum/interface';

import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { BILLING_COUPON_CHECK } from '../apis';

async function getGetBillingCouponCheck(
  code: string,
): Promise<ResponseWithData<GetBillingCouponCheckResponse>> {
  return axios
    .get(`${BILLING_COUPON_CHECK}?code=${code}`)
    .then((res) => res.data)
    .catch((err) => ({
      code: 400,
      msg: err,
    }));
}

export function useGetBillingCouponCheck() {
  return useMutation({
    mutationKey: ['get-billing-coupon-check'],
    mutationFn: (code: string) => getGetBillingCouponCheck(code),
  });
}

export default useGetBillingCouponCheck;
