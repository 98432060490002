import {
  PostDataDashboardOperationFlowListRequest,
  PostDataDashboardOperationFlowListResponse,
} from '@frontend/sorghum/interface';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { DATA_DASHBOARD_OPERATION_FLOW_LIST } from '../apis';

const defaultData = {
  totalCount: 0,
  limit: 0,
  offset: 0,
  flows: [],
};

async function postDataDashboardOperationFlowList(
  params: PostDataDashboardOperationFlowListRequest,
): Promise<PostDataDashboardOperationFlowListResponse> {
  return axios
    .post(DATA_DASHBOARD_OPERATION_FLOW_LIST, params)
    .then((res) => res.data.data)
    .catch((err) => defaultData);
}

export function usePostDataDashboardOperationFlowList({
  startDate,
  endDate,
  limit,
  offset,
}: PostDataDashboardOperationFlowListRequest) {
  return useQuery({
    queryKey: [
      'post-data-dashboard-operation-flow-list',
      { startDate, endDate, limit, offset },
    ],
    queryFn: () =>
      postDataDashboardOperationFlowList({ startDate, endDate, limit, offset }),
  });
}

export default usePostDataDashboardOperationFlowList;
