import {
  Checkbox,
  FormControl,
  FormHelperText,
  InputLabel,
  ListItemText,
  ListSubheader,
  MenuItem,
  OutlinedInput,
  SelectProps,
} from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { styled } from '@mui/system';
import { useState } from 'react';
import Typography from '../typography/typography';

const SelectStyled = styled(Select)(({ theme }) => ({}));

export interface GroupedItems {
  groupName: string;
  items: { id: number; label: string }[];
}

export interface CheckMarksSelectProps extends Omit<SelectProps, 'value'> {
  helperText?: string;
  groupedItems: GroupedItems[];
  onChangeSelectedData?: (value: string[]) => void;
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

//FIXME: 目前只支援有 group 的資料結構
//FIXME: 只拿得到 label 或 id，拿不到整個 item 的資料，要自己再去原本的 array 找
export const CheckMarksSelect = ({
  label,
  helperText,
  groupedItems = [],
  onChangeSelectedData,
}: CheckMarksSelectProps) => {
  const [selectedValue, setSelectedValue] = useState<string[]>([]);

  const handleChange = (event: SelectChangeEvent<unknown>) => {
    const {
      target: { value },
    } = event;

    const newValue = Array.isArray(value)
      ? value
      : typeof value === 'string'
      ? value.split(',')
      : [];

    setSelectedValue(newValue);
    if (onChangeSelectedData) {
      onChangeSelectedData(newValue);
    }
  };

  return (
    <FormControl size="small" sx={{ width: '100%' }}>
      <InputLabel>{label}</InputLabel>
      <SelectStyled
        fullWidth
        multiple
        value={selectedValue}
        input={<OutlinedInput label={label} />}
        onChange={handleChange}
        renderValue={(selected) =>
          Array.isArray(selected) ? selected.join(', ') : ''
        }
        MenuProps={MenuProps}
      >
        {groupedItems.map((group) => [
          <ListSubheader>{group.groupName}</ListSubheader>,
          group.items.map((item) => (
            <MenuItem value={item.label} key={item.id}>
              <Checkbox checked={selectedValue.includes(item.label)} />
              <ListItemText primary={item.label} />
            </MenuItem>
          )),
        ])}
      </SelectStyled>
      {helperText && (
        <FormHelperText>
          <Typography variant="caption" color="grey.500">
            {helperText}
          </Typography>
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default CheckMarksSelect;
