import { globalTheme } from '@frontend/components/external-providers';
import { Textarea, UploadImage } from '@frontend/components/ui';
import { Alert } from '@frontend/components/utils';
import {
  useCanvasCollect,
  useCanvasGet,
  useCanvasUpdate,
  useGetProjectID,
  usePostEditorFlowUpload,
} from '@frontend/editor/data-access';
import { PublishErrorsEnum, ShareLinkType } from '@frontend/editor/interface';
import { IMAGE_RATIO, IMAGE_WIDTH } from '@frontend/editor/utils';
import { useGetEditorLabelItems } from '@frontend/sorghum/data-access';
import { GetEditorLabelItemLabelType } from '@frontend/sorghum/interface';
import CallSplitIcon from '@mui/icons-material/CallSplit';
import { Typography } from '@mui/material';
import { get } from 'lodash';
import { memo, useCallback, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { NodeProps, Position, useViewport } from 'reactflow';
import EditorBlockBorder from '../editor-block-border/editor-block-border';
import EditorBlockContainer from '../editor-block-container/editor-block-container';
import { HandlePoint } from '../handle-point/handle-point';
import { NodeHeader } from '../node-header/node-header';
import NodeShareLinkUrlButton from './node-share-link-url-button';
import {
  BodyStyled,
  ElementContainerStyled,
  ImageUploadWrapperStyled,
  InfoStyled,
} from './styles';

export const NodeShareLink = ({ id, selected }: NodeProps) => {
  const { zoom } = useViewport();
  const { getTargetElement } = useCanvasGet();

  const { header, inputID, shareLinkData } = useMemo(() => {
    const shareLinkData =
      (getTargetElement(id) as ShareLinkType) || ({} as ShareLinkType);
    const { label: header, inputID } = shareLinkData;
    return {
      header,
      inputID,
      shareLinkData,
    };
  }, [getTargetElement, id]);

  const title = get(shareLinkData, 'data.title', '');
  const fileID = get(shareLinkData, 'data.fileID', '');
  const fileUrl = get(shareLinkData, 'data.fileUrl', '');
  const buttonText = get(shareLinkData, 'data.buttonText', '');

  const [t] = useTranslation();
  const [tempData, setTempData] = useState<{
    fileUrl: string;
    fileID: string;
    title: string;
    buttonText: string;
  }>({
    fileID,
    fileUrl,
    title,
    buttonText,
  });

  const { canvasUpdateLabel } = useCanvasUpdate();
  const { getErrorStatus, getErrorStatusAfterPublished } = useCanvasCollect();
  const { data: projectID } = useGetProjectID();
  const { mutate: uploadImage } = usePostEditorFlowUpload(projectID as string);
  const { data: labelItems } = useGetEditorLabelItems(projectID as string);
  const { canvasUpdateData } = useCanvasUpdate();

  const labelList = get(labelItems, 'data', []);

  const handleChange = useCallback(
    (text: string) => {
      canvasUpdateData(id, 'data.title', text);
      setTempData({
        ...tempData,
        title: text,
      });
    },
    [canvasUpdateData, id, tempData],
  );

  const handleButtonTextChange = useCallback(
    (val: string) => {
      canvasUpdateData(id, 'data.buttonText', val);
      setTempData({
        ...tempData,
        buttonText: val,
      });
    },
    [canvasUpdateData, id, tempData],
  );

  const handleImageChange = useCallback(
    (val: Blob) => {
      uploadImage(
        { file: val, fileType: '4' },
        {
          onSuccess: (data) => {
            if (data.code === 20000) {
              canvasUpdateData(id, 'data.fileID', data.data.fileId);
              canvasUpdateData(id, 'data.fileUrl', data.data.fileUrl);
              setTempData({
                ...tempData,
                fileID: data.data.fileId,
                fileUrl: data.data.fileUrl,
              });
            } else {
              Alert.error(t('alert.imageSize'));
            }
          },
        },
      );
    },
    [canvasUpdateData, id, t, tempData, uploadImage],
  );

  return (
    <EditorBlockContainer nodeID={id}>
      {inputID && (
        <HandlePoint
          id={inputID}
          type="target"
          position={Position.Left}
          styles={{
            top: '5%',
          }}
          isConnected={false}
        />
      )}
      <EditorBlockBorder
        zoom={zoom}
        nodeID={id}
        selected={selected}
        color={get(globalTheme, 'palette.grass.600', '')}
      >
        <NodeHeader
          background={globalTheme.palette?.['grass'][50]}
          color={globalTheme.palette?.['grass'][600]}
          icon={
            <CallSplitIcon
              sx={{
                transform: 'rotateY(180deg)',
              }}
              fontSize="small"
            />
          }
          title={header}
          onBlur={(val) => canvasUpdateLabel(id, val)}
        />

        <BodyStyled>
          <InfoStyled>
            <Typography variant="caption">
              <Trans i18nKey="canvas.shareLink.info" />
            </Typography>
          </InfoStyled>
          <ElementContainerStyled>
            <ImageUploadWrapperStyled>
              <UploadImage
                defaultValue={fileUrl}
                title={t('canvas.shareLink.addImage')}
                info={t('canvas.shareLink.imageFormat')}
                width={IMAGE_WIDTH}
                ratio={IMAGE_RATIO}
                onChange={handleImageChange}
                error={
                  !!getErrorStatus(id, PublishErrorsEnum.SHARE_LINK_IMAGE_EMPTY)
                }
                publishError={
                  !!getErrorStatusAfterPublished(
                    id,
                    PublishErrorsEnum.SHARE_LINK_IMAGE_EMPTY,
                  )
                }
              />
            </ImageUploadWrapperStyled>
            <Textarea
              defaultValue={title}
              placeholder={t('canvas.shareLink.addTitle')}
              limit={80}
              error={
                !!getErrorStatus(id, PublishErrorsEnum.SHARE_LINK_TITLE_EMPTY)
              }
              publishError={
                !!getErrorStatusAfterPublished(
                  id,
                  PublishErrorsEnum.SHARE_LINK_TITLE_EMPTY,
                )
              }
              onChange={handleChange}
              labelItem={labelList.filter(
                (item: GetEditorLabelItemLabelType) =>
                  item.type === 1 || item.type === 2,
              )}
              allowEnter={false}
            />
            <NodeShareLinkUrlButton
              id={id}
              defaultValue={buttonText}
              onChange={handleButtonTextChange}
            />
          </ElementContainerStyled>
        </BodyStyled>
      </EditorBlockBorder>
    </EditorBlockContainer>
  );
};

export default memo(NodeShareLink);
