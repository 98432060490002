export const IMAGE_WIDTH = 265;
export const IMAGE_RATIO = 1.91;
export const CANVAS_ZOOM_LEVEL = {
  '-6': 0.1,
  '-5': 0.2,
  '-4': 0.4,
  '-3': 0.5,
  '-2': 0.6,
  '-1': 0.72,
  0: 0.864,
  1: 1.0368,
  2: 1.24416,
  3: 1.4929919999999999,
  4: 1.7915903999999998,
  5: 2,
};
export const NEW_ELEMENT_POSITION_DISTANCE = 32;
