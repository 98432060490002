import {
  IconSizeType,
  MaterialStyledProps,
  TrendingStatusEnum,
} from '@frontend/components/interface';
import {
  convertNumberToK,
  convertNumberToPercentage,
  formatNumberWithCommas,
} from '@frontend/editor/utils';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import NavigateNextOutlinedIcon from '@mui/icons-material/NavigateNextOutlined';
import { Box, Skeleton, Tooltip } from '@mui/material';
import { styled } from '@mui/system';
import { isNumber } from 'lodash';
import { useMemo } from 'react';
import { Trans } from 'react-i18next';
import { IconButton } from '../icon-button/icon-button';
import { TrendingPercentage } from '../trending-percentage/trending-percentage';
import { Typography } from '../typography/typography';

export interface SubDataProps {
  title: string;
  titleTooltip: string;
  percentageSuffix?: string;
  color?: string;
  mainData: number | string;
  percentage?: number;
  lastPeriod?: number;
  hideLastPeriod?: boolean;
  trendingPercentage?: number;
  hideTrendingPercentage?: boolean;
  isLoading?: boolean;
  onClick?: () => void;
  size?: 'large' | 'normal';
}

interface SubDataContainerStyledProps extends MaterialStyledProps {
  $size: string;
}

const SubDataContainerStyled = styled(Box)<SubDataContainerStyledProps>(
  ({ theme, $size }) => ({
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    width: '100%',
    backgroundColor: theme.palette['grey']['white'],
    borderRadius: '8px',
    boxShadow: theme.shadows[2],
    padding: $size === 'large' ? '16px 20px' : '16px',
    minHeight: '80px',
    boxSizing: 'border-box',

    '.header': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: '8px',

      '.header-title': {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',

        svg: {
          marginLeft: '4px',
        },
      },
    },

    '.data-in-row': {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
  }),
);

const BottomColorStyled = styled('span')<{ $backgroundColor?: string }>(
  ({ theme, $backgroundColor }) => ({
    display: 'flex',
    width: '100%',
    height: '8px',
    position: 'absolute',
    bottom: 0,
    left: 0,
    borderRadius: '0 0 8px 8px',
    zIndex: 5,

    backgroundColor: $backgroundColor,
  }),
);

export function SubData({
  title,
  titleTooltip,
  color,
  mainData,
  percentage,
  lastPeriod,
  hideLastPeriod = false,
  trendingPercentage,
  percentageSuffix,
  hideTrendingPercentage = false,
  isLoading = false,
  size = 'normal',
  onClick,
}: SubDataProps) {
  const trendingStatus = useMemo(() => {
    if (isNumber(trendingPercentage)) {
      if (trendingPercentage > 0) {
        return TrendingStatusEnum.UP;
      } else if (trendingPercentage < 0) {
        return TrendingStatusEnum.DOWN;
      } else {
        return TrendingStatusEnum.TIE;
      }
    } else {
      return TrendingStatusEnum.NULL;
    }
  }, [trendingPercentage]);

  return (
    <SubDataContainerStyled onClick={onClick} $size={size}>
      {isLoading ? (
        <>
          <Skeleton variant="text" width="100%" height="30%" />
          <Skeleton variant="text" width="100%" height="70%" />
        </>
      ) : (
        <>
          <Box className="header">
            <Box className="header-title">
              <Typography variant="body2" color="bluegrey.600">
                {title}
              </Typography>
              <Tooltip placement="top" title={titleTooltip}>
                <InfoOutlinedIcon
                  sx={{ width: '16px', height: '16px', color: 'grey.500' }}
                />
              </Tooltip>
            </Box>
            {onClick && (
              <IconButton $size={IconSizeType.S}>
                <NavigateNextOutlinedIcon
                  sx={{ color: 'grey.600', width: '20px', height: '20px' }}
                />
              </IconButton>
            )}
          </Box>
          <Box className="data-in-row">
            <Typography
              variant={size === 'large' ? 'h4' : 'h5'}
              color="grey.700"
            >
              {typeof mainData === 'number'
                ? formatNumberWithCommas(mainData)
                : mainData}
            </Typography>
            {!hideTrendingPercentage && (
              <TrendingPercentage
                status={trendingStatus}
                percentage={trendingPercentage}
              />
            )}
          </Box>
          <Box className="data-in-row" sx={{ mt: '4px' }}>
            <Typography variant="note" color="bluegrey.200">
              {percentage && convertNumberToPercentage(percentage)}
              {percentageSuffix && percentageSuffix}
            </Typography>
            {!hideLastPeriod && (
              <Box>
                <Typography variant="note" color="bluegrey.200">
                  <Trans i18nKey="dataDashboard.lastPeriod" />
                </Typography>
                <Typography
                  variant="body3"
                  color="bluegrey.400"
                  sx={{ marginLeft: '4px' }}
                >
                  {convertNumberToK(lastPeriod)}
                </Typography>
              </Box>
            )}
          </Box>
          {color && <BottomColorStyled $backgroundColor={color} />}
        </>
      )}
    </SubDataContainerStyled>
  );
}

export default SubData;
