import { globalTheme } from '@frontend/components/external-providers';
import { IconSizeType } from '@frontend/components/interface';
import { Alert } from '@frontend/components/utils';
import { sendGAEvent } from '@frontend/sorghum/utils';
import CropIcon from '@mui/icons-material/Crop';
import CropDinIcon from '@mui/icons-material/CropDin';
import CropLandscapeIcon from '@mui/icons-material/CropLandscape';
import PhotoSizeSelectActualIcon from '@mui/icons-material/PhotoSizeSelectActual';
import { Box } from '@mui/material';
import 'cropperjs/dist/cropper.css';
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import Cropper, { ReactCropperElement } from 'react-cropper';
import { useTranslation } from 'react-i18next';
import { Dialog } from '../dialog/dialog';
import { IconButton } from '../icon-button/icon-button';
import Loading from '../loading/loading';
import { Typography } from '../typography/typography';
import {
  FloatPanelWrapperStyled,
  HiddenInputWrapper,
  IconButtonStyled,
  IconWrapper,
  ImageModalWrapperStyled,
  ImageUploadContainerStyled,
  LoadingWrapper,
  PreviewImageWrapperStyled,
  TitleWrapper,
  ToggleWrapperStyled,
  UploadImageEmptyWrapperStyled,
} from './styles';

export interface UploadImageProps {
  title?: string;
  imageType?: 1 | 2;
  info?: string;
  width: number;
  ratio?: number;
  defaultValue?: string;
  // focus 後才會觸發錯誤狀態
  error?: boolean;
  // 長駐的錯誤狀態
  publishError?: boolean;
  readonly?: boolean;
  onChange?: (cropImage: Blob) => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  toggleType?: (type: 1 | 2) => void;
  uploadImageID?: string;
}

const CROP_IMAGE_WIDTH = 552;
const CROP_IMAGE_HEIGHT = 361;

export const UploadImage: FC<UploadImageProps> = ({
  title,
  imageType = 1,
  info,
  ratio,
  width,
  defaultValue = '',
  error = false,
  publishError = false,
  readonly = false,
  onMouseEnter,
  onMouseLeave,
  onChange,
  toggleType,
  uploadImageID,
}: UploadImageProps) => {
  const [t] = useTranslation();
  const [image, setImage] = useState<string>(defaultValue);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [imageError, setImageError] = useState<boolean>(false);
  const [isFocus, setIsFocus] = useState<boolean>(false);
  const [type, setType] = useState<1 | 2>(imageType);
  const [isHover, setIsHover] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const cropperRef = useRef<ReactCropperElement>(null);

  const handleMouseDown = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();

    // 只有左鍵點擊事件才會打開上傳視窗
    if (e.button === 0) {
      if (inputRef?.current) {
        inputRef.current.focus();
        inputRef.current.click();
        setIsFocus(true);
      }
    }
  }, []);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      const file = files[0];
      if (file && file.size > 5 * 1024 * 1024) {
        Alert.error(t('alert.imageSize'));
        sendGAEvent(
          'Chat Flow Edit - Gallery',
          'Add gallery image-upload',
          'Chat Flow Edit - Gallery - add gallery image - upload',
          'failed',
        );
        return;
      } else if (file.type === 'image/jpeg' || file.type === 'image/png') {
        setImage('');
        setIsLoading(true);
        const reader = new FileReader();
        reader.onload = () => {
          setImage(reader.result as string);
        };
        reader.readAsDataURL(file);
        if (onChange) {
          const fileBlob = new Blob([file], { type: file.type });

          onChange(fileBlob);
          // 如果上傳成功移除錯誤
          setImageError(false);
          setIsLoading(false);
          sendGAEvent(
            'Chat Flow Edit - Gallery',
            'Add gallery image-upload',
            'Chat Flow Edit - Gallery - add gallery image - upload',
            'succeeded',
          );
        }
      }
    }
  };

  const handleToggleType = useCallback(
    (val: 1 | 2) => {
      setType(val);
      if (toggleType) {
        toggleType(val);
      }
    },
    [toggleType],
  );

  const handleFocus = useCallback(() => {
    if (isFocus && !image) {
      // 因為打開上傳的視窗沒有選擇不會有 onblur 事件，但是會 focus 回原本的輸入框，利用這個機制達到關閉上傳檔案的視窗時，會設定錯誤
      setImageError(error);
      setIsFocus(false);
    }
  }, [error, image, isFocus]);

  const cropImage = useCallback(() => {
    if (cropperRef.current?.cropper) {
      setImage(cropperRef.current?.cropper.getCroppedCanvas().toDataURL());
      if (onChange) {
        cropperRef.current?.cropper
          .getCroppedCanvas({
            width: CROP_IMAGE_WIDTH,
            height: CROP_IMAGE_HEIGHT,
          })
          .toBlob((blob) => {
            if (blob) {
              onChange(blob);
            }
          });
      }
    }
    setIsOpen(false);
  }, [onChange]);

  const handleMouseEnter = useCallback(() => {
    setIsHover(true);
    onMouseEnter && onMouseEnter();
  }, [onMouseEnter]);

  const handleMouseLeave = useCallback(() => {
    setIsHover(false);
    onMouseLeave && onMouseLeave();
  }, [onMouseLeave]);

  useEffect(() => {
    setImageError(publishError);
  }, [publishError]);

  useEffect(() => {
    setImage(defaultValue);
    setIsLoading(false);
  }, [defaultValue]);

  return (
    <ImageUploadContainerStyled
      id={uploadImageID}
      $width={width}
      $ratio={imageType === 1 ? ratio : 1}
      $error={imageError}
      $hasImage={!!image}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {isHover && image && !readonly && (
        <FloatPanelWrapperStyled>
          <Box>
            <IconButton
              $size={IconSizeType.XXS}
              onClick={() => setIsOpen(true)}
            >
              <CropIcon />
            </IconButton>
          </Box>
        </FloatPanelWrapperStyled>
      )}
      {!readonly && (
        <HiddenInputWrapper>
          <input
            ref={inputRef}
            onFocus={handleFocus}
            onChange={handleFileChange}
            type="file"
            accept=".jpg,.png"
          />
        </HiddenInputWrapper>
      )}
      {toggleType && !readonly && (
        <ToggleWrapperStyled>
          <IconButtonStyled
            $size={IconSizeType.XS}
            color={type === 1 ? 'primary' : 'default'}
            $isActive={type === 1}
            onClick={() => handleToggleType(1)}
            sx={{
              marginRight: '8px',
              ...(type === 1 && {
                background:
                  globalTheme?.palette?.['background']?.['primary']?.[5],
              }),
            }}
          >
            <CropLandscapeIcon />
          </IconButtonStyled>
          <IconButtonStyled
            $isActive={type === 2}
            $size={IconSizeType.XS}
            color={type === 2 ? 'primary' : 'default'}
            onClick={() => handleToggleType(2)}
            sx={{
              ...(type === 2 && {
                background:
                  globalTheme?.palette?.['background']?.['primary']?.[5],
              }),
            }}
          >
            <CropDinIcon />
          </IconButtonStyled>
        </ToggleWrapperStyled>
      )}
      {isLoading && (
        <LoadingWrapper>
          <Loading />
        </LoadingWrapper>
      )}
      {image ? (
        <PreviewImageWrapperStyled
          // 有傳入 ratio 才限制高度
          src={image}
          alt=""
          onMouseDown={handleMouseDown}
        />
      ) : (
        !isLoading && (
          <UploadImageEmptyWrapperStyled
            $error={publishError}
            onMouseDown={handleMouseDown}
          >
            <IconWrapper>
              <PhotoSizeSelectActualIcon fontSize="large" />
            </IconWrapper>
            <TitleWrapper>
              <Typography variant="caption" color="grey.500">
                {title}
              </Typography>
            </TitleWrapper>
            <Typography variant="notoSans" color="grey.400">
              {info}
            </Typography>
          </UploadImageEmptyWrapperStyled>
        )
      )}
      <Dialog
        size="s"
        title={t('modal.image.title')}
        open={isOpen}
        closeBtn={false}
        handleClose={() => setIsOpen(false)}
        cancelBtnText={t('common.cancel')}
        confirmBtnText={t('modal.image.crop')}
        handleConfirm={cropImage}
      >
        <ImageModalWrapperStyled>
          <Cropper
            ref={cropperRef}
            src={image}
            style={{ height: CROP_IMAGE_HEIGHT, width: CROP_IMAGE_WIDTH }}
            zoomTo={0}
            // 有傳入 ratio 才限制裁切比例
            {...(ratio && { aspectRatio: type === 1 ? ratio / 1 : 1 })}
            preview=".img-preview"
            viewMode={1}
            background={true}
            responsive={true}
            autoCropArea={1}
            checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
            guides={true}
          />
        </ImageModalWrapperStyled>
      </Dialog>
    </ImageUploadContainerStyled>
  );
};

export default UploadImage;
