import {
  DefaultPostIcon,
  TableTree,
  TableTreeSecondLayer,
  Tooltip,
  Typography,
} from '@frontend/components/ui';
import {
  usePostDataOperationCommentReplyDetail,
  usePostDataOperationCommentReplyList,
} from '@frontend/sorghum/data-access';
import {
  PostDataOperationCommentReplyDetailResponse,
  PostDataOperationCommentReplyType,
} from '@frontend/sorghum/interface';
import { COMMENT_AUTO_REPLY, CONTENT, usePath } from '@frontend/sorghum/utils';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import { Box, Table, TableCell, TableHead, TableRow } from '@mui/material';
import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useImmer } from 'use-immer';
import { CommentAutoReplyCellWithData } from '../comment-auto-reply-list-detail/comment-auto-reply-list-detail';
import { TableContainerWithPagination } from '../table-container/table-container';

interface Props {
  startDate: string;
  endDate: string;
}

const ContainerStyled = styled(Box)(({ theme }) => ({
  padding: '24px',
  display: 'flex',
  width: '100%',
  flexDirection: 'column',
  gap: '20px',
  background: theme.palette['grey']['white'],
  boxShadow: theme.shadows[2],
}));

export const TableCellStyled = styled(TableCell)(({ theme }) => ({
  padding: '0 0 0 20px',
  boxSizing: 'border-box',
  height: '48px',
  '.cell-container': {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
}));

export const HeaderTableCellStyled = styled(TableCell)(({ theme }) => ({
  paddingLeft: '20px',
  boxSizing: 'border-box',

  '&.first-cell': {
    paddingLeft: '48px',
  },
}));

export const TableCellInnerContainerStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',

  '.vertical-container': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
  },
}));

export const TextOverflowStyled = styled(Box)(({ theme }) => ({
  '& > p': {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
}));

const TableRowStyled = styled(TableRow)(({ theme }) => ({
  '& > td': {
    backgroundColor: theme.palette['grey'][50],
  },

  '&.mask': {
    position: 'absolute',
    width: '100%',
    zIndex: '5',
    backdropFilter: 'blur(5px)',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const SecondLayerFirstCellStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  paddingLeft: '56px',
}));

const ImageContainerStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '32px',
  height: '32px',
  borderRadius: '4px',
  backgroundPosition: 'center',
  backgroundSize: 'contain',
  backgroundRepeat: 'no-repeat',
  marginRight: '8px',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: theme.palette['grey'][100],
}));

const ROW_PER_PAGE = 15;

interface TableDataType extends PostDataOperationCommentReplyType {
  conditions?: PostDataOperationCommentReplyDetailResponse['conditions'];
}

export const AutoReply: FC<Props> = ({ startDate, endDate }: Props) => {
  const { navigateToProjectPage } = usePath();
  const [t] = useTranslation();
  const [page, setPage] = useState(0);
  const [tableData, setTableData] = useImmer<TableDataType[]>([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isOpenSecondLayer, setIsOpenSecondLayer] = useState(false);
  const [isOpenThirdLayer, setIsOpenThirdLayer] = useState(false);
  const { data: list, isFetching: isDataLoading } =
    usePostDataOperationCommentReplyList({
      startDate,
      endDate,
      offset: page * ROW_PER_PAGE,
      limit: 5,
    });

  const { mutate: getDetail } = usePostDataOperationCommentReplyDetail();

  const openFirstLayer = useCallback(
    (id: string) => {
      if (tableData.find((i) => i.id === id)?.conditions !== undefined) return;
      getDetail(
        {
          commentReplyId: id,
          startDate,
          endDate,
        },
        {
          onSuccess: (data) => {
            if (data.conditions) {
              setTableData((draft) => {
                draft
                  .filter((i) => i.id === id)
                  .forEach((i) => {
                    i.conditions = data.conditions;
                  });
              });
            }
          },
        },
      );
    },
    [endDate, getDetail, setTableData, startDate, tableData],
  );

  useEffect(() => {
    setIsLoaded(false);
    setIsOpenSecondLayer(false);
    setIsOpenThirdLayer(false);
  }, [startDate, endDate]);

  useEffect(() => {
    if (!isLoaded && list?.commentReplies) {
      setTableData(
        list?.commentReplies.map((i) => ({ ...i, conditions: undefined })),
      );
      setIsLoaded(true);
    }
  }, [isLoaded, list?.commentReplies, setTableData]);

  return (
    <ContainerStyled>
      <Typography component="div" variant="subtitle2" color="grey.800">
        {t('dataDashboard.operationAnalysis.autoReply.analysis.title')}
      </Typography>
      <TableContainerWithPagination
        pagination={{
          offset: list?.offset,
          totalCount: list?.totalCount,
          rowsPerPage: ROW_PER_PAGE,

          setPage: setPage,
        }}
        searchText={
          list?.commentReplies && list?.commentReplies?.length > 0
            ? undefined
            : t('commentAutoReply.list.table.noSearchText')
        }
        showPagination={list?.commentReplies && list.commentReplies?.length > 0}
      >
        <Table>
          <TableHead>
            <TableRow>
              <HeaderTableCellStyled width={120}>
                {t('commentAutoReply.list.table.header.name')}
              </HeaderTableCellStyled>
              <HeaderTableCellStyled width={50}>
                <TableCellInnerContainerStyled>
                  <Box className="vertical-container">
                    {t('commentAutoReply.list.table.header.comments')}
                    <Typography variant="caption" color="bluegrey.500">
                      {t('commentAutoReply.list.table.header.usersPerTime')}
                    </Typography>
                  </Box>
                  <Tooltip
                    placement="top"
                    title={t(
                      'dataDashboard.operationAnalysis.autoReply.analysis.tooltip.comments',
                    )}
                  >
                    <InfoIcon
                      sx={{
                        color: 'bluegrey.600',
                        width: '16px',
                        height: '16px',
                        marginLeft: '10px',
                      }}
                    />
                  </Tooltip>
                </TableCellInnerContainerStyled>
              </HeaderTableCellStyled>
              <HeaderTableCellStyled width={50}>
                <TableCellInnerContainerStyled>
                  <Box className="vertical-container">
                    {t('commentAutoReply.list.table.header.publicReply')}
                    <Typography variant="caption" color="bluegrey.500">
                      {t('commentAutoReply.list.table.header.usersPerTime')}
                    </Typography>
                  </Box>
                  <Tooltip
                    placement="top"
                    title={t(
                      'dataDashboard.operationAnalysis.autoReply.analysis.tooltip.publicReply',
                    )}
                  >
                    <InfoIcon
                      sx={{
                        color: 'bluegrey.600',
                        width: '16px',
                        height: '16px',
                        marginLeft: '10px',
                      }}
                    />
                  </Tooltip>
                </TableCellInnerContainerStyled>
              </HeaderTableCellStyled>
              <HeaderTableCellStyled width={50}>
                <TableCellInnerContainerStyled>
                  <Box className="vertical-container">
                    {t('commentAutoReply.list.table.header.privateReply')}
                    <Typography variant="caption" color="bluegrey.500">
                      {t('commentAutoReply.list.table.header.usersPerTime')}
                    </Typography>
                  </Box>
                  <Tooltip
                    placement="top"
                    title={t(
                      'dataDashboard.operationAnalysis.autoReply.analysis.tooltip.privateReply',
                    )}
                  >
                    <InfoIcon
                      sx={{
                        color: 'bluegrey.600',
                        width: '16px',
                        height: '16px',
                        marginLeft: '10px',
                      }}
                    />
                  </Tooltip>
                </TableCellInnerContainerStyled>
              </HeaderTableCellStyled>
              <HeaderTableCellStyled width={50}>
                <TableCellInnerContainerStyled>
                  <Box className="vertical-container">
                    {t('commentAutoReply.list.table.header.conversions')}
                    <Typography variant="caption" color="bluegrey.500">
                      {t('commentAutoReply.list.table.header.usersPerTime')}
                    </Typography>
                  </Box>
                </TableCellInnerContainerStyled>
              </HeaderTableCellStyled>
            </TableRow>
            {tableData?.map((item) => (
              <TableTree
                key={item.id}
                onOpenLayer={() => {
                  openFirstLayer(item.id);
                }}
                onClickFirstLayerTitle={() =>
                  navigateToProjectPage(
                    `/${COMMENT_AUTO_REPLY}/${item.id}/${CONTENT}`,
                  )
                }
                isOpenSecondLayer={isOpenSecondLayer}
                setIsOpenSecondLayer={setIsOpenSecondLayer}
                firstLayerChildren={
                  <>
                    <TableCellStyled component="td">
                      <CommentAutoReplyCellWithData
                        commentID={item.id}
                        num={item.comments}
                        uniqNum={item.uniqComments}
                        isLink
                        numType="comments"
                        name={item.name}
                        isLoading={isDataLoading}
                      />
                    </TableCellStyled>
                    <TableCellStyled component="td">
                      <CommentAutoReplyCellWithData
                        commentID={item.id}
                        num={item.publicReply}
                        uniqNum={item.uniqPublicReply}
                        isLink
                        numType="publicReply"
                        name={item.name}
                        isLoading={isDataLoading}
                      />
                    </TableCellStyled>
                    <TableCellStyled component="td">
                      <CommentAutoReplyCellWithData
                        commentID={item.id}
                        num={item.privateReply}
                        uniqNum={item.uniqPrivateReply}
                        isLink
                        numType="privateReply"
                        name={item.name}
                        isLoading={isDataLoading}
                      />
                    </TableCellStyled>
                    <TableCellStyled component="td">
                      <CommentAutoReplyCellWithData
                        commentID={item.id}
                        num={item.conversations}
                        uniqNum={item.uniqConversations}
                        isLink
                        numType="conversations"
                        name={item.name}
                        isLoading={isDataLoading}
                      />
                    </TableCellStyled>
                  </>
                }
                firstRowTitle={item.name}
                warningTooltips={t('commentAutoReply.list.disconnectTooltip')}
                secondLayerChildren={item?.conditions?.map((condition) => (
                  <TableTreeSecondLayer
                    key={condition.id}
                    name={condition.name}
                    isOpenThirdLayer={isOpenThirdLayer}
                    isOpenSecondLayer={isOpenSecondLayer}
                    setIsOpenThirdLayer={setIsOpenThirdLayer}
                    isShowWarning={false}
                    thirdLayerListLength={Object.keys(condition).length}
                    warningText={t('commentAutoReply.list.disconnectPostText')}
                    thirdLayerChildren={condition.posts?.map((post) => (
                      <TableRowStyled
                        key={post.id}
                        sx={{
                          visibility:
                            isOpenSecondLayer && isOpenThirdLayer
                              ? 'visible'
                              : 'collapse',
                        }}
                      >
                        <TableCellStyled component="td">
                          <SecondLayerFirstCellStyled>
                            {post.fullPicture ? (
                              <ImageContainerStyled
                                sx={{
                                  backgroundImage: `url(${post.fullPicture})`,
                                }}
                              />
                            ) : (
                              <ImageContainerStyled>
                                <DefaultPostIcon />
                              </ImageContainerStyled>
                            )}
                            <Box className="cell-container">{post.message}</Box>
                          </SecondLayerFirstCellStyled>
                        </TableCellStyled>
                        <TableCellStyled component="td">
                          <CommentAutoReplyCellWithData
                            num={post.comments}
                            uniqNum={post.uniqComments}
                            isLoading={isDataLoading}
                          />
                        </TableCellStyled>
                        <TableCellStyled component="td">
                          <CommentAutoReplyCellWithData
                            num={post.publicReply}
                            uniqNum={post.uniqPublicReply}
                            isLoading={isDataLoading}
                          />
                        </TableCellStyled>
                        <TableCellStyled component="td">
                          <CommentAutoReplyCellWithData
                            num={post.privateReply}
                            uniqNum={post.uniqPrivateReply}
                            isLoading={isDataLoading}
                          />
                        </TableCellStyled>
                        <TableCellStyled component="td">
                          <CommentAutoReplyCellWithData
                            num={post.conversations}
                            uniqNum={post.uniqConversations}
                            isLoading={isDataLoading}
                          />
                        </TableCellStyled>
                      </TableRowStyled>
                    ))}
                  >
                    <TableCellStyled component="td">
                      <CommentAutoReplyCellWithData
                        num={condition.comments}
                        uniqNum={condition.uniqComments}
                        isLoading={isDataLoading}
                      />
                    </TableCellStyled>
                    <TableCellStyled component="td">
                      <CommentAutoReplyCellWithData
                        num={condition.publicReply}
                        uniqNum={condition.uniqPublicReply}
                        isLoading={isDataLoading}
                      />
                    </TableCellStyled>
                    <TableCellStyled component="td">
                      <CommentAutoReplyCellWithData
                        num={condition.privateReply}
                        uniqNum={condition.uniqPrivateReply}
                        isLoading={isDataLoading}
                      />
                    </TableCellStyled>
                    <TableCellStyled component="td">
                      <CommentAutoReplyCellWithData
                        num={condition.conversations}
                        uniqNum={condition.uniqConversations}
                        isLoading={isDataLoading}
                      />
                    </TableCellStyled>
                  </TableTreeSecondLayer>
                ))}
              />
            ))}
          </TableHead>
        </Table>
      </TableContainerWithPagination>
    </ContainerStyled>
  );
};

export default AutoReply;
