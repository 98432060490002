import { SubData } from '@frontend/components/ui';
import { usePostDataDashboardOperationFlow } from '@frontend/sorghum/data-access';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  startDate: string;
  endDate: string;
}

export const Flow: FC<Props> = ({ startDate, endDate }: Props) => {
  const [t] = useTranslation();
  const { data } = usePostDataDashboardOperationFlow(startDate, endDate);

  return (
    <>
      <SubData
        size="large"
        title={t(
          `dataDashboard.operationAnalysis.flow.performance.entries.title`,
        )}
        titleTooltip={t(
          `dataDashboard.operationAnalysis.flow.performance.entries.tooltip`,
        )}
        mainData={data?.enterUsers || 0}
        hideLastPeriod
        hideTrendingPercentage
      />
      <SubData
        size="large"
        title={t(
          `dataDashboard.operationAnalysis.flow.performance.enteringUsers.title`,
        )}
        titleTooltip={t(
          `dataDashboard.operationAnalysis.flow.performance.enteringUsers.tooltip`,
        )}
        mainData={data?.uniqEnterUsers || 0}
        hideLastPeriod
        hideTrendingPercentage
      />
      <SubData
        size="large"
        title={t(
          `dataDashboard.operationAnalysis.flow.performance.interactions.title`,
        )}
        titleTooltip={t(
          `dataDashboard.operationAnalysis.flow.performance.interactions.tooltip`,
        )}
        mainData={data?.interactionUsers || 0}
        hideLastPeriod
        hideTrendingPercentage
      />
      <SubData
        size="large"
        title={t(
          `dataDashboard.operationAnalysis.flow.performance.interactingUsers.title`,
        )}
        titleTooltip={t(
          `dataDashboard.operationAnalysis.flow.performance.interactingUsers.tooltip`,
        )}
        mainData={data?.uniqInteractionUsers || 0}
        hideLastPeriod
        hideTrendingPercentage
      />
    </>
  );
};

export default Flow;
