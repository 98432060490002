import {
  CheckMarksSelect,
  DateRangePicker,
  Dialog,
  Loading,
  Typography,
} from '@frontend/components/ui';
import { Alert } from '@frontend/components/utils';
import { usePostDataDashboardExport } from '@frontend/sorghum/data-access';
import { DateType } from '@frontend/sorghum/interface';
import { dayjs, openExportFile } from '@frontend/sorghum/utils';
import { Box } from '@mui/material';
import { styled } from '@mui/system';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

const CenterContainerStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  marginTop: '32px',
}));

export const ExportDashboardDataModal = ({
  isOpen,
  setIsOpen,
}: {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}) => {
  const [t] = useTranslation();

  const { mutate: exportDashboardData } = usePostDataDashboardExport();

  // 日期預設為過去七天
  const [date, setDate] = useState<DateType>({
    startDate: dayjs().add(-7, 'day'),
    endDate: dayjs().add(-1, 'day'),
  });
  const [dayRange, setDayRange] = useState(0);
  const [step, setStep] = useState(0);
  const [selectedDataType, setSelectedDataType] = useState<string[]>([]);

  const dataTypeList = useMemo(
    () => [
      {
        groupName: t('dataDashboard.exportModal.dataType.operationAnalysis'),
        items: [
          {
            id: 1,
            label: t('dataDashboard.exportModal.dataType.flows'),
          },
          {
            id: 2,
            label: t('dataDashboard.exportModal.dataType.broadcasts'),
          },
          {
            id: 3,
            label: t('dataDashboard.exportModal.dataType.commentAutoReplies'),
          },
        ],
      },
      {
        groupName: t('dataDashboard.exportModal.dataType.sourceAnalysis'),
        items: [
          {
            id: 4,
            label: t('dataDashboard.exportModal.dataType.top5EntryChannels'),
          },
          {
            id: 5,
            label: t('dataDashboard.exportModal.dataType.top5Flows'),
          },
          {
            id: 6,
            label: t(
              'dataDashboard.exportModal.dataType.top5CommentAutoReplies',
            ),
          },
        ],
      },
    ],
    [t],
  );

  const handleDownload = useCallback(() => {
    const flatDataList = dataTypeList.map((group) => group.items).flat();

    if (selectedDataType.length > 0) {
      const categories: number[] = selectedDataType
        .map((item) => {
          const findItem = flatDataList.find((data) => item === data.label);

          return findItem ? findItem.id : 0;
        })
        .filter((item) => item !== 0);

      exportDashboardData(
        {
          startDate: date?.startDate?.format('YYYY-MM-DD') || '',
          endDate: date?.endDate?.format('YYYY-MM-DD') || '',
          categories: categories || [],
        },
        {
          onSuccess: (data, variables) => {
            if (data.code === 20000) {
              setIsOpen(false);
              // download file
              const fileUrl = data.data.fileUrl;
              const token = localStorage.getItem('token');
              const projectId = sessionStorage.getItem('project-id') as string;
              if (token) {
                openExportFile({
                  file: fileUrl,
                  projectId,
                  token,
                  category: 2,
                });
              }
            } else {
              Alert.error(t('dataDashboard.exportModal.errorToastDownload'));
              setIsOpen(false);
            }
          },
        },
      );
      setStep(1);
    }
  }, [
    dataTypeList,
    date?.endDate,
    date?.startDate,
    exportDashboardData,
    selectedDataType,
    setIsOpen,
    t,
  ]);

  return (
    <Dialog
      size="s"
      open={isOpen}
      title={
        step === 0
          ? t('dataDashboard.exportModal.title')
          : t('dataDashboard.exportModal.downloadTitle')
      }
      handleClose={() => setIsOpen(false)}
      handleConfirm={step === 0 ? handleDownload : undefined}
      cancelBtnText={t('common.cancel')}
      confirmBtnText={
        step === 0 ? t('dataDashboard.exportModal.download') : undefined
      }
      closeBtn={false}
      disableConfirmButton={selectedDataType.length === 0}
    >
      {step === 0 && (
        <Box>
          <Typography variant="body1" color="grey.800">
            {t('dataDashboard.exportModal.description')}
          </Typography>
          <Box sx={{ marginTop: '20px' }}>
            <DateRangePicker
              date={date}
              setDate={(props) => {
                setDate(props);
                setDayRange(props.endDate?.diff(props.startDate, 'day') || 0);
              }}
              maxDate={dayjs().add(-1, 'day')}
              marginBetween={16}
              helperText={
                dayRange > 180
                  ? t('dataDashboard.exportModal.errorTextDateRange')
                  : t('dataDashboard.exportModal.helperTextDateRange')
              }
              error={dayRange > 180}
              isFocusPicker={false}
            />
          </Box>
          <Box sx={{ marginTop: '20px' }}>
            <CheckMarksSelect
              label={t('dataDashboard.exportModal.dataTypePlaceholder')}
              helperText={t('dataDashboard.exportModal.dataTypeHelperText')}
              groupedItems={dataTypeList}
              onChangeSelectedData={setSelectedDataType}
            />
          </Box>
        </Box>
      )}
      {step === 1 && (
        <Box>
          <Typography variant="body1" color="grey.800">
            {t('dataDashboard.exportModal.downloadDescription')}
          </Typography>
          <CenterContainerStyled>
            <Loading />
          </CenterContainerStyled>
        </Box>
      )}
    </Dialog>
  );
};

export default ExportDashboardDataModal;
