import { uuid } from '@frontend/components/utils';
import { EditorCtx } from '@frontend/editor/external-providers';
import {
  BaseElement,
  BlockTypesEnum,
  ElementTypesEnum,
} from '@frontend/editor/interface';
import {
  getUniquePosition,
  isValueInEnum,
  NEW_ELEMENT_POSITION_DISTANCE,
} from '@frontend/editor/utils';
import { useCallback, useContext } from 'react';
import { useViewport } from 'reactflow';

export function useCanvasCopy() {
  const { ref, elements, addElements } = useContext(EditorCtx);
  const { zoom, x: viewportCenterX, y: viewportCenterY } = useViewport();
  const innerWidth = ref.current?.clientWidth || 0;
  const innerHeight = ref.current?.clientHeight || 0;
  const centerX = (innerWidth / 2 - viewportCenterX) / zoom;
  const centerY = (innerHeight / 2 - viewportCenterY) / zoom;

  const findCopyCenterCoordinates = useCallback(
    (
      blockPositionMap: Map<string, { x: number; y: number }>,
      centerCoordinate: { x: number; y: number },
    ) => {
      const minX = Math.min(
        ...Array.from(blockPositionMap.values()).map((i) => i.x),
      );
      const leftestBlock = Array.from(blockPositionMap.entries()).find(
        ([key, position]) => position.x === minX,
      );

      if (leftestBlock) {
        const offsetX = centerCoordinate.x - leftestBlock[1].x;
        const offsetY = centerCoordinate.y - leftestBlock[1].y;

        return { x: offsetX, y: offsetY };
      }

      return { x: 0, y: 0 };
    },
    [],
  );

  const copyElementData = useCallback(
    (
      arr: BaseElement[],
      centerCoordinate: { x: number; y: number },
    ): BaseElement[] => {
      const idMap = new Map();
      const blockPositionMap = new Map();
      const newObjArr: BaseElement[] = [];

      arr.forEach((obj) => {
        idMap.set(obj.id, uuid());
        // 最外層的元件才會有 position
        if (isValueInEnum(obj.elementType, BlockTypesEnum)) {
          blockPositionMap.set(obj.id, obj.position);
        }
      });

      arr.forEach((obj) => {
        const newObj: BaseElement = {} as BaseElement;
        newObj.id = idMap.get(obj.id);

        Object.keys(obj).forEach((key) => {
          if (key === 'children') {
            const newKey: string[] = obj[key]
              .map((childId) => {
                if (idMap.has(childId)) {
                  return idMap.get(childId);
                }
                return null;
              })
              .filter((item) => item !== null);

            if (newKey) {
              newObj[key] = newKey;
            }
          } else if (
            key === 'id' ||
            key === 'targetID' ||
            key === 'parentID' ||
            key === 'nodeID'
          ) {
            if (idMap.has(obj[key])) {
              newObj[key] = idMap.get(obj[key]);
            } else {
              newObj[key] = '';
            }
          } else if (key === 'inputID' || key === 'outputID') {
            // 生成新的 input 和 output ID
            newObj[key] = uuid();
          } else if (key === 'createDate') {
            newObj[key] = new Date().getTime();
          } else if (key === 'position') {
            if (isValueInEnum(obj.elementType, BlockTypesEnum)) {
              newObj[key] = {
                x:
                  (obj['position'] as { x: number; y: number }).x +
                  findCopyCenterCoordinates(blockPositionMap, centerCoordinate)
                    .x,
                y:
                  (obj['position'] as { x: number; y: number }).y +
                  findCopyCenterCoordinates(blockPositionMap, centerCoordinate)
                    .y,
              };
            }
          } else {
            newObj[key as keyof BaseElement] = obj[key as keyof BaseElement];
          }
        });

        newObjArr.push(newObj);
      });

      return newObjArr;
    },
    [findCopyCenterCoordinates],
  );

  const copyBlocks = useCallback(
    (blockList: string[]) => {
      if (blockList.length <= 0) return;
      const allowCopyBlockList: BaseElement[] = [];

      // 篩選並取得可以被複製的 block
      blockList.forEach((id) => {
        const element = elements.find((el) => el.id === id);
        if (
          element &&
          element.elementType !== ElementTypesEnum.ENTRY_POINT &&
          element.elementType !== ElementTypesEnum.NOTE
        ) {
          allowCopyBlockList.push(element);
        }
      });

      if (allowCopyBlockList.length <= 0) return;

      const centerCoordinate = getUniquePosition(
        centerX,
        centerY,
        elements,
        NEW_ELEMENT_POSITION_DISTANCE,
      );

      if (allowCopyBlockList.length > 0) {
        allowCopyBlockList.forEach((item) => {
          item.children.forEach((child: string) => {
            if (elements.filter((el) => el.id === child).length > 0) {
              const childElement = elements.find((el) => el.id === child);
              if (!childElement) return;
              allowCopyBlockList.push({ ...childElement, id: child });
              if (!childElement.children) return;
              childElement.children.forEach((grandChild) => {
                const grandChildElement = elements.find(
                  (el) => el.id === grandChild,
                );
                if (!grandChildElement) return;
                if (grandChildElement) {
                  allowCopyBlockList.push(grandChildElement);
                }
                if (!grandChildElement.children) return;
                grandChildElement.children.forEach((greatGrandChild) => {
                  const greatGrandChildElement = elements.find(
                    (el) => el.id === greatGrandChild,
                  );
                  if (!greatGrandChildElement) return;
                  if (greatGrandChildElement) {
                    allowCopyBlockList.push(greatGrandChildElement);
                  }
                });
              });
            }
          });
        });
      }

      const copiedBlockList = copyElementData(
        allowCopyBlockList,
        centerCoordinate,
      );

      addElements(copiedBlockList);
    },
    [addElements, centerX, centerY, copyElementData, elements],
  );

  return {
    copyBlocks,
  };
}
