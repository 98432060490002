import {
  PostDataDashboardSourceCommentReplyChartRequest,
  PostDataDashboardSourceCommentReplyChartResponse,
} from '@frontend/sorghum/interface';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { DATA_DASHBOARD_SOURCE_COMMENT_REPLY_CHART } from '../apis';

async function postDataDashboardSourceCommentReplyChart(
  props: PostDataDashboardSourceCommentReplyChartRequest,
): Promise<PostDataDashboardSourceCommentReplyChartResponse[]> {
  return axios
    .post(DATA_DASHBOARD_SOURCE_COMMENT_REPLY_CHART, props)
    .then((res) => res.data.data)
    .catch((err) => ({
      code: 400,
      msg: err,
    }));
}

export function usePostDataDashboardSourceCommentReplyChart() {
  return useMutation({
    mutationKey: ['post-data-dashboard-source-comment-reply-chart'],
    mutationFn: (props: PostDataDashboardSourceCommentReplyChartRequest) =>
      postDataDashboardSourceCommentReplyChart(props),
  });
}

export default usePostDataDashboardSourceCommentReplyChart;
