import { SubData } from '@frontend/components/ui';
import { usePostDataDashboardOperationBroadcast } from '@frontend/sorghum/data-access';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  startDate: string;
  endDate: string;
}

export const Broadcast: FC<Props> = (props: Props) => {
  const [t] = useTranslation();
  const { data } = usePostDataDashboardOperationBroadcast(props);

  return (
    <>
      <SubData
        size="large"
        title={t(
          `dataDashboard.operationAnalysis.broadcast.performance.1.title`,
        )}
        titleTooltip={t(
          `dataDashboard.operationAnalysis.broadcast.performance.1.tooltip`,
        )}
        mainData={data?.publishedBroadcastCount || 0}
        hideLastPeriod
        hideTrendingPercentage
      />
      <SubData
        size="large"
        title={t(
          `dataDashboard.operationAnalysis.broadcast.performance.2.title`,
        )}
        titleTooltip={t(
          `dataDashboard.operationAnalysis.broadcast.performance.2.tooltip`,
        )}
        mainData={data?.sent || 0}
        hideLastPeriod
        hideTrendingPercentage
      />
      <SubData
        size="large"
        title={t(
          `dataDashboard.operationAnalysis.broadcast.performance.3.title`,
        )}
        titleTooltip={t(
          `dataDashboard.operationAnalysis.broadcast.performance.3.tooltip`,
        )}
        mainData={data?.uniqOpenedP ? `${data?.uniqOpenedP} %` : '-'}
        hideLastPeriod
        hideTrendingPercentage
      />
      <SubData
        size="large"
        title={t(
          `dataDashboard.operationAnalysis.broadcast.performance.4.title`,
        )}
        titleTooltip={t(
          `dataDashboard.operationAnalysis.broadcast.performance.4.tooltip`,
        )}
        mainData={data?.ctr ? `${data?.ctr} %` : '-'}
        hideLastPeriod
        hideTrendingPercentage
      />
    </>
  );
};

export default Broadcast;
