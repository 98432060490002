import { SubData } from '@frontend/components/ui';
import { usePostDataOperationCommentReply } from '@frontend/sorghum/data-access';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  startDate: string;
  endDate: string;
}

export const AutoReply: FC<Props> = (props: Props) => {
  const [t] = useTranslation();
  const { data } = usePostDataOperationCommentReply(props);

  return (
    <>
      <SubData
        size="large"
        title={t(
          `dataDashboard.operationAnalysis.autoReply.performance.1.title`,
        )}
        titleTooltip={t(
          `dataDashboard.operationAnalysis.autoReply.performance.1.tooltip`,
        )}
        mainData={data?.publishedCommentReplyCount || 0}
        hideLastPeriod
        hideTrendingPercentage
      />
      <SubData
        size="large"
        title={t(
          `dataDashboard.operationAnalysis.autoReply.performance.2.title`,
        )}
        titleTooltip={t(
          `dataDashboard.operationAnalysis.autoReply.performance.2.tooltip`,
        )}
        mainData={data?.comments || 0}
        hideLastPeriod
        hideTrendingPercentage
      />
      <SubData
        size="large"
        title={t(
          `dataDashboard.operationAnalysis.autoReply.performance.3.title`,
        )}
        titleTooltip={t(
          `dataDashboard.operationAnalysis.autoReply.performance.3.tooltip`,
        )}
        mainData={data?.publicReply || 0}
        hideLastPeriod
        hideTrendingPercentage
      />
      <SubData
        size="large"
        title={t(
          `dataDashboard.operationAnalysis.autoReply.performance.4.title`,
        )}
        titleTooltip={t(
          `dataDashboard.operationAnalysis.autoReply.performance.4.tooltip`,
        )}
        mainData={data?.privateReply || 0}
        hideLastPeriod
        hideTrendingPercentage
      />
      <SubData
        size="large"
        title={t(
          `dataDashboard.operationAnalysis.autoReply.performance.5.title`,
        )}
        titleTooltip={t(
          `dataDashboard.operationAnalysis.autoReply.performance.5.tooltip`,
        )}
        mainData={
          data?.uniqConversationsP ? `${data?.uniqConversationsP} %` : '-'
        }
        hideLastPeriod
        hideTrendingPercentage
      />
    </>
  );
};

export default AutoReply;
