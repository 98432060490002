interface ExportFileProps {
  file: string;
  projectId: string;
  token: string;
  category: number;
}

export const openExportFile = ({
  file,
  projectId,
  token,
  category,
}: ExportFileProps) => {
  window.open(
    `${file}&projectId=${projectId}&token=${token}&category=${category}`,
  );
};
