import { ResponseWithData } from '@frontend/sorghum/interface';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { DASHBOARD_DATA_EXPORT } from '../apis';

interface ResponseData {
  fileUrl: string;
}

interface Props {
  startDate: string;
  endDate: string;
  categories: number[];
}

async function postDataDashboardExport(
  props: Props,
): Promise<ResponseWithData<ResponseData>> {
  return axios
    .post(DASHBOARD_DATA_EXPORT, {
      props,
    })
    .then((res) => res.data)
    .catch((err) => ({
      code: 400,
      msg: err,
    }));
}

export function usePostDataDashboardExport() {
  return useMutation({
    mutationKey: ['post-data-dashboard-export'],
    mutationFn: (props: Props) => postDataDashboardExport(props),
  });
}

export default usePostDataDashboardExport;
