import { globalTheme } from '@frontend/components/external-providers';
import { ChartDataType } from '@frontend/components/interface';
import {
  CHART_MAX_STRING_LENGTH,
  CHART_MIN_WIDTH,
} from '@frontend/components/utils';
import { Box } from '@mui/material';
import { styled } from '@mui/system';
import { first, get } from 'lodash';
import { FC, ReactElement, ReactNode, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Area,
  AreaChart,
  Legend,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis,
} from 'recharts';
import Loading from '../loading/loading';
import Typography from '../typography/typography';

interface ChartAreaProps {
  data: ChartDataType[];
  // 如果有值則圖表只會畫出陣列內的欄位
  labelY?: string;
  displayKeys?: string[];
  title?: string;
  minWidth?: number;
  minText?: string;
  isLoading?: boolean;
  customTooltip?:
    | ReactElement
    | ((props: TooltipProps<string, string>) => ReactNode);
}

const LoadingWrapperStyled = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const ContainerWrapperStyled = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '100%',
  position: 'relative',
  display: 'flex',
  flexDirection: 'row',
  '.recharts-legend-item': {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  '.recharts-legend-item-text': {
    color: `${theme.palette['grey'][900]} !important`,
    fontSize: '12px',
  },
}));

const WarningWrapperStyled = styled(Box)(({ theme }) => ({
  width: '100%',
  height: 'calc(100% + 30px)',
  zIndex: 99,
  // 模糊效果
  background: 'rgba(255,255,255,0.05)',
  backdropFilter: 'blur(5px)',
  position: 'absolute',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '0 34px',
}));

export const ChartArea: FC<ChartAreaProps> = ({
  data,
  displayKeys,
  // TODO: 要加上Y軸文字
  labelY,
  title,
  minText,
  minWidth = CHART_MIN_WIDTH,
  customTooltip,
  isLoading,
}: ChartAreaProps) => {
  const [t] = useTranslation();
  const [chartWidth, setChartWidth] = useState<number>(0);
  const chartColors = get(globalTheme, 'palette.chart', []);
  const subtitle1 = get(globalTheme, 'typography.subtitle1', {});
  const subtitle2 = get(globalTheme, 'typography.subtitle2', {});
  const firstData = first(data) ?? [];

  // 字數過長顯示 ...
  const legendFormatter = useCallback(
    (value: string) =>
      value.length > CHART_MAX_STRING_LENGTH
        ? value.slice(0, CHART_MAX_STRING_LENGTH) + '...'
        : value,
    [],
  );

  const handleResize = useCallback((_width: number) => {
    setChartWidth(_width);
  }, []);

  const _minText = minText ? minText : t('modal.chartWarning');

  const checkEnglishAlphabetWithSpaces = useCallback(
    (str: string | undefined): boolean => {
      if (!str) return false;

      const REGEX = /^[A-Za-z\s]+$/;
      // 如果 Title 是由英文字母所組成的，就 rotate
      return REGEX.test(str);
    },
    [],
  );

  const isTitleRotate = checkEnglishAlphabetWithSpaces(title);

  return (
    <ContainerWrapperStyled>
      {!isLoading && chartWidth < minWidth && (
        <WarningWrapperStyled>
          <Typography color="grey.700" variant="body2">
            {_minText}
          </Typography>
        </WarningWrapperStyled>
      )}
      {isLoading ? (
        <LoadingWrapperStyled>
          <Loading />
        </LoadingWrapperStyled>
      ) : (
        <>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography
              variant="caption"
              color="grey.900"
              sx={{
                writingMode: 'vertical-rl',
                transform: isTitleRotate ? 'rotate(180deg)' : 'undefined',
              }}
            >
              {title}
            </Typography>
          </Box>
          <ResponsiveContainer
            width="100%"
            height="100%"
            onResize={handleResize}
          >
            <AreaChart data={data}>
              <XAxis dataKey="name" style={subtitle2} allowDecimals={false} />
              <YAxis style={subtitle2} allowDecimals={false} />
              <Legend
                formatter={legendFormatter}
                iconType="circle"
                iconSize={9}
              />
              <Tooltip
                content={customTooltip}
                labelStyle={subtitle1}
                itemStyle={subtitle2}
              />
              {(displayKeys ? displayKeys : Object.keys(firstData)).map(
                (key, index) => {
                  if (key !== 'name') {
                    return (
                      <Area
                        key={key}
                        stackId="1"
                        type="monotone"
                        dataKey={key}
                        stroke={chartColors[index]}
                        fill={chartColors[index]}
                      />
                    );
                  } else {
                    return null;
                  }
                },
              )}
            </AreaChart>
          </ResponsiveContainer>
        </>
      )}
    </ContainerWrapperStyled>
  );
};

export default ChartArea;
