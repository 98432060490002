import {
  AutocompleteV2,
  Button,
  OptionTypeV2,
  TagOption,
  TagSelector,
} from '@frontend/components/ui';
import {
  useCanvasUpdate,
  useGetGoogleAccount,
  useGetGoogleSheet,
  useGetGoogleSheetOption,
  useGetProjectID,
  useIsDrawerDataUpdate,
  usePostGoogleSheet,
  useProjectPermission,
} from '@frontend/editor/data-access';
import { EditorCtx, UICtx } from '@frontend/editor/external-providers';
import {
  ActionCellTypesEnum,
  ActionSheetProperty,
  ActionSheetType,
  DrawerTypesEnum,
  GoogleSheet,
  ResponseWithData,
} from '@frontend/editor/interface';
import { useGetFlow } from '@frontend/sorghum/data-access';
import { GoogleAuthCtx } from '@frontend/sorghum/external-providers';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { get, isUndefined } from 'lodash';
import {
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { EditorDrawer } from '../editor-drawer/editor-drawer';

const ContainerStyled = styled(Box)(({ theme }) => ({
  whiteSpace: 'break-spaces',
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  padding: '24px',
}));

const TopWrapperStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
}));

export const DrawerActionSheet = () => {
  const [t] = useTranslation();
  const { data: projectID } = useGetProjectID();
  const { id } = useParams();
  const { onFocusCellID, isDrawerUpdate, setIsDrawerUpdate, getElement } =
    useContext(EditorCtx);
  const uiState = useContext(UICtx);
  const { refreshed: needRefresh, googleSheetAuth } = useContext(GoogleAuthCtx);
  const { canvasUpdateData } = useCanvasUpdate();

  const [accountID, setAccountID] = useState<string | null | undefined>('');
  const [accountError, setAccountError] = useState<string>('');
  const [sheetError, setSheetError] = useState<string>('');
  const [sheetID, setSheetID] = useState<string | null | undefined>('');
  const [tags, setTags] = useState<TagOption[]>([]);

  const { isViewer } = useProjectPermission();
  const { data: flow } = useGetFlow(id as string, projectID as string, false);
  const { data: publishFlow } = useGetFlow(
    id as string,
    projectID as string,
    true,
  );

  const {
    data: accounts,
    isLoading: isLoadingAccount,
    refetch: refetchAccounts,
  } = useGetGoogleAccount({
    projectID: projectID as string,
  });
  const { data: sheets, isFetching: isLoadingSheet } = useGetGoogleSheet({
    projectID: projectID as string,
    accountID: accountID as string,
  });

  const { data: sheetProperties } = useGetGoogleSheetOption({
    projectID: projectID as string,
  });

  const { mutate: createSheet, isLoading: isCreating } = usePostGoogleSheet(
    projectID as string,
  );

  const { isDrawerDataUpdate } = useIsDrawerDataUpdate();

  const { element, actionType } = useMemo(() => {
    const element = getElement(onFocusCellID) as ActionSheetType;
    const actionType = get(element, 'actionType');
    return {
      element,
      actionType,
    };
  }, [getElement, onFocusCellID]);

  const { accountOptions, sheetOptions } = useMemo(() => {
    const accountOptions: OptionTypeV2[] = [];
    const sheetOptions: OptionTypeV2[] = [];

    accountOptions.push({
      label: `+ ${t('canvas.action.drawer.sheet.connect')}`,
      value: 'add account',
      divider: true,
      exclude: true,
      onClick: googleSheetAuth,
    });

    if (accounts && accounts.length > 0) {
      accounts.forEach((account) => {
        accountOptions.push({
          label: account.email,
          value: account.id,
        });
      });
    }

    sheetOptions.push({
      label: `+ ${t('canvas.action.drawer.sheet.addSheet')}`,
      value: 'add account',
      divider: true,
      exclude: true,
      onClick: () => {
        createSheet(accountID as string, {
          onSuccess: (data: ResponseWithData<GoogleSheet>) => {
            if (data.code === 20000 && data.data.id) {
              setSheetID(data.data.id);
            }
          },
        });
      },
    });

    if (sheets && sheets.length > 0) {
      sheets.forEach((sheet) => {
        sheetOptions.push({
          label: sheet.name,
          value: sheet.id,
        });
      });
    }

    return {
      accountOptions,
      sheetOptions,
    };
  }, [accountID, accounts, createSheet, googleSheetAuth, sheets, t]);

  const propertyOptions = useMemo(() => {
    const propertyOptions: TagOption[] = [];

    if (sheetProperties && sheetProperties.length > 0) {
      sheetProperties.forEach((properties) => {
        const allowRemove = publishFlow?.chart.data
          .find((i) => i.id === onFocusCellID)
          ?.data?.properties?.findIndex(
            (j: ActionSheetProperty) => j.value === properties.value,
          );

        propertyOptions.push({
          id: properties.value,
          name:
            properties.type === 1
              ? t(`canvas.action.drawer.sheet.options.${properties.value}`)
              : properties.label,
          group:
            properties.type === 1
              ? 'All'
              : t('canvas.action.drawer.sheet.customAttribute'),
          allowRemove:
            isUndefined(allowRemove) || allowRemove < 0 ? true : false,
        });
      });
    }

    return propertyOptions;
  }, [sheetProperties, publishFlow, onFocusCellID, t]);

  const handleAccountChange = useCallback(
    (_: object, newValue: OptionTypeV2 | null) => {
      if (!newValue) {
        setAccountID('');
        setSheetID('');
        return;
      }
      setAccountID(newValue.value);
    },
    [],
  );

  const handleSheetChange = useCallback(
    (_: object, newValue: OptionTypeV2 | null) => {
      if (!newValue) {
        setSheetID('');
        return;
      }
      setSheetID(newValue.value);
    },
    [],
  );

  const handleApply = useCallback(() => {
    if (!accountID || !sheetID || tags.length <= 0) {
      return;
    }

    const email = accounts?.find((i) => i.id === accountID)?.email;
    const sheetName = sheets?.find((i) => i.id === sheetID)?.name;

    const newProperty: ActionSheetProperty[] = [];
    tags.forEach((tag) => {
      const target = sheetProperties?.find((t) => t.value === tag.id);
      if (target) {
        newProperty.push({
          value: target.value,
          type: target.type,
        });
      }
    });

    canvasUpdateData(element.id, 'data', {
      accountID,
      email,
      sheetID,
      sheetName,
      properties: newProperty,
    });

    uiState.setDrawerType(DrawerTypesEnum.CLOSE);
  }, [
    accountID,
    accounts,
    element?.id,
    sheetID,
    sheetProperties,
    sheets,
    tags,
    uiState,
    canvasUpdateData,
  ]);

  useEffect(() => {
    if (element?.data) {
      setAccountID(element.data.accountID);
      setSheetID(element.data.sheetID);

      if (element.data.properties) {
        const newTags: TagOption[] = [];
        element.data.properties.forEach((i) => {
          const targetTag = sheetProperties?.find((t) => t.value === i.value);
          if (targetTag) {
            newTags.push({
              id: targetTag.value,
              name:
                targetTag.type === 1
                  ? t(`canvas.action.drawer.sheet.options.${targetTag.value}`)
                  : targetTag.label,
            });
          }
        });
        setTags(newTags);
      } else {
        setTags([]);
      }
    }
  }, [element, sheetProperties, t]);

  useEffect(() => {
    if (accountID) {
      // 找不到資料代表權限消失
      if (!accounts?.find((i) => i.id === accountID)) {
        setAccountError(t('canvas.action.drawer.sheet.accountDisconnected'));
        return;
      }
    }
    setAccountError('');
  }, [accountID, accounts, element, t]);

  useEffect(() => {
    if (sheetID && !isLoadingSheet) {
      // 找不到資料代表權限消失
      if (!sheets?.find((i) => i.id === sheetID)) {
        setSheetError(t('canvas.action.drawer.sheet.sheetNotFound'));
        return;
      }
    }
    setSheetError('');
  }, [accountID, accounts, element, sheetID, sheets, t, isLoadingSheet]);

  useEffect(() => {
    if (needRefresh) {
      refetchAccounts();
      const id = localStorage.getItem('google-id');
      if (id) {
        setAccountID(id);
        localStorage.removeItem('google-id');
      }
    }
  }, [needRefresh, refetchAccounts]);

  useEffect(() => {
    if (
      element?.data &&
      uiState.drawerType === DrawerTypesEnum.ACTION_EXPORT_GOOGLE_SHEET
    ) {
      const tagList = tags.map((item) => item.id);
      const propertyList = element.data.properties.map((item) => item.value);

      if (
        isDrawerDataUpdate(
          {
            accountID: element.data.accountID,
            sheetID: element.data.sheetID,
            list: JSON.stringify(tagList),
          },
          {
            accountID,
            sheetID,
            list: JSON.stringify(propertyList),
          },
        )
      ) {
        setIsDrawerUpdate(true);
      } else {
        setIsDrawerUpdate(false);
      }
    }
  }, [
    accountID,
    element.data,
    sheetID,
    tags,
    uiState.drawerType,
    isDrawerDataUpdate,
    setIsDrawerUpdate,
  ]);

  const isPublish = useMemo(() => {
    if (flow?.publishedElement && element?.id) {
      let isPublish = false;
      flow.publishedElement.forEach((i) => {
        if (i.elementId === element.id) {
          isPublish = true;
        }
      });
      return isPublish;
    }
    return false;
  }, [element?.id, flow?.publishedElement]);

  const isOpen =
    element?.data && actionType === ActionCellTypesEnum.EXPORT_GOOGLE_SHEET;

  if (isOpen) {
    return (
      <EditorDrawer drawerType={DrawerTypesEnum.ACTION_EXPORT_GOOGLE_SHEET}>
        <ContainerStyled>
          <TopWrapperStyled>
            <Typography variant="h6" color="grey.900">
              <Trans i18nKey="canvas.action.sheet.title" />
            </Typography>
            <Typography variant="body2">
              <Trans i18nKey="canvas.action.drawer.sheet.info" />
            </Typography>
            <AutocompleteV2
              required
              loading={isLoadingAccount}
              errorMessage={accountError}
              value={accountID}
              onChange={handleAccountChange}
              noOptionsText={t('canvas.coupon.empty')}
              placeholder={t('canvas.action.drawer.sheet.account')}
              label={t('canvas.action.drawer.sheet.account')}
              options={accountOptions}
              disabled={isPublish || isViewer}
            />
            <AutocompleteV2
              required
              loading={isLoadingSheet || isCreating}
              errorMessage={sheetError}
              value={sheetID}
              disabled={
                isViewer ||
                !accountID ||
                !!accountError ||
                (isPublish && !sheetError)
              }
              onChange={handleSheetChange}
              noOptionsText={t('canvas.coupon.empty')}
              placeholder={t('canvas.action.drawer.sheet.sheet')}
              label={t('canvas.action.drawer.sheet.sheet')}
              options={sheetOptions}
            />
            <Typography variant="subtitle1" color="grey.800">
              <Trans i18nKey="canvas.action.drawer.sheet.data" />
            </Typography>
            <TagSelector
              required
              options={propertyOptions}
              value={tags}
              allowNew={false}
              setValue={setTags}
              placeholder={t('canvas.action.drawer.sheet.addData')}
              helperText={
                tags.length <= 0
                  ? t('canvas.action.drawer.sheet.dataEmpty')
                  : ''
              }
              minHeight={64}
            />
          </TopWrapperStyled>
          <Button
            onClick={handleApply}
            size="large"
            variant="contained"
            fullWidth
            disabled={!isDrawerUpdate}
            sx={{ marginTop: '24px' }}
          >
            <Trans i18nKey="common.apply" />
          </Button>
        </ContainerStyled>
      </EditorDrawer>
    );
  } else {
    return null;
  }
};

export default memo(DrawerActionSheet);
