export enum ModalTypesEnum {
  RENAME_FLOW = 'RENAME_FLOW',
  RENAME_BROADCAST = 'RENAME_BROADCAST',
  RENAME_SEQUENCE = 'RENAME_SEQUENCE',
  WELCOME_MESSAGE_FLOW_UNPUBLISH = 'WELCOME_MESSAGE_FLOW_UNPUBLISH',
  DEFAULT_ANSWER_FLOW_UNPUBLISH = 'DEFAULT_ANSWER_FLOW_UNPUBLISH',
  COMMON = 'COMMON',
  CLOSE = 'CLOSE',
  RENAME_COUPON = 'RENAME_COUPON',
  CREATE_PROJECT = 'CREATE_PROJECT',
  RENAME_PROJECT = 'RENAME_PROJECT',
  TUTORIAL_MODAL = 'TUTORIAL_MODAL',
  RENAME_COMMENT_AUTO_REPLY = 'RENAME_COMMENT_AUTO_REPLY',
  DELETE_COMMENT_AUTO_REPLY = 'DELETE_COMMENT_AUTO_REPLY',
  EDIT_COMMENT_AUTO_REPLY_POST = 'EDIT_COMMENT_AUTO_REPLY_POST',
  UNSUBSCRIBE_PAYMENT = 'UNSUBSCRIBE_PAYMENT',
  TRIAL_END = 'TRIAL_END',
  OFFICIAL_VERSION_LAUNCHED = 'OFFICIAL_VERSION_LAUNCHED',
  CHANGE_PAYMENT_METHOD = 'CHANGE_PAYMENT_METHOD',
  TAP_PAY_LOADING = 'TAP_PAY_LOADING',
  VIEWER_MODE = 'VIEWER_MODE',
  COPY_FLOW = 'COPY_FLOW',
  COMMENT_AUTO_REPLY_ONBOARDING_WELCOME = 'COMMENT_AUTO_REPLY_ONBOARDING_WELCOME',
  COMMENT_AUTO_REPLY_ONBOARDING_MAIN_TUTORIAL = 'COMMENT_AUTO_REPLY_ONBOARDING_MAIN_TUTORIAL',
  COMMENT_AUTO_REPLY_ONBOARDING_CONFIRM_BEHAVIOR = 'COMMENT_AUTO_REPLY_ONBOARDING_CONFIRM_BEHAVIOR',
}

export enum PublishStatus {
  UNPUBLISH = 1,
  PUBLISHED = 2,
  UNPUBLISHED_CHANGES = 3,
}

export enum PersistentMenuPublishErrorsEnum {
  NO_TITLE = 'NO_TITLE',
  DUPLICATED_TITLE = 'DUPLICATED_TITLE',
  NO_SELECT_FLOW = 'NO_SELECT_FLOW',
  FLOW_UNPUBLISHED = 'FLOW_UNPUBLISHED',
  NO_URL = 'NO_URL',
  INCORRECT_URL_FORMAT = 'INCORRECT_URL_FORMAT',
}

export enum EditStatusEnum {
  EDIT = 'EDIT',
  VIEW = 'VIEW',
}

export enum BroadcastButtonTypeEnum {
  REDIRECT = 'redirectButton',
  URL = 'urlButton',
  CALL = 'callButton',
}

export enum BroadcastErrorEnum {
  NO_TOPIC = 'no_topic',
  TEXT_BUTTON_NO_TEXT = 'text_button_no_text',
  CELL_NO_TITLE = 'cell_no_title',
  NO_CONDITION_VALUE = 'no_condition_value',
  NO_SELECTED_FLOW = 'no_selected_flow',
  NO_URL = 'no_url',
  WRONG_URL_FORMAT = 'wrong_url_format',
  NO_PHONE = 'no_phone',
  WRONG_PHONE_FORMAT = 'wrong_phone_format',
}

export enum CouponErrorEnum {
  SUM_NOT_CORRECT = 'The sum of probabilities must not exceed 100.',
  NO_UPLOAD_FILE = 'Please upload a file.',
}

export enum FlowTemplateEnum {
  'RN' = 1,
  'AUTO_REPLY' = 2,
  'DRAW' = 3,
}

export enum LoadingModalTypeEnum {
  BLOCK = 'BLOCK',
  MASK = 'MASK',
}

export enum WebSocketTypeEnum {
  NEW_RN_RECEIVER = 1,
  SEND_FIRST_RN = 2,
  SEND_RN_UNPUBLISHED_FLOW = 3,
  PUBLISHED_FIRST_FLOW = 4,
}

export enum WebSocketEventNameEnum {
  NOTIBAR = 'notibar',
  BULK_EDITING = 'bulkEditing',
}

export enum NotificationTypeEnum {
  VIEWER = 'VIEWER',
  PAYMENT_ERROR = 'PAYMENT_ERROR',
  TRIAL_PLAN_COUNTDOWN = 'TRIAL_PLAN_COUNTDOWN',
  TRIAL_END = 'TRIAL_END',
  FACEBOOK_PAGE_DISCONNECT = 'FACEBOOK_PAGE_DISCONNECT',
  NEW_RN_RECEIVER = 'NEW_RN_RECEIVER',
  FLOW_UNPUBLISHED = 'FLOW_UNPUBLISHED',
  PERMISSION_CHANGED = 'PERMISSION_CHANGED',
}

export interface EditInvoiceFormType {
  country: string;
  address: string;
  zip: string;
  tax: string;
  name: string;
  carrierType: number;
  code: string;
  companyName: string;
  companyCountry: string;
  companyZip: string;
  companyAddress: string;
}

export enum MissionModalTypeEnum {
  LEVEL1_LESSON1 = 'level1_lesson1',
  LEVEL1_LESSON2 = 'level1_lesson2',
  LEVEL1_LESSON3 = 'level1_lesson3',
  LEVEL2_LESSON1 = 'level2_lesson1',
  LEVEL2_LESSON2 = 'level2_lesson2',
  CLOSE = 'close',
}

export enum CheckListStatusEnum {
  ALL = 'ALL',
  PART = 'PART',
  NONE = 'NONE',
}

export enum FilterConditionTypeEnum {
  ATTRIBUTE = 'attribute',
  TAG = 'tag',
  TOPIC = 'notification',
  SEQUENCE = 'sequence',
  BROADCAST = 'broadcast',
  NAME = 'name',
  GENDER = 'gender',
  LAST_ACTIVE = 'lastActive',
  JOINED = 'joined',
  MESSENGER_ID = 'socialId',
  LOCALE = 'locale',
  TIME_ZONE = 'timezone',
  COMMENT_REPLY = 'commentReply',
  FLOW_BLOCK = 'flowBlock',
}

export enum OrderByEnum {
  ASC = 'ASC',
  DESC = 'DESC',
}

export enum DataDashboardOperationAnalysisChipEnum {
  FLOW = 'flow',
  BROADCAST = 'broadcast',
  AUTO_REPLY = 'autoReply',
}
