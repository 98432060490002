import { EditorCtx } from '@frontend/editor/external-providers';
import {
  FlowEntryPoint,
  TextButtonCellTypesEnum,
} from '@frontend/editor/interface';
import { getEdge } from '@frontend/editor/utils';
import { set } from 'lodash';
import { useCallback, useContext } from 'react';
import { applyEdgeChanges, useUpdateNodeInternals } from 'reactflow';

export interface UseCanvasUpdate {
  canvasUpdateButtonType: (
    elementID: string,
    type: TextButtonCellTypesEnum,
  ) => void;
  canvasUpdateLabel: (id: string, value: string) => void;
  canvasConnectBlock: (outputID: string, inputBlockID: string) => void;
  canvasSortElement: (id: string, oldIndex: number, newIndex: number) => void;
  canvasDisconnectBlock: (outputID: string) => void;
  canvasUpdateFlowEntryPoint: (newFlowEntries: FlowEntryPoint[]) => void;
  canvasUpdateData: (id: string, path: string, value: any) => void;
}

export function useCanvasUpdate(): UseCanvasUpdate {
  const {
    setEdges,
    updateElementData,
    connectElement,
    handleSetFlowEntryPoint,
    removeConnects,
    setElements,
    getElement,
    getElementByOutputID,
    sortElement,
  } = useContext(EditorCtx);
  const updateNodeInternals = useUpdateNodeInternals();

  const canvasUpdateLabel = useCallback(
    (id: string, value: string) => {
      updateElementData(id, 'label', value);
    },
    [updateElementData],
  );

  const canvasConnectBlock = useCallback(
    (outputID: string, inputBlockID: string) => {
      const source = getElementByOutputID(outputID);
      const target = getElement(inputBlockID);
      if (!source || !target) return;
      connectElement(outputID, inputBlockID);
      const newEdge = getEdge(source, target);
      if (newEdge) {
        setEdges((edges) =>
          applyEdgeChanges(
            [
              {
                id: newEdge.id,
                type: 'remove',
              },
              { item: newEdge, type: 'add' },
            ],
            edges,
          ),
        );
      }
    },
    [connectElement, getElement, getElementByOutputID, setEdges],
  );

  const canvasDisconnectBlock = useCallback(
    (outputID: string) => {
      removeConnects([outputID]);
    },
    [removeConnects],
  );

  const canvasSortElement = useCallback(
    (id: string, oldIndex: number, newIndex: number) => {
      const el = getElement(id);
      sortElement(id, oldIndex, newIndex);

      // 觸發 node rerender 以更新 handle point position
      if (el?.nodeID) {
        updateNodeInternals(el.nodeID);
      } else if (el) {
        // updateNodeInternals 會執行得比 sortElement 快，所以設 setTimeout 確保 sortElement 會先執行
        setTimeout(() => updateNodeInternals(el.id), 500);
      }
    },
    [getElement, sortElement, updateNodeInternals],
  );

  const canvasUpdateFlowEntryPoint = useCallback(
    (newFlowEntries: FlowEntryPoint[]) => {
      handleSetFlowEntryPoint(newFlowEntries);
    },
    [handleSetFlowEntryPoint],
  );

  const canvasUpdateButtonType = useCallback(
    (id: string, type: TextButtonCellTypesEnum) => {
      updateElementData(id, 'buttonType', type);
    },
    [updateElementData],
  );

  const canvasUpdateData = useCallback(
    (id: string, path: string, value: any) => {
      setElements(
        (draft) =>
          draft.forEach((element) => {
            if (element.id === id) {
              set(element, path, value);
            }
          }),
        false,
      );
    },
    [setElements],
  );

  return {
    canvasUpdateButtonType,
    canvasUpdateLabel,
    canvasConnectBlock,
    canvasSortElement,
    canvasDisconnectBlock,
    canvasUpdateFlowEntryPoint,
    canvasUpdateData,
  };
}

export default useCanvasUpdate;
