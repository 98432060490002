import { globalTheme } from '@frontend/components/external-providers';
import { ChartDataType } from '@frontend/components/interface';
import {
  CHART_DATA_EMPTY_KEY_PREFIX,
  CHART_MAX_STRING_LENGTH,
  CHART_MIN_WIDTH,
} from '@frontend/components/utils';
import { Box } from '@mui/material';
import { styled } from '@mui/system';
import { first, get } from 'lodash';
import { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import Loading from '../loading/loading';
import Typography from '../typography/typography';

interface ChartLineProps {
  data: ChartDataType[];
  title?: string;
  minWidth?: number;
  minText?: string;
  labelLimit?: number;
  // 如果傳入 empty_key_ 為前綴的 key 值，顯示的 label 會轉為此參數
  emptyDisplayName?: string;
  isLoading?: boolean;
  showMask?: boolean;
  tooltipFormatter?: () => string;
}

const LoadingWrapperStyled = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const ContainerWrapperStyled = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '100%',
  position: 'relative',
  display: 'flex',
  flexDirection: 'row',

  '.recharts-legend-item': {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  '.recharts-legend-item-text': {
    color: `${theme.palette['grey'][900]} !important`,
    fontSize: '12px',
  },
}));

const WarningWrapperStyled = styled(Box)(({ theme }) => ({
  width: '100%',
  height: 'calc(100% + 30px)',
  zIndex: 99,
  // 模糊效果
  background: 'rgba(255,255,255,0.05)',
  backdropFilter: 'blur(5px)',
  position: 'absolute',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '0 34px',
}));

export const ChartLine: FC<ChartLineProps> = ({
  data,
  title,
  minText,
  minWidth = CHART_MIN_WIDTH,
  emptyDisplayName = '',
  labelLimit = CHART_MAX_STRING_LENGTH,
  isLoading = false,
  showMask = false,
}: ChartLineProps) => {
  const [t] = useTranslation();
  const [chartWidth, setChartWidth] = useState<number>(0);
  const chartColors = get(globalTheme, 'palette.chart', []);
  const subtitle1 = get(globalTheme, 'typography.subtitle1', {});
  const subtitle2 = get(globalTheme, 'typography.subtitle2', {});
  const firstData = first(data) ?? [];

  // empty_key_ 開頭代表名字要顯示為 emptyDisplayName 字串
  const legendFormatter = useCallback(
    (value: string) => {
      if (value.startsWith(CHART_DATA_EMPTY_KEY_PREFIX)) {
        return emptyDisplayName;
      } else {
        // 字數過長顯示 ...
        return value.length > labelLimit
          ? value.slice(0, labelLimit) + '...'
          : value;
      }
    },
    [emptyDisplayName, labelLimit],
  );

  const labelFormatter = useCallback(
    (value: string, name: string) => {
      if (name.startsWith(CHART_DATA_EMPTY_KEY_PREFIX)) {
        return [value, emptyDisplayName];
      } else {
        return [value, name];
      }
    },
    [emptyDisplayName],
  );

  const handleResize = useCallback((_width: number) => {
    setChartWidth(_width);
  }, []);

  const _minText = minText ? minText : t('modal.chartWarning');

  const checkEnglishAlphabetWithSpaces = useCallback(
    (str: string | undefined): boolean => {
      if (!str) return false;

      const REGEX = /^[A-Za-z\s]+$/;
      // 如果 Title 是由英文字母所組成的，就 rotate
      return REGEX.test(str);
    },
    [],
  );

  const isTitleRotate = checkEnglishAlphabetWithSpaces(title);

  return (
    <ContainerWrapperStyled>
      {(!isLoading && chartWidth < minWidth) ||
        (showMask && (
          <WarningWrapperStyled>
            <Typography color="grey.700" variant="body2">
              {_minText}
            </Typography>
          </WarningWrapperStyled>
        ))}
      {isLoading ? (
        <LoadingWrapperStyled>
          <Loading />
        </LoadingWrapperStyled>
      ) : (
        <>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography
              variant="caption"
              color="grey.900"
              sx={{
                writingMode: 'vertical-rl',
                transform: isTitleRotate ? 'rotate(180deg)' : 'undefined',
              }}
            >
              {title}
            </Typography>
          </Box>
          <ResponsiveContainer
            width="100%"
            height="100%"
            onResize={handleResize}
          >
            <LineChart data={data}>
              {Object.keys(firstData).map((key, index) => {
                if (key !== 'name') {
                  return (
                    <Line
                      key={key}
                      type="linear"
                      dataKey={key}
                      stroke={chartColors[index]}
                      dot={false}
                    />
                  );
                } else {
                  return null;
                }
              })}
              <XAxis dataKey="name" style={subtitle2} />
              <YAxis
                // label={{ value: title, angle: -90, position: 'insideLeft' }}
                style={subtitle2}
                allowDecimals={false}
              />
              <Legend
                formatter={legendFormatter}
                iconType="circle"
                iconSize={9}
              />
              <Tooltip
                formatter={labelFormatter}
                labelStyle={subtitle1}
                itemStyle={subtitle2}
              />
            </LineChart>
          </ResponsiveContainer>
        </>
      )}
    </ContainerWrapperStyled>
  );
};

export default ChartLine;
