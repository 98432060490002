import {
  PostDataDashboardOverviewChartRequest,
  PostDataDashboardOverviewChartResponse,
} from '@frontend/sorghum/interface';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { DASHBOARD_DATA_OVERVIEW_CHART } from '../apis';

const defaultValue = [] as PostDataDashboardOverviewChartResponse[];

async function postDataDashboardOverviewChart(
  projectID: string,
  params: PostDataDashboardOverviewChartRequest,
): Promise<PostDataDashboardOverviewChartResponse[]> {
  return axios
    .post(`${DASHBOARD_DATA_OVERVIEW_CHART}?projectId=${projectID}`, params)
    .then((res) => res.data.data)
    .catch((err) => defaultValue);
}

export function usePostDataDashboardOverviewChart(projectID: string) {
  return useMutation({
    mutationKey: ['post-data-dashboard-overview-chart'],
    mutationFn: (props: PostDataDashboardOverviewChartRequest) => {
      return postDataDashboardOverviewChart(projectID, props);
    },
  });
}

export default usePostDataDashboardOverviewChart;
