import {
  PostDataDashboardOperationFlowChartRequest,
  PostDataDashboardOperationFlowChartResponse,
} from '@frontend/sorghum/interface';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { DATA_DASHBOARD_OPERATION_FLOW_CHART } from '../apis';

const defaultData: PostDataDashboardOperationFlowChartResponse[] = [];

async function postDataDashboardOperationFlowChart(
  params: PostDataDashboardOperationFlowChartRequest,
): Promise<PostDataDashboardOperationFlowChartResponse[]> {
  return axios
    .post(DATA_DASHBOARD_OPERATION_FLOW_CHART, params)
    .then((res) => res.data.data)
    .catch((err) => defaultData);
}

export const usePostDataDashboardOperationFlowChart = (
  startDate: string,
  endDate: string,
  period: number,
) => {
  return useQuery({
    queryKey: [
      'post-data-dashboard-operation-flow-chart',
      { startDate, endDate, period },
    ],
    queryFn: () =>
      postDataDashboardOperationFlowChart({ startDate, endDate, period }),
  });
};

export default usePostDataDashboardOperationFlowChart;
