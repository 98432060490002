import { globalTheme } from '@frontend/components/external-providers';
import { useCanvasCollect } from '@frontend/editor/data-access';
import { EditorCtx } from '@frontend/editor/external-providers';
import { sendGAEvent } from '@frontend/sorghum/utils';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material';
import { get } from 'lodash';
import { useCallback, useContext, useState } from 'react';
import {
  applyEdgeChanges,
  EdgeLabelRenderer,
  EdgeProps,
  getBezierPath,
  useViewport,
} from 'reactflow';

// 外層透明的邊，讓觸發範圍比畫面範圍大一些
const EdgeHoverWrapperStyled = styled('path')(({ theme }) => ({
  strokeWidth: '12px',
  stroke: 'transparent',
  fill: 'transparent',

  '&:hover': {
    '& + path': {
      stroke: get(globalTheme, 'palette.blue.600'),
    },
  },
}));

const EdgeStyled = styled('path')(({ theme }) => ({
  strokeWidth: '2px',

  '&.react-flow__edge-path-focus': {
    stroke: get(globalTheme, 'palette.blue.600'),
    fill: 'none',
    markerEnd: 'url(#arrow-closed-focus)',
  },
  '&.react-flow__edge-path': {
    '&:hover': {
      stroke: get(globalTheme, 'palette.blue.600'),
      markerEnd: 'url(#arrow-closed-focus)',
    },
  },
  markerEnd: 'url(#arrow-closed)',
}));

export const CustomFocusMarker = () => {
  return (
    <svg width="0" height="0">
      <defs>
        <marker
          id="arrow-closed-focus"
          markerWidth="12.5"
          markerHeight="12.5"
          viewBox="-10 -10 20 20"
          markerUnits="strokeWidth"
          orient="auto-start-reverse"
          refX="0"
          refY="0"
        >
          <polyline
            stroke={get(globalTheme, 'palette.blue.600')}
            strokeLinecap="round"
            strokeLinejoin="round"
            fill={get(globalTheme, 'palette.blue.600')}
            points="-5,-4 0,0 -5,4 -5,-4"
          ></polyline>
        </marker>
      </defs>
    </svg>
  );
};

export const CustomMarker = () => {
  return (
    <svg width="0" height="0">
      <defs>
        <marker
          id="arrow-closed"
          markerWidth="12.5"
          markerHeight="12.5"
          viewBox="-10 -10 20 20"
          markerUnits="strokeWidth"
          orient="auto-start-reverse"
          refX="0"
          refY="0"
        >
          <polyline
            stroke="#b1b1b7"
            strokeLinecap="round"
            strokeLinejoin="round"
            fill="#b1b1b7"
            points="-5,-4 0,0 -5,4 -5,-4"
          ></polyline>
        </marker>
      </defs>
    </svg>
  );
};

const EdgeDeleteIconStyled = styled('div')<{
  $labelX: number;
  $labelY: number;
  $zoom: number;
}>(({ $labelX, $labelY, $zoom }) => ({
  position: 'absolute',
  transform: `translate(-50%, -50%) translate(${$labelX}px,${$labelY}px)`,
  // everything inside EdgeLabelRenderer has no pointer events by default
  // if you have an interactive element, set pointer-events: all
  pointerEvents: 'all',
  background: get(globalTheme, 'palette.grey.white'),
  color: get(globalTheme, 'palette.grey.600'),
  border: `${get(globalTheme, 'palette.grey.white')} ${2 / $zoom}px solid`,
  fontSize: `${16 / $zoom}px`,
  borderRadius: '50%',
  width: `${28 / $zoom}px`,
  height: `${28 / $zoom}px`,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: `${10 / $zoom}px`,
  cursor: 'pointer',
  boxShadow: get(globalTheme, 'shadows.8', ''),
  '&:hover': {
    border: `${get(globalTheme, 'palette.blue.600')} 2px solid`,
  },
}));

const EditorCustomEdge = ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  source,
  sourceHandleId,
  target,
  selected,
}: EdgeProps) => {
  const [edgePath, labelX, labelY] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });
  const {
    setEdges,
    readonly,
    tourMode,
    getElement,
    getElementByOutputID,
    removeConnect,
  } = useContext(EditorCtx);
  const [isHover, setIsHover] = useState(false);

  const { zoom } = useViewport();
  const { selectedNodeIDs } = useCanvasCollect();

  const handleDeleteClick = useCallback(() => {
    sendGAEvent(
      'Chat Flow Edit',
      'Delete connector',
      'Chat Flow Edit - connector- delete - click',
      '',
    );

    setEdges((prevEdges) =>
      applyEdgeChanges(
        [
          {
            id: id,
            type: 'remove',
          },
        ],
        prevEdges,
      ),
    );

    // 有 sourceHandleId 的代表是內層的 Element / Cell，sourceHandleId 會是該 Element / Cell 的 outputID
    if (sourceHandleId) {
      const element = getElementByOutputID(sourceHandleId);
      if (element) {
        removeConnect(element?.outputID);
      }
      // 沒有 sourceHandleId 的代表是最外層的 Block，source 會是該 Block 的 outputID
    } else {
      const element = getElement(source);
      if (element) {
        removeConnect(element?.outputID);
      }
    }
  }, [
    getElement,
    getElementByOutputID,
    id,
    removeConnect,
    setEdges,
    source,
    sourceHandleId,
  ]);

  return (
    <>
      <EdgeHoverWrapperStyled
        id={id}
        d={edgePath}
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
      />

      <EdgeStyled
        className={
          isHover || selected
            ? 'react-flow__edge-path-focus'
            : 'react-flow__edge-path'
        }
        d={edgePath}
      />
      {!readonly && !tourMode && isHover && (
        <EdgeLabelRenderer>
          <EdgeDeleteIconStyled
            $labelX={labelX}
            $labelY={labelY}
            $zoom={zoom}
            onClick={handleDeleteClick}
            onMouseEnter={() => setIsHover(true)}
            onMouseLeave={() => setIsHover(false)}
          >
            <CloseIcon
              style={{
                fontSize: '1em',
              }}
            />
          </EdgeDeleteIconStyled>
        </EdgeLabelRenderer>
      )}
    </>
  );
};

export default EditorCustomEdge;
