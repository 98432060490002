import { PlacementEnum, TourMaskProps } from '@frontend/components/interface';
import { Dialog, VideoModal } from '@frontend/components/ui';
import { usePostCommentAutoReply } from '@frontend/editor/data-access';
import { usePostAccountTutorial } from '@frontend/sorghum/data-access';
import { UICtx } from '@frontend/sorghum/external-providers';
import {
  MissionModalTypeEnum,
  ModalTypesEnum,
} from '@frontend/sorghum/interface';
import {
  COMMENT_AUTO_REPLY,
  CONTENT,
  dayjs,
  HOW_TO_USE_COMMENT_AUTO_REPLY_ZH,
  LIST,
  sendGAEvent,
  usePath,
} from '@frontend/sorghum/utils';
import { Box, Link, styled, Typography } from '@mui/material';
import i18next from 'i18next';
import { useCallback, useContext, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Step1After from '../../images/comment-auto-reply-onboarding/step1-finish.png';
import Step1Before from '../../images/comment-auto-reply-onboarding/step1.png';
import Step2After from '../../images/comment-auto-reply-onboarding/step2-finish.png';
import Step2Before from '../../images/comment-auto-reply-onboarding/step2.png';
import Step3After from '../../images/comment-auto-reply-onboarding/step3-finish.png';
import Step3Before from '../../images/comment-auto-reply-onboarding/step3.png';

const ContentWrapperStyled = styled(Box)(({ theme }) => ({
  zIndex: 100,
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
}));

const ImageRowWrapperStyled = styled(Box)(({ theme }) => ({
  zIndex: 100,
  display: 'inline-flex',
  justifyContent: 'space-between',
  padding: '0 66px',
}));

const StepWrapperStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',
  '& > img': {
    width: '200px',
    height: '200px',
    marginBottom: '16px',
  },
}));

interface CommentAutoReplyOnboardingWelcomeModalProps {
  googleStorageEndpoint: string;
}

export const CommentAutoReplyOnboardingWelcomeModal = ({
  googleStorageEndpoint,
}: CommentAutoReplyOnboardingWelcomeModalProps) => {
  const [t] = useTranslation();
  const {
    modalType,
    openRenameCommentAutoReplyModal,
    openModal,
    closeModal,
    setTour,
    setIsTouring,
    closeDemoMode,
    closeTour,
    openDemoMode,
    nextTour,
  } = useContext(UICtx);
  const { navigateToProjectPage } = usePath();
  const { mutate: addCommentReply } = usePostCommentAutoReply();
  const { mutate: updateAccountTutorial } = usePostAccountTutorial();

  const [isVideoEnded, setIsVideoEnded] = useState(false);
  const [completed, setIsCompleted] = useState(false);
  const mode = completed ? 'finish' : 'start';

  const onAddReply = useCallback(() => {
    addCommentReply(
      {
        name: t('commentAutoReply.list.defaultReplyName'),
        conditionName: t('commentAutoReply.list.defaultConditionName'),
      },
      {
        onSuccess: (data, variables) => {
          if (data.code === 20000) {
            sendGAEvent(
              'Comment auto reply',
              'comment auto reply - created',
              'Comment auto reply - created',
              '',
            );
            navigateToProjectPage(
              `/${COMMENT_AUTO_REPLY}/${data.data.id}/${CONTENT}`,
            );
            openRenameCommentAutoReplyModal(data.data.id);
          }
        },
      },
    );
  }, [
    addCommentReply,
    navigateToProjectPage,
    openRenameCommentAutoReplyModal,
    t,
  ]);

  const finishTutorial = useCallback(() => {
    updateAccountTutorial({
      marketingGuru: {
        commentAutoReply: {
          completedAt: dayjs().toISOString(),
        },
      },
    });
    closeTour();
    setIsCompleted(true);
    openModal(ModalTypesEnum.COMMENT_AUTO_REPLY_ONBOARDING_WELCOME);
  }, [updateAccountTutorial, closeTour, openModal]);

  const onboardingTour: TourMaskProps[] = useMemo(() => {
    return [
      {
        targets: ['add_comment_auto_reply'],
        highlight: 'add_comment_auto_reply',
        highlightType: 'button',
        onHighlightClick: () => {
          setIsTouring(false);
          openModal(ModalTypesEnum.COMMENT_AUTO_REPLY_ONBOARDING_MAIN_TUTORIAL);
          setIsVideoEnded(false);
        },
        tooltipProps: {
          description: t('tour.commentAutoReplyOnBoarding.step1.description'),
          placement: PlacementEnum.BOTTOM_END,
          pagination: `1 ${t('common.of')} 3`,
        },
      },
      {
        targets: ['comment-auto-reply-switch-button'],
        highlight: 'comment-auto-reply-switch-button',
        highlightType: 'button',
        onHighlightClick: () => {
          setIsTouring(false);
          navigateToProjectPage(`/${COMMENT_AUTO_REPLY}/${LIST}`);
          openModal(
            ModalTypesEnum.COMMENT_AUTO_REPLY_ONBOARDING_CONFIRM_BEHAVIOR,
          );
          setIsVideoEnded(false);
        },
        tooltipProps: {
          description: t('tour.commentAutoReplyOnBoarding.step3.description'),
          placement: PlacementEnum.BOTTOM_END,
          pagination: `2 ${t('common.of')} 3`,
        },
      },
      {
        targets: ['6d16e090-1f4c-4f22-9581-0a274ee03393'],
        tooltipProps: {
          title: t('tour.commentAutoReplyOnBoarding.step5.title'),
          description: t('tour.commentAutoReplyOnBoarding.step5.description'),
          placement: PlacementEnum.BOTTOM,
          pagination: `3 ${t('common.of')} 3`,
          nextButtonText: t('tour.commentAutoReplyOnBoarding.step5.next'),
          nextButtonClick: finishTutorial,
        },
        isTransparentMaskClickable: true,
      },
    ];
  }, [setIsTouring, t, openModal, navigateToProjectPage, finishTutorial]);

  // start tutorial
  const handleTourStart = useCallback(() => {
    closeModal();
    setTour(onboardingTour);

    setTimeout(() => {
      setIsTouring(true);
    }, 1000);
  }, [closeModal, setTour, setIsTouring, onboardingTour]);

  const createCommentAutoReply = useCallback(() => {
    setIsCompleted(false);
    closeDemoMode();
    closeModal();
    onAddReply();
  }, [closeDemoMode, closeModal, onAddReply]);

  const leaveMission = useCallback(() => {
    closeTour();
    closeModal();
    closeDemoMode();
    navigateToProjectPage(`/${COMMENT_AUTO_REPLY}/${LIST}`);
  }, [closeDemoMode, closeTour, closeModal, navigateToProjectPage]);

  const finishInteract = useCallback(
    (type: 'later' | 'start') => {
      updateAccountTutorial({
        marketingGuru: {
          commentAutoReply: {
            hasInteracted: true,
          },
        },
      });
      closeModal();
      if (type === 'start') {
        openModal(ModalTypesEnum.COMMENT_AUTO_REPLY_ONBOARDING_WELCOME);
        openDemoMode(leaveMission);
      }
    },
    [updateAccountTutorial, closeModal, openModal, openDemoMode, leaveMission],
  );

  const handleVideoEnded = useCallback(() => {
    setIsVideoEnded(true);
  }, []);

  const { title, confirmBtnText, handleConfirm } = useMemo(() => {
    if (mode === 'start') {
      return {
        title: t('tour.modal.commentAutoReplyWelcome.start.title'),
        confirmBtnText: t('tour.modal.commentAutoReplyWelcome.start.button'),
        handleConfirm: handleTourStart,
      };
    }
    return {
      title: t(`tour.modal.commentAutoReplyWelcome.finish.title`),
      confirmBtnText: t(`tour.modal.commentAutoReplyWelcome.finish.button`),
      handleConfirm: createCommentAutoReply,
    };
  }, [mode, t, handleTourStart, createCommentAutoReply]);

  const handleClose = useCallback(() => {
    setIsCompleted(false);
    closeDemoMode();
    closeModal();
  }, [closeDemoMode, closeModal]);

  return (
    <>
      <Dialog
        size="md"
        title={title}
        open={
          modalType === ModalTypesEnum.COMMENT_AUTO_REPLY_ONBOARDING_WELCOME
        }
        {...(mode === 'finish' && {
          cancelBtnText: t('tour.modal.commentAutoReplyWelcome.finish.later'),
        })}
        confirmBtnText={confirmBtnText}
        closeBtn={false}
        autoClose={false}
        handleClose={handleClose}
        handleConfirm={handleConfirm}
        disableEscapeKeyDown
      >
        <ContentWrapperStyled>
          {mode === 'start' && (
            <Typography variant="body3" color="grey.500" alignSelf="end">
              {t(`tour.modal.commentAutoReplyWelcome.${mode}.time`)}
            </Typography>
          )}
          <Typography>
            <Typography component="span" variant="body1" color="grey.800">
              {t(`tour.modal.commentAutoReplyWelcome.${mode}.description`)}
            </Typography>
            {mode === 'finish' && (
              <Typography
                component="span"
                variant="body1"
                color="grey.800"
                sx={{ marginTop: '25px' }}
              >
                <Trans
                  i18nKey="tour.modal.commentAutoReplyWelcome.finish.memo"
                  components={[
                    <Link
                      href={HOW_TO_USE_COMMENT_AUTO_REPLY_ZH}
                      underline="always"
                      target="_blank"
                      sx={{
                        color: 'alert.info.main',
                      }}
                    />,
                  ]}
                />
              </Typography>
            )}
          </Typography>
          <ImageRowWrapperStyled>
            <StepWrapperStyled>
              <img
                src={completed ? Step1After : Step1Before}
                alt="step1-before"
              />
              <Typography variant="body1" color="grey.700">
                {t('tour.modal.commentAutoReplyWelcome.learnStep.step1')}
              </Typography>
            </StepWrapperStyled>
            <StepWrapperStyled>
              <img
                src={completed ? Step2After : Step2Before}
                alt="step1-before"
              />
              <Typography variant="body1" color="grey.700">
                {t('tour.modal.commentAutoReplyWelcome.learnStep.step2')}
              </Typography>
            </StepWrapperStyled>
            <StepWrapperStyled>
              <img
                src={completed ? Step3After : Step3Before}
                alt="step1-before"
              />
              <Typography variant="body1" color="grey.700">
                {t('tour.modal.commentAutoReplyWelcome.learnStep.step3')}
              </Typography>
            </StepWrapperStyled>
          </ImageRowWrapperStyled>
        </ContentWrapperStyled>
      </Dialog>

      {/* intro video */}
      <VideoModal
        open={modalType === MissionModalTypeEnum.LEVEL2_LESSON2}
        handleClose={() => finishInteract('later')}
        title={t('tour.modal.learnCommentAutoReply.title')}
        loop
        content={
          <Trans
            i18nKey="tour.modal.learnCommentAutoReply.description"
            components={[
              <br />,
              <span style={{ display: 'block', marginTop: '16px' }} />,
            ]}
          />
        }
        cancelButtonText={t('tour.modal.learnCommentAutoReply.later')}
        confirmButtonText={t('tour.modal.learnCommentAutoReply.confirm')}
        onConfirm={() => finishInteract('start')}
        videoLink={
          i18next.language.includes('en')
            ? `${googleStorageEndpoint}/static/video/onboarding/commentAutoReply-introModal-en.mp4`
            : `${googleStorageEndpoint}/static/video/onboarding/commentAutoReply-introModal-zh.mp4`
        }
      />

      {/* main tutorial video */}
      <VideoModal
        open={
          modalType ===
          ModalTypesEnum.COMMENT_AUTO_REPLY_ONBOARDING_MAIN_TUTORIAL
        }
        confirmButtonText={t('tour.commentAutoReplyOnBoarding.step2.next')}
        onConfirm={() => {
          closeModal();
          navigateToProjectPage(
            `/${COMMENT_AUTO_REPLY}/fd60a40b-a2ef-43ce-ad1a-df09b1c029f5/${CONTENT}`,
          );
          nextTour();
          setTimeout(() => setIsTouring(true), 1100);
        }}
        videoLink={
          i18next.language.includes('en')
            ? `${googleStorageEndpoint}/static/video/onboarding/commentAutoReply-basicSettings-en.mp4`
            : `${googleStorageEndpoint}/static/video/onboarding/commentAutoReply-basicSettings-zh.mp4`
        }
        onEnded={handleVideoEnded}
        confirmButtonDisabled={!isVideoEnded}
      />

      {/* conform behavior video */}
      <VideoModal
        open={
          modalType ===
          ModalTypesEnum.COMMENT_AUTO_REPLY_ONBOARDING_CONFIRM_BEHAVIOR
        }
        title={t('tour.commentAutoReplyOnBoarding.step4.title')}
        content={t('tour.commentAutoReplyOnBoarding.step4.description')}
        confirmButtonText={t('tour.commentAutoReplyOnBoarding.step4.next')}
        onConfirm={() => {
          closeModal();
          nextTour();
          setIsTouring(true);
        }}
        videoLink={
          i18next.language.includes('en')
            ? `${googleStorageEndpoint}/static/video/onboarding/commentAutoReply-confirmBehavior-en.mp4`
            : `${googleStorageEndpoint}/static/video/onboarding/commentAutoReply-confirmBehavior-zh.mp4`
        }
        onEnded={handleVideoEnded}
        confirmButtonDisabled={!isVideoEnded}
      />
    </>
  );
};

export default CommentAutoReplyOnboardingWelcomeModal;
