import { ChartPieDataType } from '@frontend/components/interface';
import { CHART_MAX_STRING_LENGTH } from '@frontend/components/utils';
import { Box } from '@mui/material';
import { styled } from '@mui/system';
import { FC, useCallback } from 'react';
import { Cell, Legend, Pie, PieChart, ResponsiveContainer } from 'recharts';
import Loading from '../loading/loading';

interface PieDef {
  cx: number;
  cy: number;
  percent: number;
  index: number;
  midAngle: number;
  startAngle: number;
  endAngle: number;
  paddingAngle: number;
  innerRadius: number;
  outerRadius: number;
  cornerRadius: number;
}

interface ChartPieProps {
  data: ChartPieDataType[];
  // 如果有值則圖表只會畫出陣列內的欄位
  chartColors: string[];
  isLoading?: boolean;
}

const LoadingWrapperStyled = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const ContainerWrapperStyled = styled(Box)(({ theme }) => ({
  height: '300px',
  width: '400px',
  position: 'relative',
}));

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
}: PieDef) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? 'start' : 'end'}
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

export const ChartPie: FC<ChartPieProps> = ({
  data,
  isLoading,
  chartColors,
}: ChartPieProps) => {
  // 字數過長顯示 ...
  const legendFormatter = useCallback(
    (value: string) =>
      value.length > CHART_MAX_STRING_LENGTH
        ? value.slice(0, CHART_MAX_STRING_LENGTH) + '...'
        : value,
    [],
  );

  return (
    <ContainerWrapperStyled>
      {isLoading ? (
        <LoadingWrapperStyled>
          <Loading />
        </LoadingWrapperStyled>
      ) : (
        <ResponsiveContainer width="100%" height="100%">
          <PieChart width={300} height={400}>
            <Pie
              data={data}
              cx="50%"
              cy="50%"
              labelLine={false}
              label={renderCustomizedLabel}
              outerRadius={110}
              dataKey="value"
            >
              {data.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={chartColors[index % chartColors.length]}
                />
              ))}
            </Pie>
            <Legend iconType="circle" formatter={legendFormatter} />
          </PieChart>
        </ResponsiveContainer>
      )}
    </ContainerWrapperStyled>
  );
};

export default ChartPie;
