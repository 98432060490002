import { PostDataDashboardDemographicsChartResponse } from '@frontend/sorghum/interface';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { DATA_DASHBOARD_DEMOGRAPHICS_CHART } from '../apis';

async function postDataDashboardDemographicsChart(): Promise<
  PostDataDashboardDemographicsChartResponse[]
> {
  return axios
    .post(DATA_DASHBOARD_DEMOGRAPHICS_CHART)
    .then((res) => res.data.data)
    .catch((err) => []);
}

export function usePostDataDashboardDemographicsChart() {
  return useQuery({
    queryKey: ['post-data-dashboard-demographics-chart'],
    queryFn: () => postDataDashboardDemographicsChart(),
  });
}

export default usePostDataDashboardDemographicsChart;
