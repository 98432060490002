import {
  PostDataDashboardSourceCommentReplyRequest,
  PostDataDashboardSourceCommentReplyResponse,
} from '@frontend/sorghum/interface';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { DATA_DASHBOARD_SOURCE_COMMENT_REPLY } from '../apis';

const defaultData = [] as PostDataDashboardSourceCommentReplyResponse[];

async function postDataDashboardSourceCommentReply(
  props: PostDataDashboardSourceCommentReplyRequest,
): Promise<PostDataDashboardSourceCommentReplyResponse[]> {
  return axios
    .post(DATA_DASHBOARD_SOURCE_COMMENT_REPLY, props)
    .then((res) => res.data.data)
    .catch((err) => defaultData);
}

export function usePostDataDashboardSourceCommentReply() {
  return useMutation({
    mutationKey: ['post-data-dashboard-source-comment-reply'],
    mutationFn: (props: PostDataDashboardSourceCommentReplyRequest) =>
      postDataDashboardSourceCommentReply(props),
  });
}

export default usePostDataDashboardSourceCommentReply;
