import { DataDashboardOperationAnalysisChipEnum } from '@frontend/sorghum/interface';
import { FC } from 'react';
import AutoReply from './auto-reply';
import Broadcast from './broadcast';
import Flow from './flow';

interface Props {
  type: DataDashboardOperationAnalysisChipEnum;
  startDate: string;
  endDate: string;
}

export const DataDashboardOperationPerformance: FC<Props> = ({
  type,
  startDate,
  endDate,
}: Props) => {
  switch (type) {
    case DataDashboardOperationAnalysisChipEnum.FLOW:
      return <Flow startDate={startDate} endDate={endDate} />;
    case DataDashboardOperationAnalysisChipEnum.BROADCAST:
      return <Broadcast startDate={startDate} endDate={endDate} />;
    case DataDashboardOperationAnalysisChipEnum.AUTO_REPLY: {
      return <AutoReply startDate={startDate} endDate={endDate} />;
    }
    default:
      return null;
  }
};

export default DataDashboardOperationPerformance;
