import { MaterialStyledProps } from '@frontend/components/interface';
import { Button, Tooltip, Typography } from '@frontend/components/ui';
import { PostDataDashboardCondition } from '@frontend/sorghum/interface';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Box, ButtonGroup } from '@mui/material';
import { styled } from '@mui/system';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface DataDashboardChartContainerProps {
  title: string;
  startDate: string;
  endDate: string;
  tooltip?: string;
  condition: PostDataDashboardCondition[];
  hasTimeIntervalSelector?: boolean;
  ChartContent: (props: {
    startDate: string;
    endDate: string;
    period: number;
    condition: PostDataDashboardCondition[];
  }) => JSX.Element | null;
}
export const ChartContainerStyled = styled(Box)<MaterialStyledProps>(
  ({ theme }) => ({
    display: 'flex',
    flex: 1,
    backgroundColor: theme.palette['grey']['white'],
    flexDirection: 'column',
    padding: '24px',
    boxShadow: theme.shadows[2],
    borderRadius: '8px',
    width: '100%',
    height: '100%',

    '.title': {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      height: '32px',
    },

    '.button-group': {
      marginTop: '4px',
      '.MuiButtonGroup-grouped': {
        '&:not(:last-of-type)': {
          borderRight: `1px solid ${theme.palette['background']['black'][10]}`,
        },
      },
    },
  }),
);

export const ButtonGroupWrapperStyled = styled(Box)`
  min-height: 50px;
`;

export const ChartWrapperStyled = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '300px',
  display: 'flex',
  justifyContent: 'center',
}));

export const PeriodButtonStyled = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette['grey'][50],
  color: theme.palette['grey'][400],

  '&.selected': {
    backgroundColor: theme.palette['grey'][100],
    color: theme.palette['primary']['main'],
  },

  '&:hover': {
    backgroundColor: theme.palette['grey'][100],
  },
}));

export const TitleWrapperStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
}));

export const DataDashboardChartContainer: FC<
  DataDashboardChartContainerProps
> = ({
  title,
  startDate,
  endDate,
  tooltip,
  condition,
  hasTimeIntervalSelector = false,
  ChartContent,
}) => {
  const [t] = useTranslation();
  const [period, setPeriod] = useState<number>(1);

  useEffect(() => {
    setPeriod(1);
  }, [startDate, endDate, condition, title]);

  return (
    <ChartContainerStyled>
      <Box className="title">
        <TitleWrapperStyled>
          <Typography variant="subtitle2" color="grey.800">
            {title}
          </Typography>
          {tooltip && (
            <Tooltip placement="top" title={tooltip}>
              <InfoOutlinedIcon
                fontSize="small"
                sx={{
                  color: 'grey.600',
                  marginLeft: '12px',
                }}
              />
            </Tooltip>
          )}
        </TitleWrapperStyled>
        <ButtonGroupWrapperStyled>
          {hasTimeIntervalSelector && (
            <ButtonGroup
              variant="contained"
              className="button-group"
              size="small"
              disableElevation
            >
              <PeriodButtonStyled
                {...(period === 1 && { className: 'selected' })}
                onClick={() => setPeriod(1)}
              >
                {t('dataDashboard.timeInterval.day')}
              </PeriodButtonStyled>
              <PeriodButtonStyled
                {...(period === 2 && { className: 'selected' })}
                onClick={() => setPeriod(2)}
              >
                {t('dataDashboard.timeInterval.week')}
              </PeriodButtonStyled>
              <PeriodButtonStyled
                {...(period === 3 && { className: 'selected' })}
                onClick={() => setPeriod(3)}
              >
                {t('dataDashboard.timeInterval.month')}
              </PeriodButtonStyled>
            </ButtonGroup>
          )}
        </ButtonGroupWrapperStyled>
      </Box>
      <ChartWrapperStyled>
        <ChartContent
          startDate={startDate}
          endDate={endDate}
          condition={condition}
          period={period}
        />
      </ChartWrapperStyled>
    </ChartContainerStyled>
  );
};

export default DataDashboardChartContainer;
