/* account */
export const FB_SIGN_IN = '/api/v1/account/fb/signin';
export const EMAIL_SIGN_UP = '/api/v1/account/signup';
export const EMAIL_SIGN_IN = '/api/v1/account/signin';
export const SIGN_UP_VERIFY = '/api/v1/account/signup/verify';
export const ACCOUNT_RESEND = '/api/v1/account/resend';
export const ACCOUNT_RESET_MAIL = '/api/v1/account/resetMail';
export const ACCOUNT_RESET_VERIFY = '/api/v1/account/reset/verify';
export const ACCOUNT_RESET = '/api/v1/account/reset';
export const ACCOUNT_QUESTION = '/api/v1/account/question';
export const ACCOUNT_PAGE_LIST = '/api/v1/account/page/list';
export const ACCOUNT_INFO = '/api/v1/account/info';
export const ACCOUNT_PROFILE = '/api/v1/account/profile';
export const ACCOUNT_STEP = '/api/v1/account/step';
export const ACCOUNT_SURVEY = '/api/v1/account/survey';
export const ACCOUNT_INVITATION_CHECK = '/api/v1/account/invitation/check';
export const ACCOUNT_LOGOUT = '/api/v1/account/logout';

/* project */
export const PROJECT = '/api/v1/project';
export const PROJECT_CONNECT = '/api/v1/project/connect';
export const PROJECT_DISCONNECT = '/api/v1/project/disconnect';
export const PROJECT_LIST = '/api/v1/project/list';
export const PROJECT_LINKED = '/api/v1/project/linked';
export const PROJECT_PERMISSION = '/api/v1/project/permission';
export const PROJECT_INVITE = '/api/v1/project/invite';
export const PROJECT_NOTIBAR_INFO = '/api/v1/project/notibar/info';
export const PROJECT_RN_COUNT = '/api/v1/project/rnCount';
export const PROJECT_OUTCOME = '/api/v1/project/outcome';
export const PEOPLE_TAGS_BY_FREQUENCY = '/api/v1/project/tag';
export const PROJECT_SURVEY = '/api/v1/project/survey';
export const ATTRIBUTE = '/api/v1/project/attribute/key';

/* flow */
export const FLOW = '/api/v1/editor/flow';
export const FLOW_CHART = '/api/v1/editor/flow/chart';
export const FLOW_PUBLISH = '/api/v1/editor/flow/publish';
export const FLOW_ENTRY_STATUS = '/api/v1/editor/entry/status';
export const FLOW_ENTRY_INDEX = '/api/v1/editor/flow/entry/index';
export const FLOW_ENTRY = '/api/v1/editor/flow/entry';
export const FLOW_COPY = 'api/v1/editor/flow/copy';
export const FLOW_PIN = 'api/v1/editor/flow/pin';
export const FLOW_GROUP = '/api/v1/editor/flowGroup';
export const FLOW_GROUP_FLOW = '/api/v1/editor/flowGroup/flow';
export const FLOW_GROUP_INDEX = '/api/v1/editor/flowGroup/index';
export const FLOW_GROUP_COPY = '/api/v1/editor/flowGroup/copy';
export const EDITOR_MENU = '/api/v1/editor/menu';
export const EDITOR_OPTION_FLOW = 'api/v1/editor/option/flow';
export const EDITOR_BASIC_FLOW_STATUS = 'api/v1/editor/basicFlow/status';
export const EDITOR_DEFAULT_ANSWER = 'api/v1/editor/defaultAnswer';
export const EDITOR_FLOW_STATUS = 'api/v1/editor/flow/status';
export const EDITOR_WELCOME = 'api/v1/editor/welcome';
export const EDITOR_CONVERSATION = 'api/v1/editor/conversation';
export const EDITOR_OPTION_PARAM = 'api/v1/editor/option/param';
export const EDITOR_UNIQUE_NAME = 'api/v1/editor/uniqueName';

/* contacts */
export const PEOPLE_LIST = '/api/v1/contacts';
export const CONTACTS_EXPORT = '/api/v1/contacts/export';
export const CONTACTS_LOCALES = '/api/v1/contacts/locales';
export const PEOPLE_UPDATE_TAG = '/api/v1/contacts/tag';

/* broadcast */
export const BROADCAST = '/api/v1/broadcast';
export const BROADCAST_LIST = '/api/v1/broadcast/list';
export const BROADCAST_CONTACTS = '/api/v1/broadcast/contacts';
export const BROADCAST_SEND = '/api/v1/broadcast/send';
export const BROADCAST_DATA = '/api/v1/data/broadcast';
export const BROADCAST_OPTION = '/api/v1/broadcast/option';

export const DASHBOARD_FLOW_SUMMARY = '/api/v1/data/flow/summary';
export const DASHBOARD_FLOW_LIST = '/api/v1/data/flow/list';

export const DASHBOARD_DATA_TOTAL = '/api/v1/data/dashboard/total';
export const DASHBOARD_DATA_OVERVIEW = '/api/v1/data/dashboard/overview';
export const DASHBOARD_DATA_OVERVIEW_CHART =
  '/api/v1/data/dashboard/overview/chart';

//FIXME: 統一改成 DATA_DASHBOARD?
export const DASHBOARD_DATA_ENGAGEMENT = '/api/v1/data/dashboard/engagement';
export const DASHBOARD_DATA_ENGAGEMENT_CHART =
  '/api/v1/data/dashboard/engagement/chart';
export const DASHBOARD_DATA_SOURCE_ENTRY = '/api/v1/data/dashboard/sourceEntry';
export const DASHBOARD_DATA_SOURCE_ENTRY_CHART =
  '/api/v1/data/dashboard/sourceEntry/chart';
export const DASHBOARD_DATA_SOURCE_CHANNEL =
  '/api/v1/data/dashboard/sourceChannel';
export const DASHBOARD_DATA_SOURCE_CHANNEL_CHART =
  '/api/v1/data/dashboard/sourceChannel/chart';
export const DASHBOARD_DATA_EXPORT = '/api/v1/data/dashboard/export';
export const DASHBOARD_DATA_OPERATION_BROADCAST =
  '/api/v1/data/dashboard/operation/broadcast/list';
export const DASHBOARD_DATA_OPERATION_COMMENT_REPLY =
  '/api/v1/data/operation/commentReply';
export const DASHBOARD_DATA_OPERATION_COMMENT_REPLY_LIST =
  '/api/v1/data/operation/commentReply/list';
export const DASHBOARD_DATA_OPERATION_COMMENT_REPLY_DETAIL =
  '/api/v1/data/operation/commentReply/detail';

export const DATA_DASHBOARD_OPERATION_BROADCAST =
  '/api/v1/data/dashboard/operation/broadcast';
export const DATA_DASHBOARD_DEMOGRAPHICS_CHART =
  '/api/v1/data/dashboard/demographics/chart';
export const DATA_DASHBOARD_SOURCE_COMMENT_REPLY =
  '/api/v1/data/dashboard/sourceCommentReply';
export const DATA_DASHBOARD_SOURCE_COMMENT_REPLY_CHART =
  '/api/v1/data/dashboard/sourceCommentReply/chart';
export const DATA_DASHBOARD_OPERATION_FLOW =
  '/api/v1/data/dashboard/operation/flow';
export const DATA_DASHBOARD_OPERATION_FLOW_CHART =
  '/api/v1/data/dashboard/operation/flow/chart';
export const DATA_DASHBOARD_OPERATION_FLOW_LIST =
  '/api/v1/data/dashboard/operation/flow/list';

export const REDIRECT = '/api/v1/webhook/redirect';

/* sequence */
export const SEQUENCE = '/api/v1/sequence';
export const SEQUENCE_LIST = '/api/v1/sequence/list';
export const SEQUENCE_DATA = '/api/v1/data/sequence';
export const SEQUENCE_MESSAGE = '/api/v1/sequence/message';

/* coupon */
export const COUPON = '/api/v1/coupon';
export const COUPON_LIST = '/api/v1/coupon/list';
export const COUPON_INCENTIVE_LIST = '/api/v1/coupon/incentive/list';
export const COUPON_REWARD_COUNT = '/api/v1/coupon/rewardCount';
export const COUPON_INCENTIVE_CODE_UPLOAD =
  '/api/v1/coupon/incentive/code/upload';
export const COUPON_INCENTIVE = '/api/v1/coupon/incentive';
export const COUPON_ACTIVATE = '/api/v1/coupon/activate';

/* comment reply */
export const COMMENT_REPLY = '/api/v1/commentReply';
export const COMMENT_REPLY_LIST = '/api/v1/commentReply/list';
export const COMMENT_REPLY_LIST_DETAIL = '/api/v1/commentReply/list/detail';
export const COMMENT_REPLY_POSTS = '/api/v1/commentReply/posts';
export const COMMENT_REPLY_CONDITION = '/api/v1/commentReply/condition';
export const COMMENT_DATA_REPLY_CONDITION =
  '/api/v1/data/commentReply/condition';
export const COMMENT_REPLY_PERIOD = '/api/v1/commentReply/period';
export const COMMENT_REPLY_ACTIVE = '/api/v1/commentReply/active';
export const COMMENT_REPLY_DATA = '/api/v1/data/commentReply';
export const COMMENT_REPLY_DATA_DETAIL = '/api/v1/data/commentReply/detail';

export const CONTACTS = '/api/v1/contacts';
export const CONTACTS_ATTRIBUTE = `${CONTACTS}/attribute`;
export const CONTACTS_TAG = `${CONTACTS}/tag`;

/* billing */
export const BILLING = '/api/v1/billing';
export const BILLING_PAYMENT = '/api/v1/billing/payment';
export const BILLING_ORDER_RESULT = '/api/v1/billing/order/result';
export const BILLING_ORDER = '/api/v1/billing/order';
export const BILLING_INVOICE_EXPORT = '/api/v1/billing/invoice/export';
export const BILLING_PLAN = '/api/v1/billing/plan';
export const BILLING_HISTORY = '/api/v1/billing/history';
export const BILLING_COUPON_CHECK = '/api/v1/billing/coupon/check';

export const ACCOUNT_TUTORIAL = '/api/v1/account/tutorial';
