import { EditorCtx } from '@frontend/editor/external-providers';
import {
  ActionAttributeType,
  ActionCellBaseType,
  ActionCellTypesEnum,
  ActionSequenceType,
  ActionSheetType,
  BaseElement,
  CollectUserAnswerType,
  ConditionCellType,
  CouponType,
  CouponTypesEnum,
  ElementTypesEnum,
  GalleryImageType,
  GalleryType,
  ImageType,
  JumpToFlowType,
  NodeTypesEnum,
  OptionCellType,
  RecurringNotificationType,
  TextButtonCellType,
} from '@frontend/editor/interface';
import { get } from 'lodash';
import { useCallback, useContext } from 'react';
import { applyEdgeChanges, EdgeRemoveChange } from 'reactflow';
import useCanvasCollect from '../use-canvas-collect/use-canvas-collect';
import useModal from '../use-modal/use-modal';

export interface UseCanvasRemove {
  removeTargetBlock: () => void;
  removeSelectedBlocks: () => void;
  removeBlockByID: (id: string) => void;
  removeElement: (id?: string) => void;
  removeCell: (id: string) => void;
  removeBlockWithValidation: (id: string) => void;
  removeSelectedBlocksWithValidation: () => void;
  removeCreateMenu: () => void;
  removeConnect: (outputID: string) => void;
}

export function useCanvasRemove(): UseCanvasRemove {
  const {
    targetElementID,
    removeElement: removeStateElement,
    setEdges,
    setNodes,
    getElement,
    getElementByOutputID,
    removeElements,
    updateElementData,
  } = useContext(EditorCtx);
  const { selectedNodes } = useCanvasCollect();
  const { openDeleteBlockModal, openDeleteBlocksModal } = useModal();

  const removeCreateMenu = useCallback(() => {
    setNodes((prev) =>
      prev.filter((n) => n.type !== NodeTypesEnum.CREATE_MENU_NODE),
    );
  }, [setNodes]);

  const removeTargetBlock = useCallback(() => {
    if (targetElementID) {
      removeStateElement(targetElementID);
    }
  }, [removeStateElement, targetElementID]);

  const removeSelectedBlocks = useCallback(() => {
    if (selectedNodes) {
      // 排除不可刪除的元素
      const nodesAllowedToDelete = selectedNodes
        .filter((node) => {
          return node.type !== NodeTypesEnum.ENTRY_POINT_NODE;
        })
        .map((node) => node.id);
      removeElements(nodesAllowedToDelete);
    }
  }, [selectedNodes, removeElements]);

  const removeBlockByID = useCallback(
    (id: string) => {
      removeStateElement(id);
    },
    [removeStateElement],
  );

  const removeElement = useCallback(
    (id?: string) => {
      // 如果沒有指定 id 則從 state 儲存的 target 為目標
      if (id) {
        removeStateElement(id);
      } else if (targetElementID) {
        removeStateElement(targetElementID);
      }
    },
    [removeStateElement, targetElementID],
  );

  const removeCell = useCallback(
    (id: string) => {
      removeElement(id);
    },
    [removeElement],
  );

  // helper function
  const checkElementData = useCallback(
    (id: string): boolean | undefined => {
      let haveContent = false;

      const elementData = getElement(id);

      if (
        elementData &&
        (elementData.elementType === 'COUPON' ||
          elementData.elementType === 'JUMP_TO_FLOW')
      ) {
        // Coupon
        if ('type' in elementData) {
          const couponData = elementData as CouponType;

          switch (couponData.type) {
            case CouponTypesEnum.SEND:
            case CouponTypesEnum.SHOW:
            case CouponTypesEnum.REDEEM: {
              if (couponData?.couponValue) {
                haveContent = true;
              }
              break;
            }
          }
        }

        // Jump to flow
        if (elementData.elementType === 'JUMP_TO_FLOW') {
          const jumpToFlowData = elementData as JumpToFlowType;

          if (jumpToFlowData.data?.flowID) {
            haveContent = true;
          }
        }

        return haveContent;
      }

      return undefined;
    },
    [getElement],
  );

  // helper function
  const checkChildren = useCallback(
    (id: string): boolean | undefined => {
      let haveContent = false;

      const elementData = getElement(id);
      const children = get(elementData, 'children', []) as string[];

      if (
        elementData &&
        (elementData.elementType === 'BLOCK' ||
          elementData.elementType === 'ACTION' ||
          elementData.elementType === 'CONDITION')
      ) {
        if (children.length > 0) {
          const editableButtonWithContent: BaseElement[] = [];
          const galleryWithContent: GalleryType[] = [];
          const collectUserAnswerWithContent: CollectUserAnswerType[] = [];

          // Block
          if (elementData.elementType === 'BLOCK') {
            children.forEach((childID) => {
              const itemData = getElement(childID);
              if (!itemData) return;

              // Block - Text + Button
              if (itemData.elementType === ElementTypesEnum.TEXT_BUTTON) {
                if (itemData.label && itemData.label.trim().length) {
                  haveContent = true;
                }
                if (itemData.children.length > 0) {
                  editableButtonWithContent.push(itemData);
                }
              }

              // Block - Image
              if (itemData.elementType === ElementTypesEnum.IMAGE) {
                const imageData = itemData as ImageType;
                if (imageData?.fileID && imageData?.fileUrl) {
                  haveContent = true;
                }
                if (imageData.children.length > 0) {
                  editableButtonWithContent.push(imageData);
                }
              }

              // Block - Gallery
              if (itemData.elementType === ElementTypesEnum.GALLERY) {
                const galleryData = itemData as GalleryType;
                if (galleryData.children.length > 0) {
                  galleryWithContent.push(galleryData);
                }
              }

              // Block - Recurring Notification
              if (
                itemData.elementType === ElementTypesEnum.RECURRING_NOTIFICATION
              ) {
                const RNData = itemData as RecurringNotificationType;
                if (RNData?.fileID && RNData?.fileUrl) {
                  haveContent = true;
                }
                if (RNData.title && RNData.title.trim().length) {
                  haveContent = true;
                }
                if (RNData.targetID) {
                  haveContent = true;
                }
              }

              // Block - Collect User Answer
              if (
                itemData.elementType === ElementTypesEnum.COLLECT_USER_ANSWER
              ) {
                const collectUserAnswerData = itemData as CollectUserAnswerType;
                if (
                  collectUserAnswerData.data.text &&
                  collectUserAnswerData.data.text.trim().length
                ) {
                  haveContent = true;
                }
                if (collectUserAnswerData.children.length > 0) {
                  collectUserAnswerWithContent.push(collectUserAnswerData);
                }
                if (collectUserAnswerData.data.isAllowFreeInput) {
                  haveContent = true;
                }
              }
            });
          } else {
            children.forEach((childID) => {
              const itemData = getElement(childID) as ActionCellBaseType;

              // Action
              if ('actionType' in itemData) {
                switch (itemData.actionType) {
                  case ActionCellTypesEnum.SET:
                  case ActionCellTypesEnum.REMOVE: {
                    const attributeData = itemData as ActionAttributeType;
                    if (attributeData?.attributeID) {
                      haveContent = true;
                    }
                    break;
                  }
                  case ActionCellTypesEnum.SUBSCRIBE_SEQUENCE:
                  case ActionCellTypesEnum.UNSUBSCRIBE_SEQUENCE: {
                    const sequenceData = itemData as ActionSequenceType;
                    if (sequenceData?.sequence) {
                      haveContent = true;
                    }
                    break;
                  }
                  case ActionCellTypesEnum.EXPORT_GOOGLE_SHEET: {
                    const sheetData = itemData as ActionSheetType;
                    if (sheetData.data.properties.length > 0) {
                      haveContent = true;
                    }
                    break;
                  }
                }
              }

              // Condition
              if (itemData.elementType === 'CONDITION_CELL') {
                const conditionData = itemData as ConditionCellType;
                if (conditionData?.categoryType) {
                  haveContent = true;
                }
              }
            });
          }

          // Gallery
          if (galleryWithContent.length > 0) {
            galleryWithContent.forEach((item) => {
              const childrenList = item.children;
              if (childrenList.length > 0) {
                childrenList.forEach((childItemID) => {
                  const galleryImageData = getElement(
                    childItemID,
                  ) as GalleryImageType;
                  if (
                    galleryImageData.title &&
                    galleryImageData.title.trim().length
                  ) {
                    haveContent = true;
                  }
                  if (
                    galleryImageData.subtitle &&
                    galleryImageData.subtitle.trim().length
                  ) {
                    haveContent = true;
                  }
                  if (galleryImageData?.fileID && galleryImageData?.url) {
                    haveContent = true;
                  }
                  if (galleryImageData.children.length > 0) {
                    editableButtonWithContent.push(galleryImageData);
                  }
                });
              }
            });
          }

          // Collect User Answer
          if (collectUserAnswerWithContent.length > 0) {
            collectUserAnswerWithContent.forEach((item) => {
              const childrenList = item.children;
              if (childrenList.length > 0) {
                childrenList.forEach((childItemID) => {
                  const optionCellData = getElement(
                    childItemID,
                  ) as OptionCellType;
                  if (
                    optionCellData.data.title &&
                    optionCellData.data.title.trim().length
                  ) {
                    haveContent = true;
                  }
                });
              }
            });
          }

          // Editable Button
          if (editableButtonWithContent.length > 0) {
            editableButtonWithContent.forEach((item) => {
              const childrenList = item.children;
              if (childrenList.length > 0) {
                childrenList.forEach((childItemID) => {
                  const textButtonCellData = getElement(
                    childItemID,
                  ) as TextButtonCellType;
                  if (
                    textButtonCellData.label &&
                    textButtonCellData.label.trim().length
                  ) {
                    haveContent = true;
                  }
                  if (textButtonCellData?.targetID) {
                    haveContent = true;
                  }
                  if (
                    textButtonCellData.data?.flowID &&
                    textButtonCellData.data.flowID
                  ) {
                    haveContent = true;
                  }
                  if (
                    textButtonCellData.data?.url &&
                    textButtonCellData.data.url.trim().length
                  ) {
                    haveContent = true;
                  }
                  if (
                    textButtonCellData.data?.tel &&
                    textButtonCellData.data.tel
                  ) {
                    haveContent = true;
                  }
                });
              }
            });
          }

          return haveContent;
        } else {
          return false;
        }
      }

      return undefined;
    },
    [getElement],
  );

  // helper function
  const checkShowModalOrDelete = useCallback(
    (id: string): boolean => {
      const haveContentInElementData = checkElementData(id);
      const haveContentInChildren = checkChildren(id);

      return !!haveContentInElementData || !!haveContentInChildren;
    },
    [checkElementData, checkChildren],
  );

  // 一般模式下，檢查 block 是否有輸入資料
  const removeBlockWithValidation = useCallback(
    (id: string) => {
      const isShowModal = checkShowModalOrDelete(id);

      if (isShowModal) {
        openDeleteBlockModal(id);
      } else {
        removeBlockByID(id);
      }
    },
    [checkShowModalOrDelete, openDeleteBlockModal, removeBlockByID],
  );

  // 框選模式下，檢查 block(s) 是否有輸入資料
  const removeSelectedBlocksWithValidation = useCallback(() => {
    const haveContent = selectedNodes.some((node) =>
      checkShowModalOrDelete(node.id),
    );

    if (haveContent) {
      openDeleteBlocksModal();
    } else {
      removeSelectedBlocks();
    }
  }, [
    selectedNodes,
    checkShowModalOrDelete,
    openDeleteBlocksModal,
    removeSelectedBlocks,
  ]);

  const removeConnect = useCallback(
    (outputID: string) => {
      const elementData = getElementByOutputID(outputID);
      if (!elementData) return;

      updateElementData(elementData.id, 'targetID', '');
      setEdges((prev) =>
        applyEdgeChanges(
          [
            {
              id: outputID,
              type: 'remove',
            } as EdgeRemoveChange,
          ],
          prev,
        ),
      );
    },
    [getElementByOutputID, setEdges, updateElementData],
  );

  return {
    removeCreateMenu,
    removeTargetBlock,
    removeSelectedBlocks,
    removeBlockByID,
    removeElement,
    removeCell,
    removeBlockWithValidation,
    removeSelectedBlocksWithValidation,
    removeConnect,
  };
}

export default useCanvasRemove;
