import {
  PostDataOperationCommentReplyListProps,
  PostDataOperationCommentReplyListResponse,
} from '@frontend/sorghum/interface';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { DASHBOARD_DATA_OPERATION_COMMENT_REPLY_LIST } from '../apis';

async function postDataOperationCommentReplyList(
  props: PostDataOperationCommentReplyListProps,
): Promise<PostDataOperationCommentReplyListResponse> {
  return axios
    .post(DASHBOARD_DATA_OPERATION_COMMENT_REPLY_LIST, props)
    .then((res) => res.data)
    .catch((err) => ({
      code: 400,
      msg: err,
    }));
}

export function usePostDataOperationCommentReplyList(
  props: PostDataOperationCommentReplyListProps,
) {
  return useQuery({
    queryKey: ['post-data-operation-commentReply-list', props],
    queryFn: () => postDataOperationCommentReplyList(props),
  });
}

export default usePostDataOperationCommentReplyList;
