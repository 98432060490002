import { globalTheme } from '@frontend/components/external-providers';
import { Textarea } from '@frontend/components/ui';
import {
  useCanvasAdd,
  useCanvasCollect,
  useCanvasRemove,
  useCanvasUpdate,
  useGetProjectID,
  useModal,
} from '@frontend/editor/data-access';
import { EditorCtx, UICtx } from '@frontend/editor/external-providers';
import { BaseElement, PublishErrorsEnum } from '@frontend/editor/interface';
import { useGetEditorLabelItems } from '@frontend/sorghum/data-access';
import { GetEditorLabelItemLabelType } from '@frontend/sorghum/interface';
import { Box } from '@mui/material';
import { styled } from '@mui/system';
import { get, isEmpty } from 'lodash';
import {
  Dispatch,
  SetStateAction,
  memo,
  useCallback,
  useContext,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useViewport } from 'reactflow';
import EditorFloatPanel from '../editor-float-panel/editor-float-panel';
import SortableTextButtonGroup from '../editor-sortable-text-button-group/editor-sortable-text-button-group';

interface TextButtonProps {
  id: string;
  label?: string;
  draggable: boolean;
  parentID: string;
  setIsHoverMenu: Dispatch<SetStateAction<boolean>>;
  index?: number;
}

const ContainerStyled = styled(Box)<{ $isHover: boolean; $zoom: number }>(
  ({ theme, $isHover, $zoom }) => ({
    background: theme.palette['grey'][100],
    borderRadius: '8px',
    border: `2px solid ${
      $isHover ? theme.palette['info']['light'] : 'transparent'
    }`,
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(1),
    position: 'relative',
    fontSize: `${28 / $zoom}px`,
  }),
);

const ButtonWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  '& > div': {
    marginBottom: theme.spacing(1),
  },
}));

const FloatPanelWrapperStyled = styled(Box)(({ theme }) => ({
  position: 'absolute',
  left: '-1.25em',
  top: 0,
  paddingRight: '0.42em',
}));

export const EditorTextButton = ({
  id, // text button 的 ID
  index,
  parentID, // Block 的 ID
  label,
  draggable,
  setIsHoverMenu, // 檢查是否 hover 在側邊的選單來決定是否開放 element 的 sort event
}: TextButtonProps) => {
  const state = useContext(EditorCtx);
  const UIstate = useContext(UICtx);
  const { zoom } = useViewport();
  const [t] = useTranslation();
  const { data: projectID } = useGetProjectID();
  const [isTextButtonHover, setIsTextButtonHover] = useState<boolean>(false);
  const [isFocusTextarea, setIsFocusTextarea] = useState<boolean>(false);
  const { openDeleteTextButtonModal } = useModal();
  const { addCellButton } = useCanvasAdd();
  const { removeElement } = useCanvasRemove();
  const { canvasSortElement, canvasUpdateData } = useCanvasUpdate();
  const { getErrorStatus, getErrorStatusAfterPublished } = useCanvasCollect();
  const { data: labelItems } = useGetEditorLabelItems(projectID as string);

  const onRemoveButtonClick = useCallback(() => {
    const textButtonData =
      state.elements.find((item) => item.id === id) || ({} as BaseElement);
    // 如果有設定任何資料則跳出提示
    const needConfirm =
      !isEmpty(textButtonData) ||
      !isEmpty(textButtonData.data.selectedBlockID) ||
      !isEmpty(textButtonData.data.tel) ||
      !isEmpty(textButtonData.data.url) ||
      !isEmpty(label);

    if (needConfirm) {
      openDeleteTextButtonModal(id);
    } else {
      removeElement(id);
    }
  }, [removeElement, id, label, openDeleteTextButtonModal, state.elements]);

  const handleChange = useCallback(
    (text: string) => {
      state.setIsSaving(true);
      canvasUpdateData(id, 'label', text);
    },
    [canvasUpdateData, id, state],
  );

  const handleMouseEnterTextButton = useCallback(() => {
    UIstate.onHoverElement(id, 2);
    setIsTextButtonHover(true);
  }, [id, UIstate]);

  const handleMouseLeaveTextButton = useCallback(() => {
    setIsTextButtonHover(false);
  }, []);

  const handleMouseEnterMenu = useCallback(() => {
    setIsHoverMenu(true);
  }, [setIsHoverMenu]);

  const handleMouseLeaveMenu = useCallback(() => {
    setIsHoverMenu(false);
  }, [setIsHoverMenu]);

  const onCellSortEnd = (oldIndex: number, newIndex: number) => {
    canvasSortElement(id, oldIndex, newIndex);
  };

  const labelList = get(labelItems, 'data', []);

  return (
    <ContainerStyled
      onMouseEnter={handleMouseEnterTextButton}
      onMouseLeave={handleMouseLeaveTextButton}
      $isHover={isTextButtonHover}
      $zoom={zoom}
    >
      {/* Element 左側的刪除和 sorting 按鈕、新增 text+button 按鈕 */}
      {isTextButtonHover &&
        UIstate.hoveredElement.layer < 3 &&
        !isFocusTextarea && (
          <EditorFloatPanel
            onMouseEnter={handleMouseEnterMenu}
            onMouseLeave={handleMouseLeaveMenu}
            type="element"
            index={index}
            parentID={parentID}
            draggable={draggable}
            direction="column"
            allowAdd
            handleDelete={onRemoveButtonClick}
            backgroundColor={globalTheme?.palette?.['grey']?.[100]}
          />
        )}

      <ButtonWrapper>
        <Box>
          {/* TODO: 驗證兩次（onBlur & published）可 refactor 更好的寫法 */}
          <Textarea
            defaultValue={label}
            focus={state.onFocusCellID === id}
            placeholder={t('canvas.textButton.placeholder')}
            labelItem={labelList.filter(
              (item: GetEditorLabelItemLabelType) =>
                item.type === 1 || item.type === 2 || item.type === 3,
            )}
            limit={640}
            onFocus={() => setIsFocusTextarea(true)}
            onBlur={() => setIsFocusTextarea(false)}
            publishError={
              !!getErrorStatusAfterPublished(
                id,
                PublishErrorsEnum.TEXTAREA_LABEL_EMPTY,
              )
            }
            error={!!getErrorStatus(id, PublishErrorsEnum.TEXTAREA_LABEL_EMPTY)}
            onChange={handleChange}
          />
        </Box>
        <SortableTextButtonGroup
          parentID={id}
          onSortEnd={onCellSortEnd}
          onAddClick={() => addCellButton(id)}
        />
      </ButtonWrapper>
    </ContainerStyled>
  );
};

export default memo(EditorTextButton);
