import { globalTheme } from '@frontend/components/external-providers';
import { ChartPieDataType } from '@frontend/components/interface';
import { ChartPie } from '@frontend/components/ui';
import { usePostDataDashboardDemographicsChart } from '@frontend/sorghum/data-access';

import { get } from 'lodash';
import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const enum GenderEnum {
  MALE = 1,
  FEMALE = 2,
  OTHER = 3,
}

export const DataDashboardEngagementPieChart: FC = () => {
  const [t] = useTranslation();
  const [chartData, setChartData] = useState<ChartPieDataType[]>([
    {
      name: t('dataDashboard.userAnalysis.pie.unknown'),
      value: 100,
    },
  ]);
  const blue600 = get(globalTheme, 'palette.blue.600', '');
  const pink600 = get(globalTheme, 'palette.pink.600', '');
  const purple600 = get(globalTheme, 'palette.purple.600', '');

  const { data, isLoading } = usePostDataDashboardDemographicsChart();

  const convertToName = useCallback(
    (gender: number): string => {
      let name = '';
      switch (gender) {
        case GenderEnum.MALE:
          name = t('dataDashboard.userAnalysis.pie.male');
          break;
        case GenderEnum.FEMALE:
          name = t('dataDashboard.userAnalysis.pie.female');
          break;
        case GenderEnum.OTHER:
          name = t('dataDashboard.userAnalysis.pie.unknown');
          break;
        default:
          name = t('dataDashboard.userAnalysis.pie.unknown');
      }
      return name;
    },
    [t],
  );

  useEffect(() => {
    if (!data?.length) return;
    const newChartData: ChartPieDataType[] = [];
    data.forEach((i) => {
      newChartData.push({
        name: convertToName(i.gender),
        value: i.value,
      } as ChartPieDataType);
    });

    setChartData(newChartData);
  }, [convertToName, data]);

  return (
    <ChartPie
      data={chartData}
      isLoading={isLoading}
      chartColors={[blue600, pink600, purple600]}
    />
  );
};

export default DataDashboardEngagementPieChart;
