import { Paper, TableContainer, TablePagination } from '@mui/material';
import { Box, styled } from '@mui/system';
import { FC, PropsWithChildren } from 'react';
import LabelDisplayedRows from '../label-displayed-rows/label-displayed-rows';

interface TableContainerProps {
  showPagination?: boolean;
  searchText?: string;
  pagination?: {
    offset?: number;
    totalCount?: number;
    rowsPerPage: number;
    setPage: (page: number) => void;
  };
}

const PaperContainerStyled = styled(Paper)(({ theme }) => ({
  display: 'flex',
  position: 'relative',
  flexDirection: 'column',
  justifyContent: 'space-between',
  width: '100%',
  minHeight: '400px',
  borderRadius: '8px',

  th: {
    height: '48px',
    backgroundColor: theme.palette['grey'][50],
    border: 0,
    boxShadow: 'none',
  },
  tr: {
    '&: hover': {
      backgroundColor: theme.palette['grey'][200],
    },
    td: {
      cursor: 'pointer',
    },
  },
}));

const PaginationStyled = styled(TablePagination)<{ component: 'div' }>(
  ({ theme }) => ({
    overflow: 'hidden',
    borderTop: `1px solid ${theme.palette['grey'][200]}`,
    height: '48px',
    '.MuiTablePagination-selectLabel': {
      display: 'none',
    },
    '.MuiInputBase-root': {
      display: 'none',
    },
    '.MuiTablePagination-displayedRows': {
      color: theme.palette['grey'][700],
    },
    '& > div': {
      minHeight: '48px',
      height: '48px',
    },
  }),
);

export const EmptyContentStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: '100%',
  position: 'absolute',
}));

export const TableContainerWithPagination: FC<
  PropsWithChildren<TableContainerProps>
> = ({ searchText, showPagination, pagination, children }) => {
  const { offset, totalCount, rowsPerPage, setPage } = pagination || {
    rowsPerPage: 0,
  };

  return (
    <PaperContainerStyled elevation={0}>
      {searchText && <EmptyContentStyled>{searchText}</EmptyContentStyled>}
      <TableContainer>{children}</TableContainer>
      {pagination && showPagination && (
        <PaginationStyled
          page={offset || 0}
          rowsPerPageOptions={[rowsPerPage]}
          component="div"
          count={totalCount || 0}
          rowsPerPage={rowsPerPage}
          onPageChange={(_, page) => setPage && setPage(page)}
          labelDisplayedRows={LabelDisplayedRows}
        />
      )}
    </PaperContainerStyled>
  );
};

export default TableContainer;
