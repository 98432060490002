import {
  PostDataDashboardOperationBroadcastRequest,
  PostDataDashboardOperationBroadcastResponse,
} from '@frontend/sorghum/interface';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { DATA_DASHBOARD_OPERATION_BROADCAST } from '../apis';

async function postDataDashboardOperationBroadcast(
  props: PostDataDashboardOperationBroadcastRequest,
): Promise<PostDataDashboardOperationBroadcastResponse> {
  return axios
    .post(DATA_DASHBOARD_OPERATION_BROADCAST, props)
    .then((res) => res.data.data)
    .catch((err) => ({
      code: 400,
      msg: err,
    }));
}

export function usePostDataDashboardOperationBroadcast(
  props: PostDataDashboardOperationBroadcastRequest,
) {
  return useQuery({
    queryKey: ['post-data-dashboard-operation-broadcast', props],
    queryFn: () => postDataDashboardOperationBroadcast(props),
  });
}

export default usePostDataDashboardOperationBroadcast;
