import { Loading } from '@frontend/components/ui';
import { EditorCtx, UICtx } from '@frontend/editor/external-providers';
import { Box } from '@mui/material';
import { styled } from '@mui/system';
import { get } from 'lodash';
import { memo, useContext } from 'react';

const ContainerStyled = styled(Box)(({ theme }) => ({
  position: 'fixed',
  width: '100%',
  height: '100%',
  backgroundColor: get(theme, 'palette.grey.100'),
  // 在 top-bar 之下
  zIndex: 99,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

export const EditorLoadingModal = () => {
  const uiState = useContext(UICtx);
  const editorState = useContext(EditorCtx);

  if (!uiState.isLoading && editorState.elements.length > 0) {
    return null;
  }
  return (
    <ContainerStyled>
      <Loading />
    </ContainerStyled>
  );
};

export default memo(EditorLoadingModal);
