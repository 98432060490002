import { UICtx } from '@frontend/sorghum/external-providers';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { FC, useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';

const ContainerStyled = styled(Box)(({ theme }) => ({
  position: 'fixed',
  top: 0,
  display: 'flex',
  width: '100vw',
  height: '30px',
  borderTop: `4px solid ${theme.palette['primary']['main']}`,
  justifyContent: 'center',
  alignItems: 'center',
  background: 'transparent',
  zIndex: 9999,
}));

const ButtonWrapperStyled = styled(Box)(({ theme }) => ({
  display: 'inline-flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '4px 8px 4px 12px',
  gap: '8px',
  borderRadius: '4px',
  background: theme.palette['primary']['main'],
  cursor: 'pointer',
}));

export const DemoModeTopBar: FC = () => {
  const [t] = useTranslation();
  const { demoMode, closeDemoMode, demoModeClick } = useContext(UICtx);

  const handleClose = useCallback(() => {
    if (demoModeClick) demoModeClick();
    closeDemoMode();
  }, [closeDemoMode, demoModeClick]);
  if (!demoMode) return null;

  return (
    <ContainerStyled>
      <ButtonWrapperStyled>
        <Typography variant="body2" color="grey.white">
          {t('common.demoMode')}
        </Typography>
        <CloseIcon
          onClick={handleClose}
          fontSize="small"
          style={{
            color: 'white',
          }}
        />
      </ButtonWrapperStyled>
    </ContainerStyled>
  );
};

export default DemoModeTopBar;
