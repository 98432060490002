import {
  DefaultPostIcon,
  MenuItemProps,
  TableTree,
  TableTreeSecondLayer,
} from '@frontend/components/ui';
import { useProjectPermission } from '@frontend/editor/data-access';
import { convertNumberToK } from '@frontend/editor/utils';
import {
  useGetCommentReplyListDetail,
  usePostCommentReplyDetail,
} from '@frontend/sorghum/data-access';
import {
  CommentRepliesType,
  PostCommentReplyDataType,
  PostCommentReplyDetailPost,
  PostCommentReplyDetailResponse,
  PostCommentReplyDetailType,
  PostsType,
} from '@frontend/sorghum/interface';
import {
  COMMENT_AUTO_REPLY,
  CONTENT,
  dayjs,
  usePath,
} from '@frontend/sorghum/utils';
import {
  Box,
  Link,
  Skeleton,
  TableCell,
  TableRow,
  Typography,
  styled,
} from '@mui/material';
import { get, isUndefined } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface CommentAutoReplyListDetailProps {
  id?: string;
  data: CommentRepliesType;
  menuList?: MenuItemProps[];
  numberData: PostCommentReplyDataType;
  isDataLoading?: boolean;
}

interface ConditionListType {
  id: string;
  name: string;
  [key: string]: unknown;
}

const TableCellStyled = styled(TableCell)(({ theme }) => ({
  '.cell-container': {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },

  '.flex-end': {
    justifyContent: 'flex-end',
  },

  '.second': {
    paddingLeft: '24px',
  },

  '&.clickable': {
    cursor: 'pointer',
  },

  '&.overflow-ellipsis': {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
}));

const TableRowStyled = styled(TableRow)(({ theme }) => ({
  '& > td': {
    backgroundColor: theme.palette['grey'][50],
  },

  '&.mask': {
    position: 'absolute',
    width: '100%',
    zIndex: '5',
    backdropFilter: 'blur(5px)',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const ImageContainerStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '32px',
  height: '32px',
  borderRadius: '4px',
  backgroundPosition: 'center',
  backgroundSize: 'contain',
  backgroundRepeat: 'no-repeat',
  marginRight: '8px',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: theme.palette['grey'][100],
}));

const SecondLayerFirstCellStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  paddingLeft: '56px',
}));

export const CommentAutoReplyCellWithData = ({
  num,
  uniqNum,
  isLink,
  numType,
  commentID,
  name,
  isLoading,
  id,
}: {
  num?: number;
  uniqNum?: number;
  isLink?: boolean;
  numType?: string;
  commentID?: string;
  name?: string;
  isLoading?: boolean;
  id?: string;
}) => {
  const { navigateToPeopleWithCondition } = usePath();

  return (
    <Box className="cell-container flex-end">
      {isLoading ? (
        <Skeleton variant="text" width={100} animation="wave" />
      ) : (
        <Box className="cell-container">
          {isLink ? (
            <Link
              id={id}
              variant="overline"
              onClick={() =>
                navigateToPeopleWithCondition({
                  searchFilter: 'commentReply',
                  search: numType,
                  operator: '1',
                  key: commentID,
                  name,
                })
              }
            >
              <Typography variant="body2" color="bluegrey.700">
                {isUndefined(uniqNum) ? '-' : convertNumberToK(uniqNum)}&nbsp;
              </Typography>
            </Link>
          ) : (
            <Typography variant="body2" color="bluegrey.700">
              {isUndefined(uniqNum) ? '-' : convertNumberToK(uniqNum)}&nbsp;
            </Typography>
          )}

          <Typography variant="body2" color="grey.500">
            {'/'}&nbsp;
            {isUndefined(num) ? '-' : convertNumberToK(num)}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

const StatusText = ({ status }: { status: number }) => {
  const [t] = useTranslation();

  if (status === 2) {
    return (
      <Typography variant="body2" color="success.main">
        {t('commentAutoReply.list.table.active')}
      </Typography>
    );
  } else {
    return (
      <Typography variant="body2" color="grey.500" sx={{ fontStyle: 'italic' }}>
        {t('commentAutoReply.list.table.inactive')}
      </Typography>
    );
  }
};

const PeriodText = ({
  periodType,
  period,
}: {
  periodType: number;
  period?: { startedAt: string; endedAt: string };
}) => {
  const [t] = useTranslation();

  if (periodType === 1) {
    return (
      <Typography variant="body2" color="grey.500">
        {dayjs(period?.startedAt).tz().format('YYYY/MM/DD, HH:mm')} -{' '}
        {dayjs(period?.endedAt).tz().format('YYYY/MM/DD, HH:mm')}
      </Typography>
    );
  } else {
    return (
      <Typography variant="body2" color="grey.500">
        {t('commentAutoReply.list.table.longTerm')}
      </Typography>
    );
  }
};

const ThirdLayerChildren = ({
  isOpenSecond,
  isOpenThird,
  postList,
  postData,
  isDataLoading,
}: {
  isOpenSecond: boolean;
  isOpenThird: boolean;
  postList: PostsType[];
  postData: PostCommentReplyDetailPost[];
  isDataLoading: boolean;
}) => {
  return (
    <>
      {Object.entries(postList).map((item) => (
        <TableRowStyled
          key={item[1].id}
          sx={{
            visibility: isOpenThird && isOpenSecond ? 'visible' : 'collapse',
          }}
        >
          <TableCellStyled component="td">
            <SecondLayerFirstCellStyled>
              {item[1]?.fullPicture ? (
                <ImageContainerStyled
                  sx={{ backgroundImage: `url(${item[1]?.fullPicture})` }}
                />
              ) : (
                <ImageContainerStyled>
                  <DefaultPostIcon />
                </ImageContainerStyled>
              )}
              <Box className="cell-container">{item[1].message}</Box>
            </SecondLayerFirstCellStyled>
          </TableCellStyled>
          <TableCellStyled component="td"></TableCellStyled>
          <TableCellStyled component="td"></TableCellStyled>
          <TableCellStyled component="td">
            <CommentAutoReplyCellWithData
              num={get(postData, item[0])?.comments}
              uniqNum={get(postData, item[0])?.uniqComments}
              isLoading={isDataLoading}
            />
          </TableCellStyled>
          <TableCellStyled component="td">
            <CommentAutoReplyCellWithData
              num={get(postData, item[0])?.publicReply}
              uniqNum={get(postData, item[0])?.uniqPublicReply}
              isLoading={isDataLoading}
            />
          </TableCellStyled>
          <TableCellStyled component="td">
            <CommentAutoReplyCellWithData
              num={get(postData, item[0])?.privateReply}
              uniqNum={get(postData, item[0])?.uniqPrivateReply}
              isLoading={isDataLoading}
            />
          </TableCellStyled>
          <TableCellStyled component="td">
            <CommentAutoReplyCellWithData
              num={get(postData, item[0])?.conversations}
              uniqNum={get(postData, item[0])?.uniqConversations}
              isLoading={isDataLoading}
            />
          </TableCellStyled>
          <TableCellStyled component="td"></TableCellStyled>
        </TableRowStyled>
      ))}
    </>
  );
};

const SecondLayerChildren = ({
  condition,
  isOpenSecondLayer,
  isShowWarning,
  postList,
  isDataLoading,
  numberDataList,
}: {
  condition: ConditionListType;
  isOpenSecondLayer: boolean;
  isShowWarning: boolean;
  postList: PostsType[];
  isDataLoading: boolean;
  numberDataList: PostCommentReplyDetailResponse;
}) => {
  const [isOpenThirdLayer, setIsOpenThirdLayer] = useState(false);
  const [t] = useTranslation();

  const numberData = useCallback(
    (id: string) => {
      const specificConditionNumberData = get(
        numberDataList,
        id,
        {} as PostCommentReplyDetailType,
      );
      return specificConditionNumberData;
    },
    [numberDataList],
  );

  const postNumberData = useCallback(
    (id: string) => {
      const specificConditionNumberData = get(
        numberDataList,
        `${id}.posts`,
        [] as PostCommentReplyDetailPost[],
      );
      return specificConditionNumberData as PostCommentReplyDetailPost[];
    },
    [numberDataList],
  );

  return (
    <TableTreeSecondLayer
      key={condition.id}
      name={condition.name}
      isOpenThirdLayer={isOpenThirdLayer}
      isOpenSecondLayer={isOpenSecondLayer}
      setIsOpenThirdLayer={setIsOpenThirdLayer}
      isShowWarning={isShowWarning}
      thirdLayerListLength={Object.keys(postList).length}
      warningText={t('commentAutoReply.list.disconnectPostText')}
      thirdLayerChildren={
        <ThirdLayerChildren
          isOpenSecond={isOpenSecondLayer}
          isOpenThird={isOpenThirdLayer}
          isDataLoading={isDataLoading}
          postData={postNumberData(condition.id)}
          postList={postList}
        />
      }
    >
      <TableCellStyled component="td"></TableCellStyled>
      <TableCellStyled component="td"></TableCellStyled>
      <TableCellStyled component="td">
        <CommentAutoReplyCellWithData
          num={numberData(condition.id)?.comments}
          uniqNum={numberData(condition.id)?.uniqComments}
          isLoading={isDataLoading}
          id="comment_auto_reply_post_comments"
        />
      </TableCellStyled>
      <TableCellStyled component="td">
        <CommentAutoReplyCellWithData
          num={numberData(condition.id)?.publicReply}
          uniqNum={numberData(condition.id)?.uniqPublicReply}
          isLoading={isDataLoading}
          id="comment_auto_reply_post_public_reply"
        />
      </TableCellStyled>
      <TableCellStyled component="td">
        <CommentAutoReplyCellWithData
          num={numberData(condition.id)?.privateReply}
          uniqNum={numberData(condition.id)?.uniqPrivateReply}
          isLoading={isDataLoading}
          id="comment_auto_reply_post_private_reply"
        />
      </TableCellStyled>
      <TableCellStyled component="td">
        <CommentAutoReplyCellWithData
          num={numberData(condition.id)?.conversations}
          uniqNum={numberData(condition.id)?.uniqConversations}
          isLoading={isDataLoading}
          id="comment_auto_reply_post_conversations"
        />
      </TableCellStyled>
      <TableCellStyled component="td"></TableCellStyled>
    </TableTreeSecondLayer>
  );
};

const SecondLayerChildrenList = ({
  conditionList,
  numberDataList,
  isDataLoading,
  isOpenSecondLayer,
  postList,
  isShowWarning,
}: {
  conditionList: ConditionListType[];
  numberDataList: PostCommentReplyDetailResponse;
  isDataLoading: boolean;
  isOpenSecondLayer: boolean;
  postList: PostsType[];
  isShowWarning: boolean;
}) => {
  return (
    <>
      {conditionList.map((condition: ConditionListType) => {
        return (
          <SecondLayerChildren
            key={condition.id}
            condition={condition}
            isOpenSecondLayer={isOpenSecondLayer}
            isShowWarning={isShowWarning}
            postList={postList}
            isDataLoading={isDataLoading}
            numberDataList={numberDataList}
          />
        );
      })}
    </>
  );
};

export function CommentAutoReplyListDetail({
  id,
  data,
  menuList,
  numberData,
  isDataLoading = false,
}: CommentAutoReplyListDetailProps) {
  const [t] = useTranslation();

  const [isOpenSecondLayer, setIsOpenSecondLayer] = useState(false);
  const [commentReplyID, setCommentReplyID] = useState('');
  const [channelID, setChannelID] = useState('');

  const { navigateToProjectPage } = usePath();
  const { isViewer } = useProjectPermission();
  const { data: detailData } = useGetCommentReplyListDetail(commentReplyID);
  const { data: detailDataNumber } = usePostCommentReplyDetail(
    commentReplyID,
    channelID,
  );

  useEffect(() => {
    if (isOpenSecondLayer) {
      setCommentReplyID(data.id);
      setChannelID(detailData?.projectChannelId ?? '');
    }
  }, [data.id, detailData?.projectChannelId, isOpenSecondLayer]);

  const conditionList: ConditionListType[] = get(
    detailData,
    'conditions',
    [] as ConditionListType[],
  ) as ConditionListType[];
  const postList: PostsType[] = get(detailData, 'posts', [] as PostsType[]);

  return (
    <TableTree
      id={id}
      onClickFirstLayerTitle={() =>
        navigateToProjectPage(`/${COMMENT_AUTO_REPLY}/${data.id}/${CONTENT}`)
      }
      isOpenSecondLayer={isOpenSecondLayer}
      setIsOpenSecondLayer={setIsOpenSecondLayer}
      menuList={menuList}
      menuListDisabled={isViewer}
      firstLayerChildren={
        <>
          <TableCellStyled
            className="clickable"
            onClick={() =>
              navigateToProjectPage(
                `/${COMMENT_AUTO_REPLY}/${data.id}/${CONTENT}`,
              )
            }
            component="td"
          >
            <StatusText status={data.status} />
          </TableCellStyled>
          <TableCellStyled
            className="clickable"
            onClick={() =>
              navigateToProjectPage(
                `/${COMMENT_AUTO_REPLY}/${data.id}/${CONTENT}`,
              )
            }
            component="td"
          >
            <PeriodText
              periodType={data.periodType}
              period={data?.period ?? undefined}
            />
          </TableCellStyled>
          <TableCellStyled component="td">
            <CommentAutoReplyCellWithData
              commentID={data.id}
              num={numberData.comments}
              uniqNum={numberData.uniqComments}
              isLink
              numType="comments"
              name={data.name}
              isLoading={isDataLoading}
              id="comment_auto_reply_condition_comments"
            />
          </TableCellStyled>
          <TableCellStyled component="td">
            <CommentAutoReplyCellWithData
              commentID={data.id}
              num={numberData.publicReply}
              uniqNum={numberData.uniqPublicReply}
              isLink
              numType="publicReply"
              name={data.name}
              isLoading={isDataLoading}
              id="comment_auto_reply_condition_public_reply"
            />
          </TableCellStyled>
          <TableCellStyled component="td">
            <CommentAutoReplyCellWithData
              commentID={data.id}
              num={numberData.privateReply}
              uniqNum={numberData.uniqPrivateReply}
              isLink
              numType="privateReply"
              name={data.name}
              isLoading={isDataLoading}
              id="comment_auto_reply_condition_private_reply"
            />
          </TableCellStyled>
          <TableCellStyled component="td">
            <CommentAutoReplyCellWithData
              commentID={data.id}
              num={numberData.conversations}
              uniqNum={numberData.uniqConversations}
              isLink
              numType="conversations"
              name={data.name}
              isLoading={isDataLoading}
              id="comment_auto_reply_condition_conversations"
            />
          </TableCellStyled>
        </>
      }
      firstRowTitle={data.name}
      isShowWarning={data.status === 3}
      warningTooltips={t('commentAutoReply.list.disconnectTooltip')}
      secondLayerChildren={
        <SecondLayerChildrenList
          conditionList={conditionList}
          numberDataList={
            detailDataNumber ?? ({} as PostCommentReplyDetailResponse)
          }
          isDataLoading={isDataLoading}
          isOpenSecondLayer={isOpenSecondLayer}
          postList={postList}
          isShowWarning={data.status === 3}
        />
      }
    />
  );
}
