import { globalTheme } from '@frontend/components/external-providers';
import {
  useCanvasGet,
  useCanvasRemove,
  useCanvasUpdate,
  useModal,
} from '@frontend/editor/data-access';
import { EditorCtx, UICtx } from '@frontend/editor/external-providers';
import { TextButtonCellType } from '@frontend/editor/interface';
import AddIcon from '@mui/icons-material/Add';
import { Box, Button, Typography } from '@mui/material';
import { get } from 'lodash';
import {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  SortableContainer,
  SortableContainerProps,
  SortableElement,
  SortableElementProps,
} from 'react-sortable-hoc';
import { useViewport } from 'reactflow';
import EditorFloatPanel from '../editor-float-panel/editor-float-panel';
import TextButtonCell from '../text-button-cell/text-button-cell';
import { SortableItemStyled } from './styles';

// cell
const SortableItem: React.ComponentClass<
  SortableElementProps & {
    id: string;
    parentID: string;
    draggable: boolean;
    setIsHover: Dispatch<SetStateAction<boolean>>;
  }
> = SortableElement(
  ({
    id,
    parentID,
    draggable,
    setIsHover,
  }: {
    id: string;
    parentID: string;
    draggable: boolean;
    setIsHover: Dispatch<SetStateAction<boolean>>;
  }) => {
    const state = useContext(EditorCtx);
    const UIState = useContext(UICtx);
    const ref = useRef(null);
    const { zoom } = useViewport();

    const itemData = state.getElement(id) as TextButtonCellType;

    if (!itemData) {
      return null;
    }
    // 紀錄是否 hover InputField
    const [isInputFieldHover, setIsInputFieldHover] = useState(false);

    const { canvasUpdateLabel } = useCanvasUpdate();
    const { removeCell } = useCanvasRemove();
    const { openDeleteTextButtonCellModal } = useModal();

    const handleMouseEnterInputField = useCallback(
      (objectId: string) => {
        // hover on cell
        UIState.onHoverElement(objectId, 3);
        setIsInputFieldHover(true);
      },
      [UIState],
    );

    const handleMouseLeaveInputField = useCallback(() => {
      UIState.onHoverElement('', 0);
      setIsInputFieldHover(false);
    }, [UIState]);

    const handleLabelChange = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        canvasUpdateLabel(itemData.id, e.target.value);
      },
      [itemData?.id, canvasUpdateLabel],
    );

    const handleDeleteClick = useCallback(() => {
      if (itemData) {
        if (
          itemData.label ||
          (itemData.data.tel && itemData.data.tel !== '') ||
          (itemData.data.url && itemData.data.url !== '') ||
          (itemData.data.webview && itemData.data.webview !== '') ||
          (itemData.data.selectedBlockID &&
            itemData.data.selectedBlockID !== '') ||
          itemData.targetID
        ) {
          openDeleteTextButtonCellModal(() => removeCell(itemData.id));
          return;
        }
      }

      removeCell(id);
    }, [itemData, removeCell, id, openDeleteTextButtonCellModal]);

    return (
      <SortableItemStyled
        key={id}
        onMouseEnter={() => handleMouseEnterInputField(id)}
        onMouseLeave={() => handleMouseLeaveInputField()}
        $zoom={zoom}
      >
        {/* InputField 本身的刪除和 sorting 按鈕 */}

        {(isInputFieldHover || state.onFocusCellID === id) && (
          <EditorFloatPanel
            type="button"
            draggable={draggable}
            direction="column"
            handleDelete={handleDeleteClick}
            backgroundColor={
              globalTheme ? globalTheme.palette?.grey?.white : ''
            }
          />
        )}

        <TextButtonCell
          innerRef={ref}
          elementID={id}
          setIsHoverBlock={setIsHover}
          setIsInputFieldHover={setIsInputFieldHover}
          isInputFieldHover={isInputFieldHover}
          onChange={handleLabelChange}
        />
      </SortableItemStyled>
    );
  },
);

const SortableList: React.ComponentClass<
  SortableContainerProps & {
    items: string[];
    parentID: string;
  }
> = SortableContainer(
  ({ items, parentID }: { items: string[]; parentID: string }) => {
    const [isHover, setIsHover] = useState(false);

    return (
      <Box>
        {items.map((item, index: number) => (
          <SortableItem
            parentID={parentID}
            key={item}
            index={index}
            id={item}
            setIsHover={setIsHover}
            disabled={isHover}
            draggable={items.length > 1}
          />
        ))}
      </Box>
    );
  },
);

interface SortableTextButtonGroupProps {
  parentID: string;
  onAddClick: () => void;
  onSortEnd: (oldIndex: number, newIndex: number) => void;
  addButtonID?: string;
}

// 讓 sortable item 在拖曳的時候不會被 zoom 影響大小

const SortableTextButtonGroup: FC<SortableTextButtonGroupProps> = ({
  onSortEnd,
  onAddClick,
  parentID,
  addButtonID = 'button_add',
}: SortableTextButtonGroupProps) => {
  const { t } = useTranslation();

  const { getTargetElement } = useCanvasGet();

  const parentElement = useMemo(
    () => getTargetElement(parentID),
    [getTargetElement, parentID],
  );
  const children = get(parentElement, 'children', []);

  return (
    <Box>
      <SortableList
        items={children}
        lockAxis="y"
        onSortEnd={({ oldIndex, newIndex }) => onSortEnd(oldIndex, newIndex)}
        parentID={parentID}
        useDragHandle
        helperClass="cell_dragged_style"
      />

      {children.length < 3 && (
        <Box>
          {/* Add editable button */}
          <Button
            id={addButtonID}
            fullWidth
            variant="text"
            color="inherit"
            sx={{
              background: '#ffffff',
            }}
            startIcon={<AddIcon fontSize="small" />}
            onClick={onAddClick}
          >
            <Typography
              variant="body2"
              color="grey.900"
              sx={{ textTransform: 'none' }}
            >
              {t('canvas.addButton')}
            </Typography>
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default SortableTextButtonGroup;
