import { PostDataDashboardOperationFlowResponse } from '@frontend/sorghum/interface';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { DATA_DASHBOARD_OPERATION_FLOW } from '../apis';

const defaultData = {
  enterUsers: 0,
  interactionUsers: 0,
  uniqEnterUsers: 0,
  uniqInteractionUsers: 0,
};

async function postDataDashboardOperationFlow(
  startDate: string,
  endDate: string,
): Promise<PostDataDashboardOperationFlowResponse> {
  return axios
    .post(DATA_DASHBOARD_OPERATION_FLOW, { startDate, endDate })
    .then((res) => res.data.data)
    .catch((err) => defaultData);
}

export function usePostDataDashboardOperationFlow(
  startDate: string,
  endDate: string,
) {
  return useQuery({
    queryKey: ['post-data-dashboard-operation-flow', { startDate, endDate }],
    queryFn: () => postDataDashboardOperationFlow(startDate, endDate),
  });
}

export default usePostDataDashboardOperationFlow;
