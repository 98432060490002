import { BaseElement } from '@frontend/editor/interface';
import { Edge, MarkerType } from 'reactflow';

export const getEdge = (source: BaseElement, target: BaseElement) => {
  let newEdge: Edge;
  if (source.nodeID) {
    // 只有在內層的元素才會有 node id
    newEdge = {
      id: source.outputID,
      source: source.nodeID as string,
      sourceHandle: source.outputID,
      target: target.id,
      targetHandle: target.inputID,
      updatable: 'target',
      markerEnd: {
        type: MarkerType.ArrowClosed,
      },
    };
  } else {
    // 如果找不到代表是最外層的 node，sourceID 會是 elementID
    newEdge = {
      id: source.outputID,
      source: source.id,
      target: target.id,
      updatable: 'target',
      markerEnd: {
        type: MarkerType.ArrowClosed,
      },
    };
  }
  return newEdge;
};
