import { EditorCtx } from '@frontend/editor/external-providers';
import { Box, BoxProps, styled } from '@mui/material';
import { useContext, useMemo } from 'react';

interface EditorBlockBodyProps extends BoxProps {
  nodeID: string;
  color: string;
  zoom?: number;
  selected?: boolean;
}

const EditorBlockBorderStyled = styled(Box)<{
  $focus: boolean;
  $color: string;
  $disabled?: boolean;
  $zoom?: number;
}>(({ $focus, $color, $zoom, $disabled }) => ({
  background: '#ffffff',
  display: 'flex',
  flexDirection: 'column',
  borderRadius: '11px',
  cursor: 'move',
  fontSize: `${$zoom ? 28 / $zoom : 28}px`,
  margin: '2px',
  boxShadow:
    '0px 3px 6px rgba(69, 90, 100, 0.15), 0px 2px 3px rgba(69, 90, 100, 0.12)',
  ...($focus && { border: `2px solid ${$color}`, margin: 0 }),
  '&:hover': {
    margin: 0,
    border: `2px solid ${$color}`,
  },
  ...($disabled && {
    pointerEvents: 'none',
  }),
}));

export const EditorBlockBorder = ({
  children,
  nodeID,
  color,
  zoom,
  selected,
  ...props
}: EditorBlockBodyProps) => {
  const { tourMode } = useContext(EditorCtx);

  const isSelected = useMemo(
    () => !!selected && !tourMode,
    [selected, tourMode],
  );

  return (
    <EditorBlockBorderStyled
      $focus={isSelected}
      $color={color}
      $zoom={zoom}
      $disabled={tourMode}
      {...props}
    >
      {children}
    </EditorBlockBorderStyled>
  );
};

export default EditorBlockBorder;
