import { GetDataDashboardTotalResponse } from '@frontend/sorghum/interface';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { DASHBOARD_DATA_TOTAL } from '../apis';

const defaultValue = {
  uniqUsers: 0,
  uniqRnSubscribers: 0,
  uniqActiveUsers: 0,
  broadcastCount: 0,
  uniqBroadcastOpenP: 0,
} as GetDataDashboardTotalResponse;

async function getDataDashboardTotal(
  projectID: string,
): Promise<GetDataDashboardTotalResponse> {
  return axios
    .get(`${DASHBOARD_DATA_TOTAL}?projectId=${projectID}`)
    .then((res) => res.data.data)
    .catch((err) => defaultValue);
}

export function useGetDataDashboardTotal(projectID: string) {
  return useQuery<GetDataDashboardTotalResponse>({
    queryKey: ['get-data-dashboard-total', projectID],
    queryFn: () => {
      if (projectID) {
        return getDataDashboardTotal(projectID);
      } else {
        return defaultValue;
      }
    },
  });
}

export default useGetDataDashboardTotal;
