import { globalTheme } from '@frontend/components/external-providers';
import { ChartDataType } from '@frontend/components/interface';
import { ChartArea } from '@frontend/components/ui';
import { useGetProjectID } from '@frontend/editor/data-access';
import { convertNumberToPercentage } from '@frontend/editor/utils';
import { usePostDataDashboardEngagementChart } from '@frontend/sorghum/data-access';
import {
  DashboardEngagementChartData,
  PostDataDashboardProps,
} from '@frontend/sorghum/interface';
import { dayjs } from '@frontend/sorghum/utils';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/system';

import { get } from 'lodash';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TooltipProps } from 'recharts';

const CustomTooltipWrapper = styled(Box)(({ theme }) => ({
  padding: '12px 8px',
  background: 'white',
  display: 'flex',
  flexDirection: 'column',
  border: `1px solid ${theme.palette['grey'][200]}`,
  gap: '8px',
}));

const CustomTooltip = ({
  payload,
  active,
  label,
}: TooltipProps<string, string>) => {
  const [t] = useTranslation();
  if (active && payload && payload.length) {
    const user =
      payload[0].payload[
        t('dataDashboard.userAnalysis.chart.users')
      ]?.toLocaleString();
    const usersP = convertNumberToPercentage(
      payload[0].payload.uniqNewActiveUsersP,
    );
    const retentionUsers =
      payload[0].payload[
        t('dataDashboard.userAnalysis.chart.retentionUsers')
      ]?.toLocaleString();
    const retentionUsersP = convertNumberToPercentage(
      payload[0].payload.uniqRetentionUsersP,
    );
    const sleepUsers =
      payload[0].payload[
        t('dataDashboard.userAnalysis.chart.sleepUsers')
      ]?.toLocaleString();
    const sleepUsersP = convertNumberToPercentage(
      payload[0].payload.uniqSleepUsersP,
    );
    const aboveTotal = payload[0].payload.aboveTotal;
    return (
      <CustomTooltipWrapper>
        <Typography variant="subtitle1">{label}</Typography>
        <Typography variant="body2" color={get(globalTheme, 'palette.chart.2')}>
          {t('dataDashboard.userAnalysis.chart.users')}
          &nbsp;:&nbsp;
          {user}({usersP})
        </Typography>
        <Typography variant="body2" color={get(globalTheme, 'palette.chart.1')}>
          {t('dataDashboard.userAnalysis.chart.retentionUsers')}
          &nbsp;:&nbsp;
          {retentionUsers}({retentionUsersP})
        </Typography>
        <Typography variant="body2" color="grey.600">
          --&nbsp;
          {t('dataDashboard.userAnalysis.chart.aboveTotal')}
          &nbsp;:&nbsp;
          {aboveTotal}
        </Typography>
        <Typography variant="body2" color={get(globalTheme, 'palette.chart.0')}>
          {t('dataDashboard.userAnalysis.chart.sleepUsers')}
          &nbsp;:&nbsp;
          {sleepUsers}({sleepUsersP})
        </Typography>
      </CustomTooltipWrapper>
    );
  }

  return null;
};

export const DataDashboardEngagementChart: FC<PostDataDashboardProps> = ({
  startDate,
  endDate,
  condition,
}: PostDataDashboardProps) => {
  const [t] = useTranslation();
  const { data: projectID } = useGetProjectID();
  const [chartData, setChartData] = useState<ChartDataType[]>([]);
  const { mutate: queryData, isLoading } = usePostDataDashboardEngagementChart(
    projectID as string,
  );

  useEffect(() => {
    if (startDate && endDate && projectID) {
      queryData(
        { startDate, endDate, condition },
        {
          onSuccess: (data: DashboardEngagementChartData[]) => {
            const newChartData: ChartDataType[] = [];
            data.forEach((i) => {
              newChartData.push({
                name: dayjs(i.startDate).format('MM / DD'),
                [t('dataDashboard.userAnalysis.chart.users')]:
                  i.uniqNewActiveUsers,
                [t('dataDashboard.userAnalysis.chart.retentionUsers')]:
                  i.uniqRetentionUsers,
                [t('dataDashboard.userAnalysis.chart.sleepUsers')]:
                  i.uniqSleepUsers,
                uniqNewActiveUsersP: i.uniqNewActiveUsersP,
                uniqRetentionUsersP: i.uniqRetentionUsersP,
                uniqSleepUsersP: i.uniqSleepUsersP,
                aboveTotal: i.aboveTotal,
              });
            });

            setChartData(newChartData);
          },
        },
      );
    }
  }, [startDate, endDate, condition, queryData, t, projectID]);

  return (
    <ChartArea
      data={chartData}
      displayKeys={[
        t('dataDashboard.userAnalysis.chart.sleepUsers'),
        t('dataDashboard.userAnalysis.chart.retentionUsers'),
        t('dataDashboard.userAnalysis.chart.users'),
      ]}
      customTooltip={<CustomTooltip />}
      isLoading={isLoading}
      title={t('dataDashboard.numberOfUsers')}
    />
  );
};

export default DataDashboardEngagementChart;
