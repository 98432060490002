import { queryClient } from '@frontend/sorghum/external-providers';
import {
  GetAccountTutorialResponse,
  PostAccountTutorialRequestType,
  Response,
} from '@frontend/sorghum/interface';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { ACCOUNT_TUTORIAL } from '../apis';

const defaultData = {} as PostAccountTutorialRequestType;

async function postAccountTutorial(
  props: PostAccountTutorialRequestType,
): Promise<Response> {
  return axios
    .post(ACCOUNT_TUTORIAL, props)
    .then((res) => res.data)
    .catch((err) => {
      return defaultData;
    });
}

export function usePostAccountTutorial() {
  return useMutation({
    mutationKey: ['post-account-tutorial'],
    mutationFn: (props: PostAccountTutorialRequestType) => {
      if (props.marketingGuru?.customer?.hasInteracted) {
        queryClient.setQueriesData(
          ['get-account-tutorial'],
          (prev: GetAccountTutorialResponse | undefined) => {
            return {
              ...prev,
              marketingGuru: {
                ...prev?.marketingGuru,
                customer: {
                  ...prev?.marketingGuru?.customer,
                  hasInteracted: true,
                },
              },
            } as GetAccountTutorialResponse;
          },
        );
      }
      return postAccountTutorial(props);
    },
  });
}

export default usePostAccountTutorial;
