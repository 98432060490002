import { globalTheme } from '@frontend/components/external-providers';
import { Button } from '@frontend/components/ui';
import {
  useCanvasAdd,
  useCanvasCopy,
  useCanvasGet,
  useCanvasRemove,
  useCanvasUpdate,
  useCanvasView,
  useGetGoogleAccount,
  useGetGoogleSheet,
  useGetProjectID,
} from '@frontend/editor/data-access';
import { UICtx as EditorUICtx } from '@frontend/editor/external-providers';
import {
  ActionAttributeType,
  ActionCellBaseType,
  ActionCellTypesEnum,
  ActionSequenceType,
  ActionSheetType,
  DrawerTypesEnum,
  ElementTypesEnum,
} from '@frontend/editor/interface';
import { useGetAttributeKey } from '@frontend/sorghum/data-access';
import {
  CONTENT,
  GOOGLE_SHEET,
  HOW_TO_USE_ATTRIBUTE_EN,
  PAGE_SEQUENCE,
  usePath,
} from '@frontend/sorghum/utils';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AddIcon from '@mui/icons-material/Add';
import BookmarkBorderOutlinedIcon from '@mui/icons-material/BookmarkBorderOutlined';
import BookmarkRemoveOutlinedIcon from '@mui/icons-material/BookmarkRemoveOutlined';
import BookmarksIcon from '@mui/icons-material/Bookmarks';
import LabelOffOutlinedIcon from '@mui/icons-material/LabelOffOutlined';
import LabelOutlinedIcon from '@mui/icons-material/LabelOutlined';
import TableChartOutlinedIcon from '@mui/icons-material/TableChartOutlined';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import {
  Box,
  IconButton,
  Link,
  Popover,
  Tooltip,
  Typography,
} from '@mui/material';
import { get, isArray } from 'lodash';
import {
  SetStateAction,
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Trans, useTranslation } from 'react-i18next';
import {
  SortableContainer,
  SortableContainerProps,
  SortableElement,
  SortableElementProps,
} from 'react-sortable-hoc';
import { NodeProps, Position, useViewport } from 'reactflow';
import EditorBlockBorder from '../editor-block-border/editor-block-border';
import EditorBlockContainer from '../editor-block-container/editor-block-container';
import EditorFloatPanel from '../editor-float-panel/editor-float-panel';
import EditorMenu from '../editor-menu/editor-menu';
import { HandlePoint } from '../handle-point/handle-point';
import { NodeHeader } from '../node-header/node-header';
import {
  AttributeWrapperStyled,
  BodyStyled,
  GoogleSheetWrapperStyled,
  ItemWrapperStyled,
  TitleWrapperStyled,
} from './styles';

export const ActionCellItem = ({
  actionData,
}: {
  actionData: ActionCellBaseType;
}) => {
  const { navigateToProjectPage } = usePath();
  const [t] = useTranslation();
  const editorUIState = useContext(EditorUICtx);
  const { data: projectID } = useGetProjectID();
  const { data: attributeData } = useGetAttributeKey(projectID as string);

  const { data: accounts, isFetched: isFetchedAccount } = useGetGoogleAccount({
    projectID: projectID as string,
  });

  const { data: sheets, isFetched: isFetchedSheet } = useGetGoogleSheet({
    projectID: projectID as string,
    accountID: get(actionData, 'data.accountID', ''),
  });

  const attributeName = useMemo(() => {
    if (isArray(attributeData)) {
      return attributeData.find(
        (data) => data.id === get(actionData, 'attributeID', {}),
      )?.key;
    } else {
      return '';
    }
  }, [attributeData, actionData]);

  // 還沒載完顯示蓋版 loading 動畫
  useEffect(() => {
    const accountID = get(actionData, 'data.accountID', '');
    if (accountID && !isFetchedAccount && !isFetchedSheet) {
      editorUIState.addLoading(actionData.id);
    } else {
      editorUIState.removeLoading(actionData.id);
    }
  }, [
    editorUIState,
    actionData.id,
    isFetchedAccount,
    isFetchedSheet,
    actionData,
  ]);

  switch (actionData.actionType) {
    case ActionCellTypesEnum.SET: {
      const _actionData = actionData as ActionAttributeType;
      return (
        <>
          <TitleWrapperStyled>
            <Typography
              sx={{ marginRight: '12px' }}
              variant="subtitle2"
              color="grey.900"
            >
              <Trans i18nKey="canvas.action.set.title" />
            </Typography>
            {(!_actionData.attributeID || !_actionData.valueID) && (
              <Tooltip
                title={t('canvas.action.set.tooltipSetAttribute')}
                placement="top"
              >
                <IconButton size="small" color="error">
                  <WarningAmberOutlinedIcon />
                </IconButton>
              </Tooltip>
            )}
          </TitleWrapperStyled>
          <AttributeWrapperStyled>
            {_actionData.attributeID ? (
              <>
                <Typography variant="caption" color="primary">
                  {attributeName}
                </Typography>
                &nbsp;
                <Typography variant="caption" color="grey.600">
                  <Trans i18nKey="canvas.condition.drawer.is" />
                </Typography>
                &nbsp;
                <Typography
                  sx={{
                    fontWeight: 700,
                  }}
                  variant="caption"
                  color="grey.700"
                >
                  {_actionData.valueName}
                </Typography>
              </>
            ) : (
              <Typography variant="caption" color="bluegrey.400">
                <Trans i18nKey="canvas.action.set.empty" />
              </Typography>
            )}
          </AttributeWrapperStyled>
        </>
      );
    }
    case ActionCellTypesEnum.REMOVE: {
      const _actionData = actionData as ActionAttributeType;
      return (
        <>
          <TitleWrapperStyled>
            <Typography
              sx={{ marginRight: '12px' }}
              variant="subtitle2"
              color="grey.900"
            >
              <Trans i18nKey="canvas.action.remove.title" />
            </Typography>
            {!_actionData.attributeID && (
              <IconButton size="small" color="error">
                <WarningAmberOutlinedIcon />
              </IconButton>
            )}
          </TitleWrapperStyled>
          <AttributeWrapperStyled>
            {_actionData.attributeID ? (
              <Typography variant="caption" color="primary">
                {attributeName}
              </Typography>
            ) : (
              <Typography variant="caption" color="bluegrey.400">
                <Trans i18nKey="canvas.action.remove.empty" />
              </Typography>
            )}
          </AttributeWrapperStyled>
        </>
      );
    }
    case ActionCellTypesEnum.SUBSCRIBE_SEQUENCE:
    case ActionCellTypesEnum.UNSUBSCRIBE_SEQUENCE: {
      const _actionData = actionData as ActionSequenceType;
      return (
        <>
          <TitleWrapperStyled>
            <Typography
              sx={{ marginRight: '12px' }}
              variant="subtitle2"
              color="grey.900"
            >
              {actionData.actionType ===
              ActionCellTypesEnum.SUBSCRIBE_SEQUENCE ? (
                <Trans i18nKey="canvas.action.subscribe.title" />
              ) : (
                <Trans i18nKey="canvas.action.unsubscribe.title" />
              )}
            </Typography>
            {!_actionData.sequence && (
              <Tooltip
                title={t('canvas.action.subscribe.tooltipSubscribe')}
                placement="top"
              >
                <IconButton size="small" color="error">
                  <WarningAmberOutlinedIcon />
                </IconButton>
              </Tooltip>
            )}
          </TitleWrapperStyled>
          <AttributeWrapperStyled>
            {_actionData.sequence ? (
              <Tooltip placement="bottom-end" title={_actionData.sequence.name}>
                <Typography
                  onClick={() =>
                    navigateToProjectPage(
                      `${PAGE_SEQUENCE}/${_actionData.sequence.id}/${CONTENT}`,
                    )
                  }
                  variant="caption"
                  color="primary"
                >
                  {_actionData.sequence.name}
                </Typography>
              </Tooltip>
            ) : (
              <Typography variant="caption" color="bluegrey.400">
                <Trans i18nKey="canvas.action.subscribe.empty" />
              </Typography>
            )}
          </AttributeWrapperStyled>
        </>
      );
    }
    case ActionCellTypesEnum.EXPORT_GOOGLE_SHEET: {
      const _actionData = (actionData as ActionSheetType).data;
      const accountEmail = _actionData.email;
      const sheetName = _actionData.sheetName;

      const isDataExist =
        accounts?.find((i) => i.id === _actionData.accountID) &&
        sheets?.find((i) => i.id === _actionData.sheetID);

      const isDataCompleted =
        _actionData &&
        _actionData.accountID &&
        _actionData.sheetID &&
        _actionData.properties?.length > 0 &&
        isDataExist;

      return (
        <>
          <TitleWrapperStyled>
            <Typography
              sx={{ marginRight: '12px' }}
              variant="subtitle2"
              color="grey.900"
            >
              <Trans i18nKey="canvas.action.sheet.title" />
            </Typography>
            {!isDataCompleted && (
              <Tooltip
                placement="top"
                title={t('canvas.action.sheet.iconTooltip')}
              >
                <IconButton size="small" color="error">
                  <WarningAmberOutlinedIcon />
                </IconButton>
              </Tooltip>
            )}
          </TitleWrapperStyled>
          {accountEmail && (
            <GoogleSheetWrapperStyled>
              <Typography
                sx={{
                  display: 'inline-flex',
                }}
                variant="caption"
                color="grey.500"
              >
                <AccountCircleIcon
                  fontSize="tiny"
                  sx={{ marginRight: '3px', marginBottom: '-3px' }}
                />

                {accountEmail}
              </Typography>
              {isDataExist ? (
                <Link
                  target="_blank"
                  href={`${GOOGLE_SHEET}/${_actionData.sheetID}`}
                  color="info.main"
                  variant="overline"
                >
                  {sheetName}
                </Link>
              ) : (
                <Typography variant="overline" color="grey.400">
                  {sheetName}
                </Typography>
              )}
            </GoogleSheetWrapperStyled>
          )}
          <AttributeWrapperStyled>
            {_actionData.properties?.length > 0 ? (
              <Typography variant="caption" color="primary">
                {t('canvas.action.sheet.export', {
                  count: _actionData.properties.length,
                })}
              </Typography>
            ) : (
              <Typography variant="caption" color="bluegrey.400">
                <Trans i18nKey="canvas.action.sheet.empty" />
              </Typography>
            )}
          </AttributeWrapperStyled>
        </>
      );
    }
    default: {
      return null;
    }
  }
};

// cell
const SortableItem: React.ComponentClass<
  SortableElementProps & {
    id: string;
    parentID: string;
    draggable: boolean;
  }
> = SortableElement(
  ({
    id: cellID,
    parentID,
    index,
    draggable,
  }: {
    id: string;
    parentID: string;
    index: number;
    draggable: boolean;
  }) => {
    const { removeElement } = useCanvasRemove();
    const {
      onHoverCellID,
      onFocusCellID,
      focusOnCell,
      handleDrawer,
      onHoverElement,
    } = useCanvasView();
    const { zoom } = useViewport();
    const { getTargetElement } = useCanvasGet();

    const actionData = useMemo(
      () => getTargetElement(cellID) as ActionCellBaseType,
      [cellID, getTargetElement],
    );

    const handleClick = useCallback(
      (cellID: string) => {
        focusOnCell(cellID);
        switch (actionData.actionType) {
          case ActionCellTypesEnum.SET:
          case ActionCellTypesEnum.REMOVE: {
            handleDrawer(DrawerTypesEnum.ACTION_ATTRIBUTE);
            break;
          }
          case ActionCellTypesEnum.SUBSCRIBE_SEQUENCE:
          case ActionCellTypesEnum.UNSUBSCRIBE_SEQUENCE: {
            handleDrawer(DrawerTypesEnum.ACTION_SEQUENCE);
            break;
          }
          case ActionCellTypesEnum.EXPORT_GOOGLE_SHEET: {
            handleDrawer(DrawerTypesEnum.ACTION_EXPORT_GOOGLE_SHEET);
            break;
          }
        }
      },
      [actionData.actionType, focusOnCell, handleDrawer],
    );

    const handleMouseEnter = useCallback(() => {
      onHoverElement(cellID, 2);
    }, [cellID, onHoverElement]);

    const handleMouseLeave = useCallback(() => {
      onHoverElement('', 1);
    }, [onHoverElement]);

    return (
      <Box>
        <ItemWrapperStyled
          key={cellID}
          $isFocus={onFocusCellID === cellID}
          $isHover={onHoverCellID === cellID}
          $zoom={zoom}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          style={{
            cursor: 'pointer',
          }}
        >
          {onHoverCellID === cellID && (
            <EditorFloatPanel
              index={index}
              parentID={parentID}
              draggable={draggable}
              direction="column"
              allowAdd
              handleDelete={() => removeElement(cellID)}
              backgroundColor={globalTheme?.palette?.['grey']?.[100]}
              floatPanelType={ElementTypesEnum.ACTION}
            />
          )}
          <Box onClick={() => handleClick(cellID)}>
            <ActionCellItem actionData={actionData} />
          </Box>
        </ItemWrapperStyled>
      </Box>
    );
  },
);

const SortableList: React.ComponentClass<
  SortableContainerProps & {
    items: string[];
    parentID: string;
  }
> = SortableContainer(
  ({ items, parentID }: { items: string[]; parentID: string }) => {
    return (
      <Box>
        {items.map((item, index: number) => (
          <SortableItem
            parentID={parentID}
            key={item}
            index={index}
            id={item}
            draggable={items.length > 1}
          />
        ))}
      </Box>
    );
  },
);

export const NodeAction = ({ id, selected }: NodeProps) => {
  const [t] = useTranslation();
  const { zoom } = useViewport();
  const { getTargetElement } = useCanvasGet();
  const { canvasUpdateLabel } = useCanvasUpdate();
  const {
    addActionSetAttribute,
    addActionRemoveAttribute,
    addActionSubscribeSequence,
    addActionUnsubscribeSequence,
    addActionExportGoogleSheet,
  } = useCanvasAdd();
  const { canvasSortElement } = useCanvasUpdate();
  const { onFocusID } = useCanvasView();
  const { removeBlockWithValidation } = useCanvasRemove();
  const { copyBlocks } = useCanvasCopy();

  const [isDragging, setIsDragging] = useState<boolean>(false);
  const [isHoverBlock, setIsHoverBlock] = useState<boolean>(false);
  const [isHoverBody, setIsHoverBody] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const buttonRef = useRef<HTMLDivElement>(null);
  const isMenuOpen = Boolean(anchorEl);

  const { header, inputID, outputID, targetID, children } = useMemo(() => {
    const elementData = getTargetElement(id);
    const header = get(elementData, 'label', '');
    const inputID = get(elementData, 'inputID', '');
    const outputID = get(elementData, 'outputID', '');
    const targetID = get(elementData, 'targetID', '');
    const children = get(elementData, 'children', []) as string[];
    return {
      header,
      inputID,
      outputID,
      targetID,
      children,
    };
  }, [getTargetElement, id]);

  const onCellSortEnd = useCallback(
    ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => {
      setIsDragging(false);
      canvasSortElement(id, oldIndex, newIndex);
    },
    [setIsDragging, canvasSortElement, id],
  );

  const handleAddClick = useCallback(() => {
    setAnchorEl(buttonRef.current as SetStateAction<HTMLButtonElement | null>);
  }, [buttonRef]);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onRemoveButtonClick = useCallback(() => {
    removeBlockWithValidation(id);
  }, [id, removeBlockWithValidation]);

  const onCopyButtonClick = useCallback(() => {
    copyBlocks([id]);
  }, [copyBlocks, id]);

  const menuList = [
    {
      dropdownItems: [
        {
          onClick: () => addActionSetAttribute(id),
          icon: (
            <BookmarkBorderOutlinedIcon
              sx={{
                color: globalTheme.palette?.purple?.[600],
              }}
            />
          ),
          title: t('canvas.blockMenu.setAttribute'),
          tooltip: (
            <Box>
              {t('canvas.blockMenu.setAttributeTooltip')}{' '}
              <Link
                sx={{
                  color: get(globalTheme, 'palette.infoOnTooltips.main', ''),
                  cursor: 'pointer',
                }}
                onClick={() => window.open(HOW_TO_USE_ATTRIBUTE_EN, '_blank')}
              >
                {t('canvas.blockMenu.setAttributeTooltipLink')}
              </Link>
            </Box>
          ),
        },
        {
          onClick: () => addActionRemoveAttribute(id),
          icon: (
            <BookmarkRemoveOutlinedIcon
              sx={{
                color: get(globalTheme, 'palette.purple.600', ''),
              }}
            />
          ),
          title: t('canvas.blockMenu.removeAttribute'),
        },
        {
          onClick: () => addActionSubscribeSequence(id),
          icon: (
            <LabelOutlinedIcon
              sx={{
                color: get(globalTheme, 'palette.purple.600', ''),
              }}
            />
          ),
          title: t('canvas.blockMenu.subscribeSequence'),
        },
        {
          onClick: () => addActionUnsubscribeSequence(id),
          icon: (
            <LabelOffOutlinedIcon
              sx={{
                color: get(globalTheme, 'palette.purple.600', ''),
              }}
            />
          ),
          title: t('canvas.blockMenu.unsubscribeSequence'),
        },
        {
          onClick: () => addActionExportGoogleSheet(id),
          icon: (
            <TableChartOutlinedIcon
              sx={{
                color: get(globalTheme, 'palette.purple.600', ''),
              }}
            />
          ),
          title: t('canvas.blockMenu.exportGoogleSheet'),
          tooltip: <Box>{t('canvas.blockMenu.exportGoogleSheetTooltip')}</Box>,
        },
      ],
    },
  ];

  return (
    <EditorBlockContainer nodeID={id}>
      <HandlePoint
        id={inputID}
        type="target"
        position={Position.Left}
        styles={{
          top: '5%',
        }}
        isConnected={false}
        setIsHoverBlock={setIsHoverBlock}
      />
      <HandlePoint
        id={outputID}
        type="source"
        position={Position.Right}
        styles={{
          top: 'calc(100% - 30px)',
        }}
        isConnected={!!targetID}
        setIsHoverBlock={setIsHoverBlock}
        isFocus={onFocusID === id}
      />
      <EditorBlockBorder
        zoom={zoom}
        nodeID={id}
        selected={selected}
        color={get(globalTheme, 'palette.purple.600', '')}
        onMouseEnter={() => setIsHoverBlock(true)}
        onMouseLeave={() => setIsHoverBlock(false)}
      >
        <NodeHeader
          background={get(globalTheme, 'palette.purple.50', '')}
          color={get(globalTheme, 'palette.purple.600', '')}
          icon={<BookmarksIcon fontSize="small" />}
          title={header}
          onBlur={(val) => canvasUpdateLabel(id, val)}
        />

        <BodyStyled
          onMouseEnter={() => setIsHoverBody(true)}
          onMouseLeave={() => setIsHoverBody(false)}
        >
          <SortableList
            lockAxis="y"
            items={children}
            onSortStart={() => setIsDragging(true)}
            onSortEnd={onCellSortEnd}
            parentID={id}
            useDragHandle
            // 避免縮放時拖曳會改變大小
            helperClass="node_item_dragged_style"
          />

          <Box ref={buttonRef}>
            <Button
              id="add_element"
              sx={{ marginTop: '10px' }}
              startIcon={<AddIcon fontSize="small" />}
              variant="outlined"
              dash
              color="bluegrey300"
              fullWidth
              onClick={handleAddClick}
            >
              <Trans i18nKey="canvas.addElement" />
            </Button>
          </Box>
        </BodyStyled>
      </EditorBlockBorder>
      <Popover
        open={isMenuOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        onClick={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <EditorMenu itemList={menuList} />
      </Popover>
    </EditorBlockContainer>
  );
};

export default memo(NodeAction);
