import {
  useCanvasCollect,
  useCanvasCopy,
  useCanvasRemove,
  useCanvasView,
} from '@frontend/editor/data-access';
import { EditorCtx } from '@frontend/editor/external-providers';
import {
  BlockTypesEnum,
  ElementTypesEnum,
  NodeTypesEnum,
} from '@frontend/editor/interface';
import { isValueInEnum } from '@frontend/editor/utils';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { Box, styled } from '@mui/system';
import { find, isArray } from 'lodash';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Node, useViewport } from 'reactflow';

export interface NodeFloatPanelProps {
  index?: number;
  parentID?: string;
  allowAdd?: boolean;
  draggable?: boolean;
  direction?: 'row' | 'column';
  backgroundColor?: string | undefined;
  floatPanelType?: ElementTypesEnum;
  handleDelete?: () => void;
  handleSwitchRatio?: () => void;
  handleAdd?: () => void;
  handleMore?: () => void;
  type?: string;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  handleHover?: (target: string) => void;
  position?: { x: number; y: number };
  isByCoordinate?: boolean;
  handleCopy?: () => void;
}

interface ContainerStyledProps {
  backgroundColor?: string;
}

const FloatPanelWrapperStyled = styled(Box)<{ $zoom: number }>(() => ({
  position: 'fixed',
  paddingBottom: '36px',
  zIndex: 999,
}));

const ContainerStyled = styled(Box)<ContainerStyledProps>(({ theme }) => ({
  display: 'flex',
  width: 'fit-content',
  color: theme.palette['grey'][600],
  background: theme.palette?.['grey'][200],
  borderRadius: '100px',
  padding: '4px',

  '& > div': {
    display: 'flex',
    padding: '4px',
    borderRadius: '50%',
    cursor: 'pointer',
    '&:hover': {
      background: theme.palette['background']['black'][5],
    },
    '& > svg': {
      fontSize: '16px',
    },
  },
  flexDirection: 'row',
  backgroundColor: theme.palette['background']['bluegrey500'][10],
}));

const REACT_FLOW_SELECT_RECT_SELECTOR = '.react-flow__nodesselection-rect';

export const NodeFloatPanel = () => {
  const { onHoverNode, setOnHoverNode } = useContext(EditorCtx);
  const { zoom } = useViewport();
  const { selectedNodes, selectedNodeIDs } = useCanvasCollect();
  const { onHoverLayer } = useCanvasView();
  const { copyBlocks } = useCanvasCopy();
  const { removeBlockWithValidation, removeSelectedBlocksWithValidation } =
    useCanvasRemove();
  // 判斷是移動到選取藍色框還是普通 node
  const [isHoverSelectNode, setIsHoverSelectNode] = useState(false);
  const [floatPanelPosition, setFloatPanelPosition] = useState({
    x: 0,
    y: 0,
  });

  // 只有 note 的時候只顯示刪除按鈕，只有 entry point 的時候兩者都不顯示
  const actionValidate = useCallback(
    (iconType: string, currentNode: Node[] | Node) => {
      if (isArray(currentNode)) {
        if (
          find(currentNode, (i: Node) =>
            isValueInEnum(i.type as BlockTypesEnum, BlockTypesEnum),
          )
        ) {
          return true;
        }

        if (find(currentNode, (i) => i.type === NodeTypesEnum.NOTE_NODE)) {
          if (iconType === 'delete') {
            return true;
          }
          return false;
        }

        return false;
      } else {
        switch (currentNode.type) {
          case NodeTypesEnum.ENTRY_POINT_NODE: {
            return false;
          }
          case NodeTypesEnum.NOTE_NODE: {
            if (iconType === 'delete') {
              return true;
            }
            return false;
          }
          default: {
            return true;
          }
        }
      }
    },
    [],
  );

  const handleDelete = useCallback(() => {
    if (isHoverSelectNode) {
      setIsHoverSelectNode(false);
      removeSelectedBlocksWithValidation();
    } else {
      if (onHoverNode?.id) {
        removeBlockWithValidation(onHoverNode.id);
        setOnHoverNode(undefined);
      }
    }
  }, [
    isHoverSelectNode,
    onHoverNode?.id,
    removeBlockWithValidation,
    removeSelectedBlocksWithValidation,
    setOnHoverNode,
  ]);

  const handleCopy = useCallback(() => {
    if (isHoverSelectNode) {
      setIsHoverSelectNode(false);
      copyBlocks(selectedNodeIDs);
    } else {
      if (onHoverNode?.id) copyBlocks([onHoverNode.id]);
    }
  }, [copyBlocks, isHoverSelectNode, onHoverNode?.id, selectedNodeIDs]);

  const handleMouseLeaveFloatPanel = useCallback(() => {
    if (isHoverSelectNode) {
      setIsHoverSelectNode(false);
    } else {
      setOnHoverNode(undefined);
    }
  }, [isHoverSelectNode, setOnHoverNode]);

  const handleMouseEnterNodesSelection = useCallback((e: Event) => {
    const rect = document
      .querySelector(REACT_FLOW_SELECT_RECT_SELECTOR)
      ?.getBoundingClientRect();
    if (!rect) return;

    setIsHoverSelectNode(true);
    setFloatPanelPosition({
      x: rect.left,
      y: rect.top,
    });
  }, []);

  const handleMouseLeaveNodesSelection = useCallback((e: MouseEvent) => {
    const relatedTarget = e.relatedTarget as HTMLElement | null;
    if (relatedTarget?.id === 'node-float-panel') return;

    setIsHoverSelectNode(false);
    setFloatPanelPosition({ x: 0, y: 0 });
  }, []);

  const { canCopy, canDelete } = useMemo(() => {
    if (isHoverSelectNode)
      return {
        canCopy: actionValidate('copy', selectedNodes),
        canDelete: actionValidate('delete', selectedNodes),
      };
    if (onHoverNode) {
      return {
        canCopy: actionValidate('copy', onHoverNode),
        canDelete: actionValidate('delete', onHoverNode),
      };
    }

    return {
      canCopy: false,
      canDelete: false,
    };
  }, [actionValidate, isHoverSelectNode, onHoverNode, selectedNodes]);

  useEffect(() => {
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.type === 'childList') {
          const addedNodes = Array.from(mutation.addedNodes);

          addedNodes.forEach((node) => {
            // 監測框選元素出現時綁定 hover 事件
            if (
              node instanceof HTMLElement &&
              node.classList.contains('react-flow__nodesselection')
            ) {
              node.addEventListener(
                'mouseenter',
                handleMouseEnterNodesSelection,
              );
              node.addEventListener(
                'mouseleave',
                handleMouseLeaveNodesSelection as (e: Event) => void,
              );
            }
          });
        }
      });

      if (isHoverSelectNode) {
        // 畫面有變動時改變座標
        const selectedElement = document.querySelector(
          REACT_FLOW_SELECT_RECT_SELECTOR,
        ) as Element;
        if (selectedElement) {
          const rect = selectedElement.getBoundingClientRect();
          setFloatPanelPosition({
            x: rect.left,
            y: rect.top,
          });
        }
      } else {
        if (onHoverNode?.id) {
          const hoverElement = document.querySelector(
            `.react-flow__node[data-id="${onHoverNode.id}"]`,
          );
          if (hoverElement) {
            const rect = hoverElement.getBoundingClientRect();
            setFloatPanelPosition({
              x: rect.left,
              y: rect.top,
            });
          }
        }
      }
    });

    observer.observe(document.body, {
      subtree: true,
      attributes: true,
      attributeFilter: ['style'],
      childList: true,
    });

    return () => {
      const rectElement = document.querySelector(
        REACT_FLOW_SELECT_RECT_SELECTOR,
      ) as Element;
      if (rectElement) {
        rectElement.removeEventListener(
          'mouseenter',
          handleMouseEnterNodesSelection,
        );
        rectElement.removeEventListener(
          'mouseleave',
          handleMouseLeaveNodesSelection as (e: Event) => void,
        );
      }
      observer.disconnect();
    };
  }, [
    handleMouseEnterNodesSelection,
    handleMouseLeaveNodesSelection,
    isHoverSelectNode,
    onHoverNode,
    selectedNodes,
  ]);

  // 沒有操作可進行時不顯示
  if (!canCopy && !canDelete) return null;
  // hover 內部 cell 時不顯示
  if (onHoverLayer > 1) return null;
  return (
    <FloatPanelWrapperStyled
      id="node-float-panel"
      $zoom={zoom}
      sx={{
        top: floatPanelPosition.y - 36,
        left: floatPanelPosition.x,
      }}
      onMouseLeave={handleMouseLeaveFloatPanel}
    >
      <ContainerStyled>
        {canCopy && (
          <Box id={`block_duplicate`} onClick={handleCopy}>
            <ContentCopyOutlinedIcon />
          </Box>
        )}
        {canDelete && (
          <Box id={`block_delete`} onClick={handleDelete}>
            <DeleteOutlineOutlinedIcon />
          </Box>
        )}
      </ContainerStyled>
    </FloatPanelWrapperStyled>
  );
};

export default NodeFloatPanel;
